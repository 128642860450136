const common = {
	'welcome.message': '<p>{{name}}, welcome to use</p><p></p><p>CodingBar smart programming learning system</p>',
	'common.edit': 'Edit ',
	'common.delete': 'Delete ',
	'common.deleteThis': 'Delete this item',
	'common.cancel': 'Cancel',
	'common.return': 'Return',
	'common.confirm': 'Confirm ',
	'common.confirm.submission': 'Confirm submission', // 確認送出
	'common.confirmEdit': 'Confirm',
	'common.confirmChanges': 'Confirm',
	'common.confirmNew': 'Confirm',
	'common.confirmAction': 'Confirm to do this action?', //請確認是否要執行此操作
	'common.share': 'Share',
	'common.previous': 'Previous', //上一步
	'common.next': 'Next',
	'common.preview': 'Preview',
	'common.loading': 'Loading...',
	'common.success': 'Success!',
	'common.update': 'Update',
	'common.opened': 'Opened ',
	'common.notOpen': 'Closed',
	'common.opening': 'Opening',
	'common.commingsoon': 'Coming Soon',
	'common.ended': 'Finished',
	'common.finished': 'Finished',
	'common.save.button': 'Save', // 儲存
	'common.saveSuc': 'Saved successfully!',
	'common.saveFail': 'Failed to Save',
	'common.save': 'Save',
	'common.this': 'this ',
	'common.share.unit': 'Share to everyone in the same group',
	'common.share.all': 'Share to everyone',
	'common.copytoresource': 'Copy to Library',
	'common.remove': 'Remove',
	'common.error': 'There is an error',
	'common.days': 'Days/Hours',
	'common.hours': 'Hours',
	'common.minutes': 'Minutes',
	'common.seconds': 'Seconds',
	'common.monday': 'Monday',
	'common.tuesday': 'Tuesday',
	'common.wednesday': 'Wednesday',
	'common.thursday': 'Thursday',
	'common.friday': 'Friday',
	'common.saturday': 'Saturday',
	'common.sunday': 'Sunday',
	'common.abbrev.monday': 'MON',
	'common.abbrev.tuesday': 'TUE',
	'common.abbrev.wednesday': 'WED',
	'common.abbrev.thursday': 'THR',
	'common.abbrev.friday': 'FRI',
	'common.abbrev.saturday': 'SAT',
	'common.abbrev.sunday': 'SUN',
	'common.Wmonday': 'Monday',
	'common.Wtuesday': 'Tuesday',
	'common.Wwednesday': 'Wednesday',
	'common.Wthursday': 'Thursday',
	'common.Wfriday': 'Friday',
	'common.Wsaturday': 'Saturday',
	'common.Wsunday': 'Sunday',
	'common.month': '/',
	'common.day': '',
	'common.status': 'Status',
	'common.time': 'Time',
	'common.score': '',
	'common.nextWeek': 'Next Week',
	'common.prevWeek': 'Last Week',
	'common.nowWeek': 'Back to the Current Week',
	'common.24/7': '24 hours',
	'common.loadingTime': 'Loading...',
	'common.loadmore': 'Load more..',
	'common.unfinished': 'Unfinished',
	'common.solved': 'Finished',
	'common.personalInfo': 'Personal Info',
	'common.belongUnit': 'Group',
	'common.belongUnits': 'Group List',
	'common.logout': 'Log out',
	'common.version': 'Version',
	'common.submit': 'Submit',
	'common.home': 'Home',
	'common.copySuccess': 'Copied Successfully',
	'common.copyFail': 'Failed to Copy. Please Try Again.',
	'common.info': ' Information',
	'common.name': 'Name',
	'common.optional': '(Optional)',
	'common.title': 'Title',
	'common.content': 'Content',
	'common.pleaseFill': 'Your content',
	'common.close': 'Close',
	'common.known': 'Got it',
	'common.notnull': 'This field is required',
	'common.continue': 'Continue',
	'common.minutes': 'min',
	'common.10min': '10 min',
	'common.15min': '15 min',
	'common.20min': '20 min',
	'common.30min': '30 min',
	'common.40min': '40 min',
	'common.45min': '45 min',
	'common.50min': '50 min',
	'common.60min': '60 min',
	'common.70min': '70 min',
	'common.80min': '80 min',
	'common.90min': '90 min',
	'common.120min': '120 min',
	'common.180min': '180 min',
	'common.new': 'New',
	'common.error': 'Error',
	'common.sendMsg': 'Send Message',
	'common.create': 'Create',
	'common.privacy': 'Privacy',
	'common.and': 'and',
	'common.perPage': '',
	'common.page': 'Per Page',
	'common.times': 'Time(s)',
	'common.open': 'Open',
	'common.executionEnvironment': 'Execution Environment',
	'common.search': 'Search',
	'common.errorOccured': 'Error. Please contact tech support',
	'common.disabledFunctionality': 'This functionality is disabled for the moment',
	'common.preparing': 'Loading',
	'common.refresh': 'Refresh',
	'common.pleaseWait': 'Please wait ....',
	'common.noData': 'No Data',
	'common.noFill': 'No Fill',

	'common.not-empty': "Can't be empty",
	'common.final.edit': 'Last edit',

	'common.yes': 'Yes',
	'common.no': 'No',
	'common.save.setting': 'Save Settings',
	'common.leave': 'Leave',
	'common.hour': 'Hours',

	'common.fillInNumber': 'Please enter the number',
	'common.slowQuery': 'The current connection speed to the server is too slow. Reading data...',

	'common.airabbi': 'AIRABBI INC.',
	'common.selectAll': 'Select All', //全選
	'common.startDate': 'Begin Date', //起始日期
	'common.startTime': 'Begin Time', //起始時間
	'common.cutoffDate': 'Cutoff Date', //截止日期
	'common.cutoffTime': 'Cutoff Time', //截止時間
	'common.reading.data': 'Reading data...',

	'common.comma': ', ',
	'common.add': 'Add',
	'common.line.break': '\n',

	'common.unlimited': 'Unlimited', //無上限
	'common.StepsTab.Title.Date': 'Date Setting', //日程設定
	'common.undefined': 'no data', //查無資料
	'common.download': 'Download', // 下載
	'common.mode': 'Mode', // 模式
	'common.updating': 'Updating...', // 更新中...
	'chooseOne.to.select': 'Choose one to search: {{selectOptions}}', // 擇一進行搜尋：{{selectOptions}}
};

const header = {
	'header.notification': 'Notifications',
	'header.announcement': 'Announcement', //公告
	'header.notes': 'Notes',
	'header.zoom': 'Meeting',
	'header.bugReport': 'BugReport',
	'header.freecode': 'Playground',
	'header.logOut': 'LogOut',

	'header.account': 'Account',
	'header.preferences': 'Preferences',
	'header.account.and.setting': 'Setting',
	// 'header.AImate.plan': 'Plan',
	'header.AImate.plan': 'Subscribe',
	'header.AImate.bill': 'Bill',
	'header.AImate.coin': 'i-coin',
	'header.AImate.notification': 'Notification',
};

const teacherExercise = {
	'teacher.exercise.open.exercise': 'Release Exercise',
	'teacher.exercise.open.all': 'Release All Exercises?',
	'teacher.exercise.open.all2': 'You are about to release all exercises',
	'teacher.exercise.close.exercise': 'Close Exercise',
	'teacher.exercise.open.exercise.opened': 'Released',
	'teacher.exercise.open.hint': 'Release Tips',
	'exercise.nohint': 'There is no hint available',
	'exercise.provideans': 'Click the button on the left to release the answer',
	'exercise.providehint': 'Click	the button on the left to provide hints',
	'exercise.hint.noonecheck': 'No one has checked it yet',
	'exercise.read': 'Students who have checked the hints',
	'exercise.betterAns': 'Do you have a better answer?',
	'exercise.betterTips': 'Do you have more hints to provide?',
	'teacher.exercise.close.hint': 'Close Tips',
	'teacher.exercise.open.hint.opened': 'Tips released already',
	'teacher.exercise.open.answer': 'Release Answer',
	'teacher.exercise.close.answer': 'Close Answer',
	'teacher.exercise.open.answer.opened': 'Answer released already',
	'teacher.exercise.moveUp': 'Move Up',
	'teacher.exercise.moveDown': 'Move Down',
	'teacher.exercise.mostRecentAnswer': 'Last Sumbitted Answer',
	'teacher.exercise.firstAnswer': 'First Recorded Answer',
	'teacher.exercise.sortResult': 'Sort Results',
	'teacher.exercise.addToObserveList': 'Add to Need Attention',
	'teacher.exercise.removeFromObserveList': 'Remove from Need Attention',
};

const sidebar = {
	'teacher.main.sidebar.welcomeMessage': 'Hi, welcome back!',
	'teacher.main.sidebar.course': 'Courses',
	'teacher.main.sidebar.cir': 'Students',
	'teacher.main.sidebar.tri': 'Library',
	'teacher.main.sidebar.studentQ': 'Message',
	'teacher.main.sidebar.liveCourse': 'Active Courses',
	'teacher.main.sidebar.alert': 'Notifications',
	'teacher.main.sidebar.heart': 'Personal Info',
	'teacher.main.sidebar.share': 'Resources - Shared Area',

	'student.main.sidebar.welcomeMessage': 'Hi, welcome back!',
	'student.main.sidebar.course': 'My Courses',
	'student.main.sidebar.question': 'My Questions',
	'student.main.sidebar.notes': 'My Notes',
	'student.main.sidebar.liveCourse': 'Active Courses',
	'student.main.sidebar.learningResults': 'Learning Results',
	'teacher.main.sidebar.tutorial': 'System Tutorial',
	'student.main.sidebar.globalexam': 'Practice Test (Beta)',
	'teacher.main.sidebar.importexercise': 'Import exercises',
	'teacher.main.sidebar.question-label': 'Question Classification Tag',
	'teacher.main.sidebar.exercise-label': 'Exercise Error Tag',
	'teacher.main.sidebar.question': 'Exercises ',
	'teacher.main.sidebar.questionGroup': 'Exercise Set',
};

const teacherCourseDetail = {
	'course.sidebar.course': 'Course Management',
	'course.sidebar.live': 'Online Classroom',
	'course.sidebar.lesson': 'Lessons',
	'course.sidebar.test': 'Quizzes',
	'course.sidebar.student': 'Students',
	'course.sidebar.score': 'Score Reports',
	'course.sidebar.exercise': 'Exercises',
	'course.sidebar.studentamount': 'Number of users',
	'teacher.sidebarFSH.useUnLimit': 'Unlimited',
};

const InformationPage = {
	'information.account.title': 'Account & Password',
	'information.account.account': 'Account',

	'information.account.certificate.title': 'Certificate', //帳號憑證
	'information.account.certificate.placeholder': 'Certificate has not been imported yet.', //尚未匯入憑證
	'information.account.certificate.btn': 'Import', //匯入憑證
	'information.account.certificate.noCertificate': '', //憑證無效，請匯入有效憑證
	'information.account.certificate.deadtime': 'Expiry Date', //憑證到期日
	'information.account.certificate.overtime': 'Expired', //憑證已到期

	'information.account.certificate.useLimit': 'Amount', //憑證人數
	'information.account.certificate.toastAlert.alreadyCertification':
		'Already has certification, please update when invalid.', //已有憑證！請於憑證到期後再更新憑證
	'information.account.certificate.toastAlert.alreadyPast': 'The certification is invalid, please check file.', //此憑證已過期！請確認匯入憑證檔案
	'information.account.certificate.toastAlert.notActive':
		'The certification is activable, please import after {{beginDate}}.', //此憑證尚未有效！請於憑證時間 {{beginDate}} 後再匯入
	'information.account.certificate.toastAlert.update': 'Has already update certification.', //已更新憑證！請確認憑證資訊
	'information.account.certificate.toastAlert.error': 'The certification has been used, please check file.', //憑證錯誤！此憑證已被其他人使用
	'information.account.certificate.toastAlert.import': 'Import certification Done.', //已匯入憑證，未變更憑證資訊

	'information.account.password': 'Password',
	'information.account.pre.password': 'Preset password',
	'information.account.avatar': 'Avatar',
	'information.account.button.changePW': 'Change Password',
	'information.account.button.newPW': 'New Password',
	'information.account.button.confirmPW': 'Save Changes',
	'information.account.button.cancelNewPW': 'Cancel',
	'information.account.avatar.change': 'Change',
	'information.account.avatar.chooseImage': 'Choose Image',
	'information.account.avatar.chooseColor': 'Choose Color',

	'information.socialMediaConnection': 'Connect to Social Media',
	'information.socialMedia.connectAccount': 'Connect Account',

	'information.classnum': 'Class & Seat Number',
	'information.school': 'School',
	'information.grade': 'Grade',
	'information.class': 'Class',
	'information.num': 'Seat Number',
	'information.numPlaceholder': 'Enter Seat Number Number',
	'information.num2': 'Student Number',
	'information.parent': "Parents' Informations",
	'information.parent.name': 'Parent Name',

	'information.basic.title': 'About Me',
	'information.basic.modify': 'Modify Info',
	'information.basic.school': 'School',
	'information.basic.name': 'Name',
	'information.basic.class': 'Class',
	'information.basic.nickname': 'Nickname',
	'information.basic.noNickname': 'No Nickname',
	'information.basic.tel': 'Telephone',
	'information.basic.email': 'E-mail',
	'information.basic.availableItems': 'Subjects',
	'information.basic.noAvailableItems': 'No subject is selected',

	'information.relationContact': 'Contact Person',
	'information.relation': 'Relation',

	'information.settings.title': 'Settings',
	'information.settings.lang': 'Languages',

	'information.notset': 'Not Set',
	'information.noNum': 'Not Set',
	'information.noClass': 'Not Set',

	'information.alertSchool': 'This group list is required',
	'information.alertUnit': 'This unit is required', //單位不可為空!
	'information.alertEmail': 'This email is required',
	'information.alertEmail2': 'Invalid Email format!',
	'information.alertName': 'This name is required!',
	'information.alertGender': 'This gender is required!',
	'information.alertPW': 'This password is required!',
	'information.alertTel': 'This telephone is required!',
	'information.alertTel2': 'Invalid telephone format!', //電話格式不正確!
	'information.alertExistTeacher': 'cannot replace existed teacher account!',
	'information.alertExistAccount': 'cannot replace existed {{role}} account!', //不可以覆蓋已存在的 {{role}} 帳號!
	'information.alertExistAccount.other.unit': 'cannot replace accounts that already exist in other unit!',
	'information.alertExistAccount.cannot.create.other.unit':
		'Cannot create, this account {{role}} already exists in {{unit}}',
	'information.alertExistAccount.cannot.create.current.unit':
		'Cannot create, this account {{role}} already exists in {{unit}}, please go to edit.',
	'information.correctPW': 'The password is incorrect!',

	'information.modifyTitle': 'Edit Personal Information',
	'information.modifySchool': 'School (unchangable)',
	'information.saveSuc': 'Personal Information Updated Successfully',
	'information.upload': 'Upload File',
	'information.fileLink': 'File Link',
	'information.avatarPlaceholder': 'Will be filled in automatically after the file is uploaded',
	'information.uploadFin': 'Uploaded',
	'information.uploadProgress': 'Upload Progress',

	'information.modifyNumber': 'Edit Seat Number',
	'information.confitmEnter': 'Confirm',
	'information.newNumber': 'Please enter the new Seat Number. Wrong Seat Number may lead to Incorrect Grades.',
	'information.confirmClass': 'Enter your class number after checking the course that you have applied.',

	'information.passwordWarning': 'Warning! You need to log in again to change your password!',
	'password.new': 'Please enter the new password!',
	'password.notStrong': 'Your password is too weak!',
	'password.changeSuccess': 'Password changed successfully, please log in again with your new password!',

	'password.resetByEmail': 'Please enter your e-mail to reset the password',
	'password.checkMail': 'The password reset mail has been sent. Please go to {{email}} to reset your password!',
	'password.failtoRest': 'Failed to reset your password. ',
	'password.entermail': 'Please enter your e-mail',
	'password.sendtoMail': 'We have sent the instruction to change your password to your mailbox',
	'password.yourAccount': 'Your Account',

	'information.changeSuccess': 'Student information updated successfully!',
	'information.changeFailed': 'Failed to update student information!',
	'student.contactInfo': 'Contact information',
	'information.loginpage.displayName': 'Please enter the name of the parent',
	'information.loginpage.Phone': 'Please enter the phone number',

	'information.account.avatar.changeSuccess': 'Update avatar successfully',
	'information.account.avatar.changeFail': 'Failed to update avatar',
};

const notificationPages = {
	'all.notification.noMessages': 'No notifications',
	'all.notification.unread': 'Unread',

	'notification.sent': 'The message has been sent already!',
	'notification.sentAll': 'The message has be sent to all the users!',
	'notification.sending': 'Sending message',
	'notification.activity': 'Activity',
	'notification.challenge': 'Challenge',
	'notification.greeting': 'Greeting',
	'notification.news': 'News',
	'notification.exercise': 'Exercises',

	'notification.modalTitle': 'Send Notifications',
	'notification.sendto': 'Send to',
	'notification.resetSuccess': 'Notification reset successfully',
	'notification.notifications': 'Notifications',
	'notification.title': 'Title:',
	'notification.time': 'Time:',
	'notification.message': 'Message:',

	'fillinTitle': 'Please fill in the title',
	'titleConnotBeNull': 'The field is required',
	'Subtitle': 'Subtitle (100 character max)',
	'fillin.subtitle': 'Please fill in the subtitle',
	'subtitleCannotBeNull': 'This field is required!',
	'contentCannotBeNull': 'This field is required!',
};

const teacherResourcePage = {
	'teacher.resources.header.title': 'Library',
	'teacher.resources.tab.sorting': 'Sort',
	'teacher.resources.tab.sorting.name': 'Name',
	'teacher.resources.tab.sorting.time': 'Last Edited',
	'teacher.resources.tab.searchPlaceholder': 'Enter Keywords',
	'teacher.resources.tabTitle.handout': 'Handouts ',
	'teacher.resources.tabTitle.exercise': 'Exercises ',
	'teacher.resources.tabTitle.test': 'Quizzes',

	'teacher.resources.tabTitle.questionGroup': 'Exercise Set',
	'teacher.resources.tab.sorting.questionlength': 'Number of exercises',
	'teacher.resources.tab.sorting.hashtag': 'Tag',

	'teacher.resources.tab.sorting.displayName': 'Student name',
	'teacher.resources.tab.sorting.seatNo': 'Seat number',
	'teacher.resources.tab.sorting.completepercent': 'The completion rate',
	'teacher.resources.tab.sorting.lastlogin': 'Login time',
	'teacher.resources.tab.sorting.studentuid': 'Account name',

	'teacher.resources.newResource.iconTips': 'Add New Resources',
	'teacher.resources.newResource.newHandout': 'Add New Handouts',
	'teacher.resources.newResource.newExercise': 'Add New Exercises',
	'teacher.resources.newResource.newExerciseOrVideo': 'Add Exercise or Video',
	'teacher.resources.newResource.newTest': 'Add New Quizzes',
	'teacher.resources.newResource.newLesson': 'Add New Lesson',

	'teacher.resources.handout.category': 'Standard Handouts',

	'components.handoutManageRectangle.upload': 'Upload',
	'components.handoutManageRectangle.download': 'Download Handout',
	'components.handoutManageRectangle.rename': 'Rename',
	'components.handoutManageRectangle.delete': 'Delete Handout',
	'components.handoutManageRectangle.deletePopup.title': 'Delete Handout?',
	'components.handoutManageRectangle.deletePopup.revokable':
		'The handout will be removed from this course, you can still find this handout in the Resrouce Center',
	'components.handoutManageRectangle.deletePopup.irrevokable': 'This action cannot be undone',
	'components.exercise.deletePopup.revokable':
		'The exercise will be removed from this course, you can still find this exercise in the Library',
	'components.exercise.deletePopup': 'This action cannot be undone',
	'confirmation.title': 'Confirm Message Title',
	'confirmation.content': 'Confirm Message',

	'teacher.resources.mine': 'My Resources',
	'teacher.resources.unit': 'Group Resources',
	'teacher.resources.global': 'Global Resources',

	'teacher.resources.nohandout': 'No handout has been uploaded yet',
	'teacher.resources.noexercise': 'No exercise has been created yet',
	'teacher.resources.notest': 'No quiz has been added yet',

	'teacher.nomatch.handout': 'No handout matched the search criteria',
	'teacherResourcePage.syncToCourse': 'Sync to All Courses And Tests',
	'resource.shareSuc': 'Shared Successfully!',
	'resource.shareFail': 'Shared Failed!',
	'resource.copySuc': 'Copied Successfully!',
	'resource.syncSuc': 'Sync Finished',
	'resource.syncFail': 'Failed to Sync',
	'resource.copySucc': '{{}}{{title}} copied successfully!',
	'resource.copyFailed': 'Failed to copy exercise {{title}}: {{err}}',
	'resource.delSuc': 'Quiz {{title}} deleted successfully',
	'resource.delFailed': 'Failed to delete quiz {{title}}: {{err}}',

	'teacher.studentMg': 'Student Management',
	'teacher.studentMg.addStudent': 'Add Student Information',
	'teacher.studentMg.viewStudent': 'View Student Information',

	'teacher.resources.header.subtitle':
		'We have launched a new version of the teaching resource library, you can more easily create various types of questions in CodingBar, upload handout materials, and download more resources from the unit/public resource library! Come and have a try!',
	'teacher.resources.fileName': 'File name',

	'teacher.resources.tab.sorting.uploadBy': 'Uploaded by',
	'teacher.resources.tab.sorting.useLimit': 'Quota',
	'teacher.resources.tab.sorting.currentUse': 'Used',
	'teacher.resources.tab.sorting.remainingUse': 'Available',
	'teacher.resources.tab.sorting.expiration': 'Expire Date',
	'teacher.resources.tab.sorting.tags': 'Classification label',
	'teacher.resources.tab.sorting.materials': 'Content',

	'teacher.resources.tabTitle.package': 'Package course',
	'teacher.resources.package.edit': 'Edit package course',
	'teacher.resources.package.delete': 'Delete package course',
	'teacher.resources.package.deleteSuccess': 'Successfully deleted package course',
	'teacher.resources.package.deleteFail': 'Failed to delete package course',
	'teacher.resources.package.deletePopup.title': 'Delete package course?',
	'teacher.resources.package.deletePopup.content': 'Once the package course is removed, it can no longer be restored',

	'teacher.resources.newResource.newCoursePackage': 'Add new package course',
	'teacher.resources.year': '/',
	'teacher.resources.month': '/',
	'teacher.resources.day': '',

	'teacher.studentMg.deleteStudent': 'Remove this student',
	'teacher.resources.delete.success': 'Delete Successfully',
	'teacher.resources.delete.fail': 'Delete failed ',
};

const exerciseManageRectangle = {
	'components.exerciseManageRectangle.deleteTitle': 'Delete Exercise?',
	'components.exerciseManageRectangle.deleteMsg': 'This action cannot be undone',
	'exercise.deleteSuc': 'Exercise Deleted Successfully',
	'components.exerciseManageRectangle.btn.edit': 'Edit Exercise',
	'components.exerciseManageRectangle.btn.delete': 'Delete Exercise',
	'components.exerciseManageRectangle.btn.copy': 'Copy Exercise',
};

const questionGroupManageRectangle = {
	'components.questionGroupManageRectangle.btn.edit': 'Edit exercise set',
	'components.questionGroupManageRectangle.btn.delete': 'Delete exercise set',
	'components.questionGroupManageRectangle.btn.remove': 'Remove exercise set',

	'components.QuestionGroupManageRectangle.deleteTitle': 'Delete exercise set',
	'components.QuestionGroupManageRectangle.deleteCheck.irrevokable':
		"Once a exercise set is deleted, it can't be restored.",
	'components.QuestionGroupManageRectangle.deleteCheck.revokable':
		'The exercise set has been removed from this course, you can still find this set in the Teaching Library',

	'components.QuestionGroupManageRectangle.btn.copy': 'Copy this exercise set',
};

const testManageRectangle = {
	'components.testManageRectangle.btn.edit': 'Edit',
	'components.testManageRectangle.btn.delete': 'Delete',
	'components.testManageRectangle.btn.remove': 'Remove Quiz',
	'components.testManageRectangle.btn.copy': 'Duplicate this test',
	'components.testManageRectangle.exerciseCount': 'Number of questions',

	'components.testManageRectangle.testDate': 'Quiz Date',
	'components.testManageRectangle.notTestYet': 'Not taken yet',

	'components.testManageRectangle.deleteTitle': 'Delete Quiz?',
	'components.testManageRectangle.deleteCheck.irrevokable': 'This action cannot be undone',
	'components.testManageRectangle.deleteCheck.revokable':
		'The quiz will be removed from this course, but you can still find this quiz in the Library',

	'components.testManageRectangle.editTitle': 'Edit Quiz?',
	'components.testManageRectangle.editTrue': 'All the courses linked to this quiz will be updated',
	'components.testManageRectangle.editFalse': 'The Library will be automatically updated',
};

const materialManage = {
	'components.materialManage.title': 'Add Handout',
	'components.materialManage.add': 'Add Handout',
};

const materialUploadFile = {
	'components.materialUploadFile.title': 'New Handout (File Upload)',
	'components.materialUploadFile.selectFile': 'Select Files...',
	'components.materialUploadFile.setTitle': 'Handout Title',
	'components.materialUploadFile.lang': 'Programming Language',
	'components.materialUploadFile.href': 'Handout Link',
	'components.materialUploadFile.confirm': 'New ',

	'components.materialUploadFile.selected': 'Selected Files',
	'components.materialUploadFile.loading': 'Upload Progress',
	'components.materialUploadFile.titlePlaceholder': 'Please enter the handout title',
	'components.materialUploadFile.autoFillPlaceholder': 'This will be autofilled once the upload has completed',

	'components.materialUploadFile.addMaterialSuccess': 'Added {{title}}',
	'components.materialUploadFile.addMaterialFail': 'Failed to add {{title}}. Error info: {{err}}',
	'components.materialUploadFile.checkMaterialLink': 'Please check your handout link format',
	'components.materialUploadFile.addMaterial': 'Add Handout',
	'components.materialUploadFile.addMaterialTitle': 'Fill in handout title',
	'components.materialUploadFile.href': 'Handout link',
	'components.materialUploadFile.addMaterialLink': 'Fill in the handout link',
};

const exerciseManage = {
	'component.exerciseManage.description': 'Exercise Description',
	'component.exerciseManage.title.modify': 'Edit Exercise',
	'component.exerciseManage.title.create': 'Create Exercise',
	'component.exerciseManage.title.homework': 'Create Homework',
	'component.exerciseManage.tab.title': 'Title',
	'component.exerciseManage.tab.freeCode': 'FreeCode',
	'component.exerciseManage.tab.video': 'Watch video',
	'component.exerciseManage.tab.fillin': 'Fill in the Blanks',
	'component.exerciseManage.tab.cloze': 'Cloze',
	'component.exerciseManage.tab.dnd': 'Drag and Drop',
	'component.exerciseManage.tab.choice': 'Choice',
	'component.exerciseManage.tab.rearrangement': 'Rearrangement',
	'component.exerciseManage.tab.step': 'Step By Step',
	'component.exerciseManage.tab.splitTips': 'Split Tips',
	'component.exerciseManage.tab.answer': 'Answer',
	'component.exerciseManage.tab.test': 'Test data',
	'component.exerciseManage.tab.preview': 'Publish Preview',

	'component.exerciseManage.modal.previous': 'Previous',
	'component.exerciseManage.modal.next': 'Next',
	'component.exerciseManage.modal.confirmEdit': 'Edit Exercise',
	'component.exerciseManage.modal.confirmCreate': 'Create New Exercise',

	'component.exerciseManage.fraction.exercise': 'Exercises',
	'component.exerciseManage.fraction.editSuccess': 'Edited Successfully.',
	'component.exerciseManage.fraction.editFail': 'Edited Failed',
	'component.exerciseManage.fraction.edit': 'Edit Exercise',
	'component.exerciseManage.fraction.fail': 'Failed.',
	'component.exerciseManage.fraction.createSuccess': 'Created Successfully.',
	'component.exerciseManage.fraction.createFail': 'Creation Failed.',
	'component.exerciseManage.fraction.try.again': ', please try again.',
	'component.exerciseManage.fraction.create': 'Create Exercise',
	'component.exerciseManage.alert.title': 'Please Fill in the Exercise Title.',
	'component.exerciseManage.alert.execute.frequently': 'Please do not execute frequently', //請勿頻繁送出程式碼

	'component.exerciseManage.alert.choiceNoAnswer': 'Please select the correct answer(s) for the choice exercise.',
	'component.exerciseManage.alert.choiceEmpty': "Choice exercise options can't be empty.",

	'component.exerciseManage.alert.choiceChooseAnswer': 'Mark the choice as the correct answer',
	'component.exerciseManage.alert.addChoice': 'Add new choice',
	'component.exerciseManage.alert.choiceChooseAlert': 'There must be at least one correct answer',
	'component.exerciseManage.codeSettingsNotSet': 'Code Not Set',

	'component.exerciseManage.alert.emptyVideoUrl': 'video url cannot be empty',
	'component.exerciseManage.alert.ErrorVideoUrl': 'video url cannot play',

	'component.exerciseManage.alert.emptyChoice': 'Choice exercise should have at least one option',
	'component.exerciseManage.alert.nullChoice': "Choice exercise options can't be empty.",

	'component.exerciseManage.alert.emptyStep': 'MSQ should have at least one step',
	'component.exerciseManage.addStep': 'Add step to exercise', //新增引導解題步驟
	'component.exerciseManage.confirmAddStep': 'Add', //新增步驟

	'component.exerciseManage.correctAnswer': 'Correct',
	'component.exerciseManage.wrongAnswer': 'Incorrect',
	'component.exerciseManage.previewAnswer': 'Preview',

	'component.exerciseManage.isSlotFilling':
		'This is a fill-in-the-blank exercise. Please click on the fill-in-the-blank button',
	'component.exerciseManage.setSlotFilling.placeholder':
		"Please enter the code in this section. \nThe blank to be filled is denoted with ${}. \nFor example: print('hello world') will be written as ${print}('hello world'), where ${} indicates the blank that should be filled in. \nThe number of spaces within the curly braces determines the length of the blank.", //填空题设定方式：\n请将完整程序码贴于此文字编辑栏位\n请勿设计 无法执行/非程序码 的内容，如有其馀问答需求建议使用克漏字填空题\n请将想要「挖空」的字串，以 ${} 包住\n范例说明\n程序码正确解答： print("hello world")\n\n若想将 print 变成填空题空格，请将 print 以 ${print} 取代： ${}(“hello world”)\n大括号内可允许空格，例如 ${ }，空格越多，题目显示的填空长度就越长
	'component.exerciseManage.alert.completeStep': 'Please complete this step first',
	'component.exerciseManage.alert.lastStep': 'This is the last step',
	'component.exerciseManage.setStepTip':
		'Here are the instructions that will be shown to the students. Instructions should be written as comments use by the selected programming language.',
	'component.exerciseManage.setFreecodeContent': 'Set the Free Code exercise content',

	'component.exerciseManage.loadingLonger': 'Loading ... It might take some time when there is a lot of questions',
	'component.exerciseManage.exerciseContent': 'Exercise content',
	'component.exerciseManage.exerciseDesc': 'Exercise description',
	'component.exerciseManage.select': 'Select',
	'component.exerciseManage.selectVideo': 'Select video',
	'component.exerciseManage.selectExerciseType': 'Select exercise type',
	'component.exerciseManage.exerciseTitle': 'Exercise title',
	'component.exerciseManage.alert.lastQuestion': 'This is the last question!',
	'component.exerciseManage.openFiles': 'Open File Explorer',
	'component.exerciseManage.dragDrop.placeOption': 'Place option',
	'component.exerciseManage.noErrors': 'All good!',
	'component.exerciseManage.alert.noCode': 'Your code cannot be empty',
	'component.exerciseManage.alert.incorrectData': 'Incorrect entries',
	'component.exerciseManage.alert.frequencySubmit': 'Not allowed submit frequently!!', //請勿頻繁執行/提交!!
	'component.exerciseManage.alert.executing': 'Executing ...',
	'component.exerciseManage.alert.other.code.executing': 'Other code is running!!',

	'component.exerciseManage.checkDesc': 'Check the description',
	'component.exerciseManage.previousPage': 'Last Page',
	'component.exerciseManage.checkHints': 'Check Hints',
	'component.exerciseManage.moreHints': 'Stuck? Here are some hints for you',
	'component.exerciseManage.exerciseNotAvailable': 'This exercise is not available yet',
	'component.exerciseManage.test.fail': 'Test failed',
	'component.exerciseManage.executionFailed': 'Execution failed',
	'component.exerciseManage.executionSuccess': 'Pass',
	'component.exerciseManage.reorder': 'Reorder Result',

	'component.exerciseManage.configSetting': 'Please set up the exercise using the format method below:',
	'component.exerciseManage.cloze.slot': 'the correct answer is enclosed within the slot tags',
	'component.exerciseManage.cloze.select': 'the correct answers are enclosed within the select tags ',
	'component.exerciseManage.jsonFormat': 'The options are in JSON format.',
	'component.exerciseManage.jsonFormatExample':
		'Please remember to escape characters like backslash (/) and double quote (") with a blackslash.',
	'component.exerciseManage.dragDrop.option': 'used for setting the options ',
	'component.exerciseManage.dragDrop.slot': 'used for setting the answer',

	'component.exerciseManage.dragDrop.resetContent1': 'Reset when dragdrop error happened.', //警告，應於莫名無法拖曳時使用，
	'component.exerciseManage.dragDrop.resetContent2': 'Will clear answer, really to reset?', //點擊將重置此題作答，確認重置？

	'component.exerciseManage.alert.somethingWrong': 'Something went wrong. Please report as bug.',

	'component.exerciseManage.alert.slotFillingContent': 'Slot-Filling Exercise should only fill in programming language', //填空題內容應為程式碼\n請寫入正確程式語言的程式碼
};

const exerciseAndTest = {
	'component.exerciseMainInfo.lang': 'Programming Language (exercise)',
	'component.exerciseMainInfo.testlang': 'Programming Language (quiz)',
	'component.exerciseMainInfo.title': 'Exercise Title',
	'component.exerciseMainInfo.testTitle': 'Quiz Title',
	'component.exerciseMainInfo.hashtag': 'Tags',
	'component.exerciseMainInfo.link': 'Video Link',
	'component.exerciseMainInfo.content':
		'Exercise Content (The size of the photo must be adjusted before uploading if required)',
	'component.exerciseMainInfo.contentShort': 'Exercise Content',
	'component.exerciseMainInfo.testExplainPlaceholder': 'Quiz Details',
	'component.exerciseMainInfo.titlePlaceholder': 'Enter Title',
	'component.exerciseMainInfo.testTitlePlaceholder': 'Enter Quiz Title',
	// 'component.exerciseMainInfo.hashtagPlaceholder': 'Please seperate the tags by #',
	'component.exerciseMainInfo.hashtagPlaceholder':
		'Please add # before each tag and do not use blank space. ( ex. #Level1#Coding )',

	'component.exerciseMainInfo.videoSource': 'Video Source',
	'component.exerciseMainInfo.linkInput': 'Please fill in the video link (Currently supports Youtube and Vimeo)',
	'component.exerciseMainInfo.linkSelectFromResource': 'or select existing video from Library',
	'component.exerciseMainInfo.insertVideo': 'Insert video',
};

const exerciseTipInfo = {
	'component.exerciseTipInfo.new': 'Add New Tips',
	'component.exerciseTipInfo.title': 'Tips Title',
	'component.exerciseTipInfo.content': "Please Fill in the Tip's Title",
	'component.exerciseTipInfo.titlePlaceholder': "Tip's Content",
	'component.exerciseTipInfo.contentPlaceholder': "Please Fill in Tip's Content",
	'component.exerciseTipInfo.confirmNew': 'Confirm',
};

const exerciseAnswerInfo = {
	'component.exerciseAnswerInfo.ans': 'Add Exercise Answer',
	'component.exerciseAnswerInfo.title': "Answer's Title",
	'component.exerciseAnswerInfo.titlePlaceholder': "Please Fill in the Answer's Title",
	'component.exerciseAnswerInfo.content': "Answer's Content",
	'component.exerciseAnswerInfo.contentPlaceholder': "Please Fill in the answer's Content",
	'component.exerciseAnswerInfo.confirmNew': 'Add New Answer',
};

const exerciseTestInfo = {
	'component.exerciseTestInfo.title': 'New Test Data',
	'component.exerciseTestInfo.percentage': 'Percentages',
	'component.exerciseTestInfo.input': 'Input',
	'component.exerciseTestInfo.inputPlaceholder': 'Enter Input',
	'component.exerciseTestInfo.output': 'Output',
	'component.exerciseTestInfo.outputPlaceholder': 'Enter output',
	'component.exerciseTestInfo.confirmNew': 'Create New Test Data',
};

const testManage = {
	'component.testManage.alert.title': 'Please fill in the quiz title',
	'component.testManage.alert.content': 'Please fill in the quiz content',
	'component.testManage.alert.option': 'Please choose the questions',
	'component.testManage.alert.portion': 'The total full marks of the quiz must add up to 100',
	'component.testManage.alert.score': 'Please enter the full marks of the quiz',
	'component.testManage.test': 'Quiz',
	'component.testManage.createTest': 'Create Quiz',
	'component.testManage.edit': 'Edit Quiz',
	'component.testManage.create': 'Create Quiz',
	'component.testManage.info': 'Quiz Information',
	'component.testManage.question': 'Questions',
	'component.testManage.scoring': 'Score',
	'component.testManage.preview': 'Preview Quiz',
	'component.testManage.confirmEdit': 'Confirm',
	'component.testManage.confirmNew': 'Confirm',

	'testManage.previewExercise': 'Exercise Preview',
	'testManage.closePreview': 'Close Preview',
	'testManage.selectFrom': 'Select form Library',
	'testManage.createNew': 'Create New Question',
	'testManage.loadingStudent': "Loading Students' Info....",
	'testManage.addNewExercise': 'Add exercise for classroom activity',
	'testManage.addNewHomework': 'Add exercise as homework',
	'testManage.addhandout': 'Add Handout',

	'component.testManage.info': 'Basic Information',
	'component.testManage.note.1':
		'New version of quiz functions. Please import exercises from Library. You can directly drag and drop exercises to adjust the order.',
	'component.testManage.note.2':
		'If there are more than one test data in the same question, please set the allocation ratio of each test data first. Otherwise, the system will automatically distribute the ratio evenly.',
	'component.testManage.note.3':
		'Notice: Non-problem-solving type of exercises, such as microbit / pygame / video, are automatically filtered during import.',
	'component.testManage.import.questions': 'Import Exercise or Exercise Set',
	'component.testManage.import.question': 'Import Exercise',
	'component.testManage.preview.question': 'Preview Exercise',
	'component.testManage.question.step': 'Quiz Exercises',
	'component.testManage.event.step': 'Quiz Settings',
	'component.testManage.repeat.exercise': 'The exercise is repeated ',
	'component.testManage.repeat.exercise.btn.confirm': 'Stay on this page',
	'component.testManage.repeat.exercise.btn.next': 'Continue to the next step',
	// 'component.testManage.repeat.info':'The following exercises have been detected as duplicate content. Please confirm if you want to continue to set the test paper.',
	'component.testManage.repeat.info':
		'The following exercises have been detected as duplicate content. Please reset the test paper.',
	'component.testManage.repeat.number': 'Exercise number is repeated ',

	'component.StartTestAlertModal.title': 'Test Start',
	'component.StartTestAlertModal.content': 'A test has been held, Hurry up to take!',

	'component.EndTestAlertModal.title': 'Test End',
	'component.EndTestAlertModal.content': 'the test is ended.',

	'component.studentNotification.endTest': 'Your test is ended, go to view your result!',
};

const lessonAppendExercise = {
	'lessonAppendExercise.selectLang': 'Please Choose the Programming Language on Quiz Information Page',
	'lessonAppendExercise.onlyShow': "Only show {{codeType}}'s questions",
	'lessonAppendExercise.please': 'Please upload the handout through',
	'lessonAppendExercise.please_2': '',
	'lessonAppendExercise.addToCourse': 'Add exercise to the course',
	'lessonAppendExercise.noMatch': 'No matching exercises',
	'lessonAppendExercise.alert.hw': 'This exercise is used by one or more homework(s)!',
	'lessonAppendExercise.alert.course': 'This Exercise is used by one or more course(s)!',

	'lessonAppendExercise.forceSort': 'Sort By Title', //依照標題排序
	'lessonAppendExercise.sortingExercise': 'Resorting Exericise...', //重新排序中，請耐心等候
	'lessonAppendExercise.subtitle.amountcount': '{{amountcount}} items selected',
	'lessonAppendExercise.repeated.alert':
		'Please note that adding the same exercise to classroom exercise or homework is avoided.',
	'lessonAppendExercise.existed.alert':
		'Some of the selected exercises has existed in classroom exercise or homework. Please unselect them.',
	'lessonAppendExercise.material': 'Handout can be added via library. Please upload your handout to ',
	'lessonAppendExercise.material.note2': ' first.',

	'lessonAppendExercise.no.exercise': 'No exercise...', //教學資源庫尚未有任何習題
	'lessonAppendExercise.no.video': 'No video...',
	'lessonAppendExercise.no.exerciseGroup': 'No exercise group...',

	'lessonAppendExercise.noSearch.exercise': 'No search result of exercise...', //没有符合搜寻的习题
	'lessonAppendExercise.noSearch.video': 'No search result of video...',
	'lessonAppendExercise.noSearch.exerciseGroup': 'No search result of exerciseGroup...',

	'lessonAppendMaterial.no.material': 'No Materials...', //教學資源庫尚未有任何講義
	'lessonAppendMaterial.noSearch.material': 'No search result of Materials...', //沒有符合搜尋的講義
};

const testRatioInfo = {
	'testRatioInfo.none': 'No exercise yet',
	'testRatioInfo.note.1':
		'Please set the score of each question. If you click Automatic Allocation, the system will automatically allocate the score equally (total score 100). ',
	'testRatioInfo.note.1.sub':
		'and it will be rounded to the nearest tenth, so the difference will be added to the last question!',
	'testRatioInfo.avg': 'Automatic Allocation',
	'testRatioInfo.sum': 'Current Total Score',
	'testRatioInfo.individual.allocation': 'Individual Allocation',
};

const testPreview = {
	'testPreview.content': 'Content',
	'testPreview.lang': 'Programming Language',
	'testPreview.details': 'Quiz Content',

	'testPreview.gototest': 'Go to Test',
	'testPreview.gotomock': 'Go to Challenge',
	'testPreview.handintest': 'Submitted',
	'testPreview.commingsoon': 'Coming Soon',
	'testPreview.viewtestdetail': 'View Test Content',
	'testPreview.testscore': 'Test Scores',
	'testPreview.testamountofquestion': 'Questions',
	'testPreview.testspendingtime': 'Your Spending Time',
	'testPreview.testendgap': 'Time to the end of the event',

	'testPreview.highestscore': 'Highest Score',
	'testPreview.testamountofenter': 'Challenge Times',
	'testPreview.lastscore': 'Last Score',
};

const addMaterial = {
	'addMaterial.success': 'Successfully Added',
	'addMaterial.fail': 'Failed',
};

const course = {
	'course.backToMyCourses': 'Back to Course',
	'course.newTip': 'Add New Course',
	'course.all': 'All Courses',
	'course.allLessons': 'All Lessons',
	'course.ready': 'About to Begin',
	'course.ongoing': 'In Progress',
	'course.notyet': 'Not Started Yet',
	'course.end': 'Ended',
	'course.package-ongoing': 'PKG Course',
	'course.package-past': 'PKG Course(EXP)',
	'course.self-ongoing': 'SLF Course',
	'course.self-past': 'SLF Course(ED)',
	'course.teacher': 'Instructor {{teacher}}',

	'course.buymyself.banner1': 'Purchase a package course or increase the maximum number of people ?',
	'course.buymyself.banner2': 'Make your teaching easier!',
	'course.buymyself.banner3':
		'50 free student vacancies available for SLF courses.You’ve got tons of students? Contact Us !',
	'course.buymyself.peopleused': 'Number used',
	'course.buymyself.peoplemax': 'Personal free quantity',
	'course.buyunit.peoplemax': 'Unit remaining quantity',
	'course.banner.contactus': 'Contact CodingBar',
	'course.banner.checklimit': 'Check Vacancies',

	'course.modal.modalTitle.studentRemainingUse': 'Available Vacancies for Student',
	'course.modal.modalBody.studentRemainingUse':
		'Under this account: {{teacherLimit}}\n\nUnder this institute: {{unitLimit}}',

	'course.sign': 'Signed Up Courses',
	'course.no': 'No Courses Yet',
	'course.name': 'Course Title',
	'course.cid': 'Course Cid', //課程代碼

	'course.num': 'Courses',
	'course.noclass': 'The Course has either ended or not started yet',
	'course.nosearch': 'No courses',
	'course.search': 'Enter Keyword or Course ID',
	'course.enterPractice': 'Enter Practice Exercise Directly',
	'course.students': 'students',
	'course.cancelCodeEnter': 'Cancel Enter by Course Code',
	'course.activeCodeEnter': 'Activate Enter by Course Code',
	'course.codeEnter': 'Enter Courses by Course Code',
	'course.copyCode': 'Copy Course Code',
	'course.openCode': 'Course Code Opened',
	'course.delete': 'Delete',
	'course.endCourse': 'End this Course',
	'course.template': 'Duplicate',
	'course.use': 'Use',
	'course.templateConfirm': 'as a template?',
	'course.templateAlert': 'Please give a new course ID and set schedule for the duplicated course.',
	'course.endEarly': 'End the Course Early?',
	'course.deleteConfirm': 'Delete this course?',
	'course.endMsg':
		'The end date of this course is changed to today, you can find this course\'s information in the "Ended" section.',
	'course.deleteMsg':
		'Are you sure you want to remove this course? You cannot check any information of this course if you delete it. \nYou can’t undo this action, confirm to continue.',

	'course.deadline': 'Expired on:',

	'course.saveFailed': 'Failed to save the course!',
	'course.deleteSuccess': 'Course deleted successfully!',
	'course.copying': 'Copying course information...',
	'course.noExercise': 'No Exercise Yet',

	'course.handout': 'Handouts',
	'course.exercise': 'Classroom Exercise',
	'course.homework': 'Homework',
	'course.allpract': 'All Exercises',
	'course.collection': 'My Collections',

	'course.live': 'Online Classrooms',
	'live.class': 'Teaching Mode',
	'live.test': 'Test Mode',
	'live.nounits': 'No Other Lessons',
	'course.attachment': 'Supplemetary Materials',
	'course.norelease': 'Homework not Released yet',
	'course.no_homework': 'No Homework Yet',

	'course.endMsg2': 'This course has already ended.',
	'course.addhandoutFailed': 'Failed to add new handout to {{course}}',
	'course.updateExerciseFailed': "'s exercise listing failed.",
	'course': 'Courses',
	'course.test.start': 'Quiz Start!',
	'course.test.end': 'End Course ',
	'course.test.end2': 'Quiz ',
	'course.test.startFailed': 'Failed to start the quiz!',
	'course.test.endFailed': 'Failed to end the quiz!',
	'course.test.setduration': 'Choose the quiz time limit',
	'course.testIng': 'Quiz in Progress',
	'course.test.handin': 'Hand In',
	'course.test.not.handin': 'Not yet handed in', // 尚未交卷
	'course.test.noticeTitle': 'Notices for the practice test',
	'course.test.noticeContext': `Time for this test is 150 minutes, and the test is divided into "twenty multiple choice questions" and "two program implementation questions". Please do not close the browser or disconnect the Internet or leave the test interface during the test, otherwise your answers will not be saved. \nWhen starting the exam, click on the first question of the unfinished item to enter the compiler environment to start the exam;\n(The	program implementation question environment provides answers in Python, C, and C++ languages, please switch the programming language through the setting left)\n When answering the questions, please make sure to press the submit button. You can check the submitted answers through the icon on the left. When all the questions are completed, you can click on the submit button to end the exam, or the system will automatically submit the answers you have done when the time is over; You will receive a test result and simple analysis within 5 minutes as a reference before the formal test. \n Click the button below to start the timed test, I wish you everything goes smoothly in the test ! ; )`,

	'course.test.EndTitle': 'The test is about to end',
	'course.test.EndContext': `Please note that before the test ends, please make sure that you have "submitted" the code/answers of all questions to the system, otherwise you will not be able to get scores\nAfter the test ends, we will send the test results to your registered email within five minutes!`,
	'course.test.HandinTitle': 'Are you sure you want to hand in test？',
	'course.test.HandinContext': `You still have {{unsubmitquestion}} questions not submit（total {{totalquestion}} questions), and {{markquestion}} questions to be mark.
	once you hand in, you cannot answer anymore. Please check this action carefully!`,

	'course.test.notitle': 'No Test Questions',
	'course.test.name': 'Quiz Name',
	'course.test.tried': 'Attempted Questions',
	'course.test.notfinished': 'No attempt has been made so far',
	'course.test.placeholder': 'Quiz, Midterm...',
	'course.test.time': 'Quiz Time',
	'course.endTest': 'End Quiz',

	'course.endTest2': 'End Exam',
	'course.returnTest': 'Back to the exam',
	'course.timesUp': '！The exam ends！',

	'course.test.title': 'Quiz Title',
	'course.test.tip': 'Questions will be available for viewing after a quiz is selected',
	'course.test.choose': 'Please Choose...',

	'course.test.commonModalTitle': 'Notice',
	'course.mock.noticeContext1': 'Before Starting The Test...You should know：',
	'course.mock.noticeContext2':
		'Do not close browser or disconnect network, otherwise you might lose your result, you also cannot answer after end time.',
	'course.mock.noticeContext3':
		'If test done, you can click button to hand in ,or auto-hand in after time up. You cannot re-answer after hand in.',
	'course.mock.noticeContext4':
		'You can challenge one of test repeatedly, but in the review mode, you only can view last answer record.',
	'course.mock.noticeContext5': 'Click Button to start test, Hope you brush questions brush cool cool!',

	'course.test.noticeContext1': 'Before Starting The Test...You should know：',
	'course.test.noticeContext2':
		"If you close browser or disconnect networ, the test timer still count down, you cannot keep answer if Time'sUp.",
	'course.test.noticeContext3':
		'If test done, you can click button to handin ,or auto-handin after timeup. You cannot re-answer after handin.',
	'course.test.noticeContext4':
		'if your teacher arrange test-question randomly, question may have different index in preview-mode.',
	'course.test.noticeContext5': 'Click Button to start test, Have a nice test！ ; )',

	'course.retest': 'Retake',
	'course.retest2': 'Retake Quiz ',
	'course.addTesttoCourse': 'Add Quiz to the Course',
	'course.lessonEnd': 'This Lesson has Already ended',
	'course.notstarted': 'This Lesson has not Started yet',
	'course.time': 'Timetable for this Week',
	'course.timeFormat': 'Course Time',
	'course.nextTime': 'Next Course Date',
	'course.alreadyEnd': 'This Lesson has already ended',
	'course.nohandout': 'No Handouts Yet',
	'course.noExercise': 'No Exercises Yet',
	'course.noHomework': 'No Homeworks Yet',

	'course.removeExercise': 'Remove this Exercise',
	'course.sync': 'Sync Resources',

	'course.content': 'Course Description',

	'course.videoError': 'There is an Error when Loading the Video.',
	'course.videoTutorial': 'Video Tutorial',
	'course.vidTutorial1': '1. Press ⇨ to Fast Forward',
	'course.vidTutorial2': '2. Press ⇦ to Fast Backward',
	'course.vidTutorial3': '3. Long press ⇨ or ⇦ button to Continously Fast Forward / Backward',
	'course.vidTutorial4':
		'4. The Gear Icon in the Control Section can be used to Adjust the Video Quality / Playback Speed',
	'course.videoDontShow': "Don't show this Again",

	'course.deleteConfirmMsg': 'All Lessions and Their Settings will be Permanently lost after Deletion.',
	'course.thisweek': 'This Week',
	'course.lesson': 'Lessons',
	'course.a.lesson': 'Lesson',

	'course.add': 'Enter Course',
	'course.addSuccess': 'Quiz added to {{title}}',
	'course.addFail': 'Failed to add quiz to {{title}}',

	'classunit.D': 'Confirm Lesson Date and Content',
	'classunit.editClass': 'Edit Class',
	'classunit.newClass': 'Add New Course',
	'classunitlist.lessonTitle': 'Lesson Title',
	'classunitlist.day': 'Date',
	'classunitlist.startTime': 'Start Time',
	'classunitlist.endTime': 'End Time',
	'classunitlist.content': 'Lesson Content',
	'classunitlist.add': 'Add Shcedule',
	'classunitlist.courseday': 'Weekly Schedule ',

	'classinfo.begin': 'Beginner',
	'classinfo.medium': 'Intermediate',
	'classinfo.advanced': 'Advanced',
	'classinfo.A': 'Add Programming Languages and Difficulty Level',
	'classinfo.lantype': 'Programming Languages',
	'classinfo.MainLanType': 'Main Language',
	'classinfo.level': ' Difficulty Level',
	'classinfo.Amount': 'Number of courses',
	'classinfo.CourseCustomLabel': 'Custom label',
	'classinfo.CourseCustomLabelDescription': 'Superb CustomLabel let you search faster',
	'classinfo.weeks': 'Weeks',
	'classinfo.startDate': 'Start Date',
	'classinfo.B': 'Add Course Code and Title',
	'classinfo.code': 'Course Code',
	'classinfo.title': 'Course Title',
	'classinfo.CourseTitle': 'Enter course name',
	'classinfo.CourseTitleDescription': 'Course Title should not more than X words',
	'classinfo.teacher': 'Instructor',
	'classinfo.mainpoint': 'Main Concepts',
	'classinfo.CourseIntro': 'Enter course description or notes',
	'classinfo.C': 'Assign Weekly Course Date',
	'classinfo.idplaceholder': '6-8 characters (alphanumeric)',
	'classinfo.CourseAmountLimit': 'Please enter course amount',
	'classinfo.nameplaceholder': '15 characters max (including Chinese characters)',
	'classinfo.description': 'Please provide more context for this course',

	'course.exerciseFinished': 'All Exercises are Finished.',
	'course.moreExercise': 'More Exercises!',
	'course.exercise.nocollection': 'No Collections',
	'course.exercise.collectionTips': 'Add this Exercise to My Collections!',

	'course.entercode': 'Enter Course Code',
	'course.code': 'Course ID',
	'coursecode.placeholder': 'Enter Course ID',
	'coursecode.change': 'Modify the Seat No. of this Course Code',
	'coursecode.hint': "You can add Courses via the Course Code Provided by the School's Instructors",
	'coursecode.nocourse': 'No Courses Found, Please make sure the Code is correct.',

	'course.nostudent': 'The Student List is empty, are you sure you want to update this Course?',

	'course.placeholder.searchKeywordOrQuestion': 'Enter Keywords',
	'course.placeholder.searchKeywordOrLesson': 'Enter Keywords',

	'course.labels.inProgress': 'In Progress',
	'course.labels.notStarted': 'Not Started',
	'course.labels.ended': 'Ended',
	'course.labels.live': 'Live!',

	'course.clone.copyCourseAsPackage': 'Duplicate as PKG course',
	'course.clone.copyToOtherUnit': 'Copy this Course to other Groups',
	'course.clone.copyCourse': 'Share course & resources',
	'course.clone.copyAssignUnit': 'Assign to other teacher',
	'course.export.exercise': 'Export course',
	'course.clone.currentUnit': 'Current Group',
	'course.clone.currentCourseId': 'Current Course ID',
	'course.clone.targetUnit': 'Target Group',
	'course.clone.targetTeacher': 'Target Instructor',
	'course.clone.placeholder': 'Optional, will be Automatically Generated',
	'course.clone.teacherHasCourse': 'The Chosen Instructor Already has this Course',
	'course.lesson.notSetTime': 'Time has not been set yet',

	'course.cloneToolTips.cloneResource': 'Clone Resource', //複製課程資源
	'course.cloneToolTips.exerciseAndVideo': 'Exercise/Video', //題目/影片
	'course.cloneToolTips.cloneHandout': 'Handout', //講義
	'course.cloneToolTips.cloneTest': 'Test', //試卷
	'course.cloneToolTips.cloneGroupExercise': 'GroupExercise', //題組
	'course.cloneToolTips.editTest': 'Edit Test', //編輯試卷
	'course.cloneToolTips.createTest': 'Create Test', //建立新考試活動
	'course.cloneToolTips.cloneToClone': 'Share Template', //將副本課程分享他人

	'course.package.addPackage': 'Import Package Course',
	'course.package.copyCourseAsPackage.title1': 'Let', //將
	'course.package.copyCourseAsPackage.title2': 'duplicate as packaged course?', //複製為套裝課程？
	'course.package.copyCourseAsPackage.confirm':
		'Contents in packaged course can’t be edit, please check current course contents before continue.\nAssign packaged course to other institute via sales managment system.',

	'course.transform.asGlobalTest': 'Convert to global mock exam',
	'course.global.mock.exam': 'Global Mock Exam',
	'course.transform.globalTestConfirm': 'CourseType Already globalTest, recover it？',

	'course.duplicate': 'Duplicate',
	'course.today': 'Today',
	'course.delete.lesson': 'Delete Lesson',
	'course.sync.exercise.fail': 'Sync exercise failed',
};

const studentlist = {
	'role.students': 'Students',
	'role.teachers': 'Instructor',
	'role.mentors': 'Teachers',
	'role.staffs': 'Staff',
	'role.staffs1': 'Admins',
	'role.admin': 'Admins',
	'role.other': 'Other',

	'title.teacherMaintenance': 'Instructor Management',
	'title.studentMngmt': 'Student Management',
	'title.staff': 'Administration Management',
	'title.crm': 'Customer ReSource Management', // CRM 名單管理
	'title.unknown': 'Unknown',

	'label.teacher': 'All Instructors',
	'label.student': 'All Students',
	'label.admin': 'All School Admins',
	'label.crm': 'All CRM Accounts', // CRM 名單
	'label.staff': 'Not CRM Accounts', //非 CRM 名單
	'label.courseScore': 'Class results',
	'notloginyet': 'Not logged in yet',
	'totalNum': 'Total Numbers',
	'studentlist.import': 'Import Students',
	'studentlist.confirmImport': 'Confirm Import', //確認匯入
	'studentlist.confirmModal.title': 'Student already exists', //重複學生
	'studentlist.confirmModal.exist.in.unit': 'already exists in current unit', // 已存在目前單位
	'studentlist.confirmModal.exist.in.course': 'already exists in course', // 已存在課程中
	'studentlist.confirmModal.exist.teacher': 'existing teacher account', // 已存在老師帳號
	'studentlist.confirmModal.exist.other.unit': 'already exists in other unit', // 已存在其他單位
	'studentlist.confirmModal.block':
		'Students has some issue below, please check the correct email or remove from list.', // 匯入列表當中以下學生項目仍有問題，請編輯匯入學生資訊或移除。',
	'studentlist.confirmModal.content':
		'Student already exists in unit or course, importing will update the information, please confirm whether you want to import?', // 匯入列表中有 “已存在目前單位” 或 "已存在課程中" 的學生項目，匯入將覆蓋資訊，請確認是否要匯入？
	'studentlist.loading': 'Loading the Student List ...',
	'studentlist.waiting.course.update.result': "The Student List is Being Updated. Please Don't Click the Button Twice",

	'import.noNew': 'No new Student was Added!',
	'import.student.title': 'Import Students to',
	'import.selectInstruction': 'You can Select Students to add to this Course using the Search Bar.',
	'import.searchDropdownName': "Enter Student's Name",
	'import.searchDropdownClass': "Enter Student's Class Name",
	'import.searchPlaceholder': 'Search by Keywords',
	'import.backPage': 'Previous Page',
	'import.selectFile': 'Select Files',
	'import.selectFileLabel': 'No file Selected',
	'import.downloadTemplate': 'Student File Template',

	// 'import.downloadNotes-1': 'Click on the \'File Template\' button on the top-right corner to download an example file. After import\, students\' accounts will be generated automatically,\ and the default password will be \'airabbi\' in lowercase characters. If the file cannot be displayed correctly,\ ',
	// 'import.downloadNotes-2': 'please change encoding to unicode or open it with Google Spreadsheets.\ ',
	// 'import.downloadNotes-3': 'If there is a same student account (email mailbox) already existing in the platform, the student information you imported will not be created.',
	// 'import.downloadNotes-4': '',
	// 'import.downloadNotes-5': '',
	// 'import.downloadNotes-6': '',

	'import.downloadNotes-1': '(1) Please refer to the ', //(1). 請參考
	'import.downloadNotes-2': 'Example Template', //範本檔案
	'import.downloadNotes-3':
		", After import, student's accounts will be generated automatically, If the file cannot be displayed correctly, please", //，依格式將名單製作為 csv 檔並匯入，系統自動產生學生帳號；若開啟 csv 範本為亂碼，請將
	'import.downloadNotes-4': 'change encoding to unicode or open it with Google Spreadsheets.', //編碼變更為 unicode，或使用 google 試算表'
	'import.downloadNotes-5': '', //開啟。
	'import.downloadNotes-6': '(2) Account default password will be', //(2). 學生帳號預設密碼為
	'import.downloadNotes-7': "'airabbi'", //小寫英文字元「airabbi」
	'import.downloadNotes-8':
		'in lowercase characters. If there is a same student account (email mailbox) already existing in the platform, please edit its email or uncheck it.', //；若平台內已有相同的學生帳號(email 信箱)時，請編輯匯入學生 Email 或 取消勾選。

	'import.downloadCsvTemplate': 'Download csv File Template',
	'import.defaultPasswd': "Students' default password",
	'import.defaultPasswd2': '(7 lowercase characters)',
	'import.later': 'Import Students Later',
	'import.fromCSV': 'Import Students from CSV',
	'import.fromResource': 'Import from platform',
	'import.fromCSV.wrongEmailWarning': 'Missing email information or wrong format. Please check and upload again',
	'import.fromCSV.wrongRow': 'Row',
	'import.fromCSV.warning.studentExist':
		'The Selected Student is Already in this Course. Importing Again will update thier information', // 已存在課程中，匯入將更新學生帳號
	'import.fromCSV.warning.studentExist.unit':
		'The Selected Student is Already in this unit. Importing Again will update thier information', // 已存在於單位中，匯入將更新學生帳號
	'import.fromCSV.warning.studentExistInOtherUnit-1': 'This ',
	'import.fromCSV.warning.studentExistInOtherUnit-2': ' Account exists in ',
	'import.fromCSV.warning.studentExistInOtherUnit-3': '. Importing will Replace it as a Student Account.',
	'import.fromCSV.role.teacher': 'Instructor',
	'import.fromCSV.role.student': 'Student',
	'import.studentLimit.passedStudentImportLimit': 'The total number of students is over {{limit}}.',

	'userlistview.confirmDelete': 'Are you sure to that you want to delete',
	'userlistview.unset': 'No Information',
	'userlistview.notFound': 'Not Found',
	'userlistview.noparentData': 'No Parent Information',
	'userlistview.notel': 'No Telephone',
	'userlistview.notification': 'Send Notifications',
	'userlistview.getStudentCourse': 'fetch Student Course', // 獲取學生課程

	'userlistview.newStudent': 'New Student Information',
	'userlistview.newTeacher': 'New Instructor Information',
	'userlistview.newBatchTeacher': 'New Teachers Information',
	'userlistview.newBatchCSFCStudent': 'New CSFC Information', //新增 CSFC 學生資料
	'userlistview.newStaff': 'New Staff Information',
	'userlistview.deleteUser': 'Delete User?',
	'list.backToStudentList': 'Back to the list of students',
	'list.backToPrevious': 'go back to previous layer',
	'import.package.course.student.alert':
		'Note! Once students are imported into the package course and the withdrawal period has passed, they cannot be removed. Please double-check.', // 請注意！套裝課程的學生一但匯入後超過反悔期，將無法被移除，請再三確認
};

const test = {
	'test.title': 'Quiz',
	'test.no': 'No Quizzes so far',
	'test.nopractice': 'No Practice so far',
	'test.notitle': 'No Questions so far',
	'test.noresult': 'No Quiz Result so far',
	'test.nottakeyet': 'Not Taken Yet',
	'test.alltest': 'Tests',
	'test.allpractice': 'Mock tests',
	'test.tested': 'Finished',
	'test.unchangable': 'This is Unchangable',
	'test.add.tip': 'Course Quizzes',
	'test.add.select': 'Select Quiz',
	'test.add.new': 'Add New Quiz',

	'test.append.title': 'Select Quiz',
	'test.append.lang3': "'s quiz",

	'test.append.lang': "Course Programming Language: {{codeType}}, Only show {{codeType}}'s tests",

	'test.nomatch': 'No matching quizzes',

	'test.classTest': 'In-class Quiz',
	'test.num': 'Quiz Attendants',
	'test.finished': 'Finished',
	'test.leftTime': 'Time Left',

	'test.time': 'Quiz Time',
	'test.infotitle': 'Quiz Questions',
	'test.student': 'Students',
	'test.fail': 'Fail',
	'test.pass': 'Pass',
	'test.absents': 'Absent',
	'test.score': 'Score',
	'test.corrNum': 'Correct',
	'test.wrongNum': 'Wrong',
	'test.studentScore': 'Student Score',
	'test.ended': 'The Quiz has Already Ended.',

	'test.solvingTime': 'Time',
	'test.videoTime': 'Watch Time',
	'test.runtime': 'Run',

	'test.finishedpeople': 'Keep up the Good Work! {{progress}} Classmates have Already Finished!',
	'test.be1': 'Be the First One to Finish this Question!',

	'test.correct': 'Correct',
	'test.incorrect': 'Incorrect',

	'test.confirmRetake': 'Try this question again?',
	'test.openFolder': 'Open Folder',

	'test.hasSubmitted': 'Submission Success',
	'test.tryNextExercise': 'Move on to the next exercise!',

	'test.alert.preventCopyPaste': 'Exam in progress. Copy / Paste is not allowed :(',
	'test.finished': 'The exam has finished',
	'test.alert.testFinished': 'The exam has finished',
	'event.score.sum': 'Total score',
};

const testEvent = {
	'test.event.type': 'Event Type',
	'test.event.course.test': 'Classroom exam',
	'test.event.mock.test': 'Mock tests',
	'test.event.is.start.now': 'Whether to start now',
	'test.event.course': 'Courses',
	'test.event.title': 'Test Title',
	'test.event.duration': 'Test Duration (Minutes)',
	'test.event.start.date': 'Start date',
	'test.event.start.time': 'Start time',
	'test.event.end.date': 'Deadline date',
	'test.event.end.time': 'Deadline time',
	'test.event.test.info': 'Test Instructions',
	'test.event.test.result': 'Show Answer results',
	'test.event.test.import': 'Import Test Paper',
	'test.event.placeholder.test.title': 'Enter test name',
	'test.event.placeholder.duration': 'Enter minutes',
	'test.event.duration.alert': 'Must between 1~9999 mins',
	'test.event.not.set.yet': 'Not specified yet',
	'test.event.step.one.note1':
		'As for the  pre-scheduled the test time, the system will automatically open the test activity at the designated time. If your select "Student Practice" ,it will allow students to repeat the mock test, but the result will not be included in the student\'s grades',
	'test.event.step.one.note2': 'Please import the test paper from Library. Each test contains only one test paper.',
	'test.event.step.one.note3':
		'According to different needs, you can freely release test cases (if the exercise contains test cases), answers, tips or randomly order exercises.',
	'test.event.settings.mode.title': 'Test Mode Settings',
	'test.event.settings.assist.title': 'Test Paper Auxiliary Information',
	'test.event.settings.vd.title': 'Test cases display settings',
	'test.event.settings.other.title': 'Other settings',

	'test.event.settings.mode.custom': 'Custom',
	'test.event.settings.vd.vdResults': 'Show test case result',
	'test.event.settings.vd.vdContent': 'Show test case content',
	'test.event.settings.other.random': 'Random order',
	'test.event.settings.other.copyPaste': 'Allow Paste Code', //允許複製貼上

	'test.event.settings.vd.tip': 'The first test case tip',
	'test.event.settings.vd.length': 'Number of test cases: {{num}}',

	'review.test.modal.title': 'Test Cases Settings',
	'test.event.placeholder.releaseVdLength': 'Number of released test cases',
	'review.test.setting.title': 'Review resource settings',
	'review.test.setting.note':
		'You can release relevant resources here and students will be able to view complete answers, tips and test cases in review mode, re-practice, submit, and correct their concepts.',
	'review.test.event.info': 'Test Event Information',
	'test.event.settings.chose.begin.date': 'Please select start date',
	'test.event.settings.chose.end.date': 'Please select deadline date',
	'test.event.settings.alert.end.date': 'Should be later than start date',
	'test.event.settings.alert.end.time': 'Should be later than start time',
	'test.event.settings.alert.duration': 'Should longer than test duration',
	'test.event.review.students.note': 'View student scores',
	'test.event.review.students.note.download.csv': 'Download Test Status CSV',

	'test.event.show.advanced.setting': 'Show advanced settings',
	'test.event.hide.advanced.setting': 'Hide advanced settings',
	'test.event.have.not.select.test.yet': 'No test paper imported yet',
	'test.event.settings.assist.vd.type': 'Test Case Exercise Assistance Information',
	'test.event.settings.assist.none.vd.type': 'Non Test Case Exercise Assistance Information',

	'test.detail.page.reset.student.exercise.status': 'Reset student answer record',
	'test.detail.page.reset.student.exercise.status.note':
		"When you change the exercise content in Library and sync to courses, the student records that have already answered will not match to the updated exercise content.\nResetting a student's answer record will delete the student's record and score. Are you sure you want to reset all student answer records for this test exercise?",
	'reset.studentStatus.success': 'Reset successful ',
	'reset.studentStatus.fail': 'Failed to reset ',
	'csfc.test.event': 'CSFC test sessions', // CSFC 考試場次
	'csfc.test.event.not.add.yet': 'No sessions have been created yet', // 尚未建立場次
	'csfc.test.event.create.modal.title': 'Create CSFC test sessions', // 建立 CSFC 考試場次
	'csfc.test.event.edit.modal.title': 'Edit CSFC test sessions', // 編輯 CSFC 考試場次
	'csfc.test.event.delete.modal': 'Delete CSFC test sessions', // 刪除 CSFC 考試場次
	'csfc.test.event.delete.modal.body': 'Confirm whether to delete CSFC test session', // 確認是否刪除 CSFC 考試場次
	'csfc.test.event.delete.modal.toast.success': 'Successfully deleted CSFC test sessions', // 成功刪除 CSFC 考試場次
	'csfc.test.event.preview.modal.title': 'Preview CSFC test sessions', // 預覽 CSFC 考試場次
	'csfc.test.event.form.subject': 'Test items', // 考試場次項目
	'csfc.test.event.form.level': 'Test level', // 考試場次等級
	'csfc.test.event.form.concept.test.duration': 'Concept test time (minutes)', // 觀念題考試時間（分鐘）
	'csfc.test.event.form.implementation.test.duration': 'Implementation test time (minutes)', // 實作題考試時間（分鐘）
	'csfc.test.event.form.implementation.earlier.than.concept.alert':
		'The start time of implementation test should be greater than the end time of concept test', // 實作題開始時間應大於觀念題結束時間
	'csfc.test.event.form.can.not.set.past.test.alert': 'Cannot set past time', // 不可設定過去時間
	'csfc.test.event.form.note.1':
		'Pre-arrange the test time, the system will automatically open the test activities at the specified time', // 預先排定考試時間，系統將於指定時間自動開放測驗活動
	'csfc.test.event.form.note.2': 'Please import the test paper from Library, a test contains only one test paper', // 請由教學資源庫匯入試卷，一場考試僅含一張試卷
	'csfc.test.event.form.concept.note':
		'The first test (according to the CSFC regulation, please set conceptual exercises)', // 第一場考試 (根據 CSFC 簡章，請設置觀念題)
	'csfc.test.event.form.implementation.note':
		'The second test (according to the CSFC regulation, please set implementation exercises; the primary test does not need to set the second test)', // 第二場考試 (根據 CSFC 簡章，請設置實作題；初級考試不需設置第二場考試)
	'csfc.delete.course': 'Delete CSFC Course', // 刪除 CSFC 課程
	'csfc.delete.course.confirmation.body': 'Confirm whether to delete CSFC course', // 確認是否刪除 CSFC 課程
	'csfc.delete.course.and.event.toast.success': 'Successfully deleted CSFC test sessions and courses', // 成功刪除 CSFC 考試場次與課程
	'csfc.delete.course.alert.user.already.exist': 'Unable to delete csfc courses that have been imported to students', // 已匯入學生無法刪除 CSFC 課程
	'csfc.delete.event.alert.user.already.exist': 'Unable to delete csfc sessions that have been imported to students', // 已匯入學生無法刪除 CSFC 場次
	'csfc.edit.event.alert.user.already.exist': 'Unable to edit csfc sessions that have been imported to students', // 已匯入學生無法編輯 CSFC 場次
};

const score = {
	'score.title': 'Student Scores',
	'score.avg': 'Average',
	'score.sum': 'Sum',
	'score.none': 'No Scores Yet',
	'score.download': 'Download Scores as CSV',
};

const student = {
	'student.learningreport': 'Progress Report',
	'student.none': 'You can import Students', //您尚未匯入學生 => 你可以匯入學生啊!(／‵口′)／~╧╧' 怎麼不匯入www
	'student.placeholder': "Enter students's name/email",
	'student.placeholder2': 'Enter account or name keyword',
	'student.sendreport': 'Send Report',
	'student.sendreport.confirm': 'You are About to send the Report? Please make sure that all the Scores are Correct',
	'student.downloadReport': 'Download Report',
	'student.fillReport': 'Fill in Report',
	'student.advanced': 'Advanced',
	'student.removeConfirmTitle': 'Remove Student',
	'student.confirmation1': 'Confirm',
	'student.reAddStudent.confirm.title': 'Re-add Student',
	'student.reAddStudent.confirm.content': 'Confirm that you want to re-add student?',
	'student.confirmation2': 'Confirm Again!',
	'student.lastShown': 'Last Online',
	'student.reportSendTime': 'Report sent at {{time}}',
	'student.sta': 'Statistics',
	'student.finishQs': 'Finished Questions',
	'student.exrciseTime': 'Practice Time After Class this Week',
	'student.avgTipUsage': 'Average Hint Usage',
	'student.question': 'Questions Asked this Week',
	'student.info': 'Student Info',
	'student.sex': 'Gender',
	'student.male': 'Male',
	'student.female': 'Female',
	'student.unknown': 'Not Selected',
	'student.school': 'School',
	'student.grade': 'Grade',
	'student.summary': 'Summary',
	'student.currentFeedback': 'Recent Feedbacks',
	'student.editFeedback': 'Edit Feedback',
	'student.sendCareMsg': 'Send Care Message',
	'student.courseProgress': 'Course Progress',
	'student.courseTime': 'Course Time',
	'student.futureandthisweekCourse': 'Upcoming Lessons for this Week',
	'student.showCourseNameandProgress': 'Lesson Name and Progress',
	'student.unitEnd': 'Ended Lessons',
	'student.allFeedback': 'All Feedbacks',
	'student.courseinfo': 'Course Info',
	'student.nocourseinfo': 'No Course Info',
	'student.timeuse': 'Usage Time',
	'student.teacherFeedBack': "Instructors' Feedbacks",
	'student.basicinfo': 'Basic Info',

	'student.sendCareMsgTitle': 'Send Care Message to',
	'student.care.emal': "Please Enter Student's E-mail",
	'student.care.emalTitle': "Student's E-mail",
	'student.care.parentemal': "Please Enter Parent's E-mail",
	'parent.care.emalTitle': 'Parent E-mail',
	'studentMsgTitle': 'Student Email Title',
	'parentMsgTitle': 'Parent Email Title',
	'studentMsgTitle.placeholder': "Please Enter Student's Email Title",
	'parentMsgTitle.placeholder': "Please Enter Parent's Email Title",
	'asktimes.placeholder': 'Please Enter the Number of Times when a Question is Asked',
	'asktimes': 'Numer of Times when a Question is Asked ',

	'tutorialvids': 'Video Tutorials',
	'tutorialvids.fin': 'Finished Videos',
	'tutorialvids.total': 'Total Videos',
	'exercise.fin': 'Finished Exercises',
	'exercise.correct': 'Correct Exercises',
	'exercise.total': 'Total Exercises',

	'hw.fin': 'Finished Homeworks',
	'hw.correct': 'Correct Homeworks',
	'hw.total': 'Total Homeworks',

	'studentcomments': 'Student Comments',
	'parentComments': 'Parent Comments',

	'student.continue.course': 'Continue the class',
};

const exercise = {
	'exercise.exercise': 'Exercises',
	'exercise.homework': 'Homeworks',
	'exercise.status': 'Exercise Status',
	'exercise.studentSummary': "Students' Summary",
	'exercise.ans': 'Answer',
	'exercise.student': 'Students Online',
	'exercise.mainpoint': 'Need Attention',
	'exercise.help': 'Help Needed',
	'exercise.idlestate': 'Idle State',
	'exercise.finished': 'Finished',
	'exercise.time': 'Time Spent',
	'exercise.simcheck': 'Check Similarity',
	'exercise.simcheckTitle': 'Check Code Similarity',
	'exercise.finishRate': 'Completion Rate',
	'exercise.readTips': 'Tips Read',
	'exercise.realtimecode': 'Coding Live Stream',
	'exercise.codeLastTime': 'Previous Execution',
	'exercise.codeLanguage': 'Programming Language',
	'exercise.helperSettings': 'Helper Settings',
	'exercise.openCodeHelper': 'Open Code Helper',
	'exercise.editorSettings': 'Editor Settings',
	'exercise.editorLanguage': 'Editor Language',
	'exercise.fontSize': 'Font Size',
	'exercise.notRunyet': 'Not Executed Yet',
	'exercise.checkDetails': 'Click to Check the Details',
	'exercise.progress': "Student's Progress",
	'exercise.resource': 'Download Resources',
	'exercise.result': 'Result',
	'exercise.exampleformat': 'Example Format',
	'exercise.input': 'Input',
	'exercise.output': 'Output',
	'exercise.newFn': '*New Features*',
	'exercise.newFn1': '1. Execute: Only execute the code',
	'exercise.newFn2': '2. Submit: Run the code against the test suites',
	'exercise.gotit': 'Got it',
	'exercise.closeTip': "Don't remind me next time",
	'exercise.fillinMode': 'Switch to Filling mode?',
	'exercise.errorExist': 'There are still some errors, are you sure to submit?',
	'exercise.run': 'Run',
	'exercise.submit': 'Submit',
	'exercise.noVds': 'no Vds',
	'exercise.testfail': 'Tests Failed',
	'exercise.runfail': 'Execution Failed',
	'exercise.pass': 'Pass',
	'exercise.stop': 'Stop',
	'exercise.testinput': 'Test Input',
	'exercise.tips': 'Tips',
	'exercise.answers': 'Answer',

	'exercise.reset': 'Reset',
	'exercise.refresh': 'Refresh',
	'exercise.needhelp': 'Need Help',
	'exercise.idle': 'Idle',
	'exercise.student-exercise': 'Student\\Exercise',

	'exercise.repeatExercise': 'Try Again',
	'exercise.returnExercise': 'Back to Exercise',
	'exercise.nextExercise': 'Next Exercise',
	'exercise.understandMore': 'Click to Check',
	'exercise.whichTestInput1': 'Number',
	'exercise.whichTestInput2': 'Input test',
	'exercise.whichTestInput': 'Number {{number}} test case',
	'exercise.testInputPassed': 'Passed',
	'exercise.testInputFailed': 'Failed',
	'exercise.testInput.resultMsgSuccess': 'Submission successful, congrats!',
	'exercise.testInput.resultMsgFail': 'Submission failed, please check your code again!',
	'exercise.testInput.details': 'View Test Case Details',
	'exercise.testInput.backToDetails': 'Back to Test Case Details',
	'exercise.testInput.yourOutput': 'Your Output',
	'exercise.testInput.correctOutput': 'Correct Output',
	'exercise.testInput.viewDetails': 'View Details',
	'exercise.testInput.previousPage': 'Back',

	'exercise.result.success': 'Well Done!',
	'exercise.result.successMsg': "It seems like that you've mastered these concepts.\n\nMove on to the next challenge!",
	'exercise.result.fail': 'Oops!',
	'exercise.result.failMsg': "Something's wrong...\n\n Let's have another go at it!",

	'exercise.result.lintError': 'There are still some errors',
	'exercise.result.lintErrorMsg': 'Please fix the pening Errors first before submission',

	'exercise.playground.enterCode': 'Enter your code here',
	'exercise.playground.outputResult': 'Output / Result',

	'exercise.executionFinished': 'Execution Completed',
	'exercise.executing': 'Execution In Progress',
	'exercise.executingInput': 'Please Enter your Input.',

	'exercise.addExercise.selectType': 'Exercise Type',
	'exercise.addExercise.selectLanguage': 'Programming Language',
	'exercise.addExercise.title': 'Exercise Title',
	'exercise.addExercise.tags': 'Custom Tags (Optional)',

	'exercise.tips.noTipsOrUnreleased': 'No tips yet',
	'exercise.tips.noAnswersOrUnreleased': 'No answers yet',

	'exercise.tips.title': 'Tips List',
	'exercise.answer.title': 'Answer', //習題答案
	'exercise.answers.title': 'Answers List',
	'exercise.materials.title': 'Download Resources',

	'exercise.testInput.msg.success':
		'Well Done! You have passed all of the test data ! Click the button below and move on to the next exercise.',
	'exercise.testInput.msg.fail': 'Some of the answers you submitted are incorrect, click to see what happened!',
	'exercise.testInput.msg.fail.nonFreecode':
		'The answer you submitted is wrong. Go back to check if there is anything wrong.',
	'exercise.testInput.msg.success.nonFreecode':
		'Awesome! Correct answer! Click the button below and move on to the next exercise.',
	'exercise.testInput.msg.success.step':
		'Well Done! You have passed all of the test data ! Click the button below and move on to the next step.',
	'exercise.testInput.msg.success.nonFreecode.step':
		'Awesome! Correct answer! Click the button below and move on to the next step.',
	'exercise.runInput.msg.fail':
		'There is an error in your code! Check out the tips and to see where you may go wrong !',
	'exercise.config.fontSize': 'Font Size',
	'exercise.config.editorSetting': 'Compiler settings',
	'exercise.config.otherSetting': 'other settings',
	'exercise.config.otherSetting.error.hint': 'Mr. Du automatic debugging',

	'exercise.systemLimit.overTimeLimit': 'System Runner Count Down', //系統執行時限倒數

	'exercise.killReason.overTimeLimit': 'Over Process Time! Please input testData or ameliorate your algorithm.', //執行時間超時！請確認輸入測資或修正演算法。
	'exercise.killReason.overSystemCPU': 'Over System CPU! Interrupt your process and try again later.', //系統忙碌中，自動終止執行程式，請稍後再試。
	'exercise.killReason.overProcessLimitTime': 'Over Limit Time! Please ameliorate your algorithm.', //執行時間超過測資限制！請改善您的演算法。
	'exercise.killReason.overCpuUsage': 'Over Process CPU! Please ameliorate your algorithm.', //執行佔用 CPU 超過系統限制！請改善您的演算法。
};
const question = {
	'student.question.title': 'My Questions',
	'student.question.hint': 'Click the questions on the left-hand side to check the reply.',
	'student.question.placeholder': 'Search Questions',
	'student.question.fin': 'Finish or not',
	'student.question.allquestions': 'Questions Overview',

	'student.createQuestionError': 'There is an Error when Submitting your Answer',
	'student.createQuestion': 'Create Questions',
	'student.enterQuestionPlaceholder': 'Please Enter the Title or Content',
	'studnet.questionContent': 'Question Content',
	'studnet.questionContentPlaceholder': 'Please Enter Question Content...',

	'student.questionTab.unanswered': 'Unanswered',
	'student.questionTab.allCourses': 'All Courses',
	'student.questionTab.myCourses': 'My Courses',

	'student.question.noQuestions': 'No Questions',
	'student.question.untagged': 'Untagged',
	'student.question.tagged': 'Tagged',
	'student.question.askQuestion': 'I have a question',
	'student.question.tagAddSuccess': 'Tag {{tag}} created',
	'student.question.alert.removeTag': 'Are sure to remove {{tag}}',
	'student.question.alert.tagRemoved': '{{tag}} removed',
	'student.question.createNewTag': 'Create new tag',
	'student.question.tagCategory': 'Tag category',
	'student.question.questionTitle': 'Question title',
	'student.question.questionContent': 'Question content',

	'student.question.question': 'Question',
	'student.question.NewQuestion': 'New question',
	'student.question.Constructing': 'Sending question',
	'student.question.noTitle': 'Please Enter the Title',
	'student.question.noContent': 'Please Enter the Content',
};

const feedback = {
	'feedback.title': 'Questions & Feedbacks',
	'feedback.subject': 'Subject',
	'feedback.subjectPlaceholder': 'Fill in the Subject',
	'feedback.description': 'Problem Description',
	'feedback.descriptionPlaceholder': 'Fill in the Problem Description',
	'feedback.screenshot': 'Screenshot of the Current Screen',
	'feedback.withScreenshot': 'Send with Screenshot',
	'feedback.msg': 'Thank you for your feedback!',
	'feedback.enterDescription': 'Please Describe your Problem',
	'feedback.screenshotError': 'Error Taking Screenshots, Please Contact the Tech Support',
	'feedback.submit': 'Submit',

	'feedback.additional.agree1':
		'Once you send this report, it means you agree that CodingBar has the right to access the relevant information required by this question under the',
	'feedback.additional.agree2': '.',
	'feedback.additional.privatePolicy': 'Privacy Policy',
	'feedback.issue.unable.to.submit.code': 'Unable to execute/submit code.',
	'feedback.issue.loading.no.response': 'Loading, no response',
	'feedback.issue.unable.to.open.courses.units.topics': "Can't open course/lesson/exercise",
	'feedback.issue.others': 'others',
	'feedback.issue.default': 'Please select the problem you may encounter.',
};

const addTA = {
	'addTA.new': 'Add New TA',
	'addTA.placeholder': "Enter TA's name or email",
	'addTA.alert': 'TA is Already in the List',
};

const courseManage = {
	'courseManage.langAlert': 'Please Choose the Programming Language',
	'courseManage.mainlangAlert': 'Please Choose the Main Language',
	'courseManage.diffAlert': "Please Choose the Course's Difficulty Level",
	'courseManage.teacherAlert': 'Please Choose the Instructor',
	'courseManage.codeAlert': 'Please enter course ID',
	'courseManage.nameAlert': 'Please enter course title',
	'courseManage.AmountAlert': 'Please Enter the number of courses',
	'courseManage.descAlert': 'Please Enter the Course Description',
	'courseManage.dateAlert': 'Please Choose the Course Date and the Class Time',
	'courseManage.edit/newSuccess': 'Course {{courseName}} added / edited successfully!',
	'courseManege.addFail': 'Fail to Create this Course! {{err}}',
	'courseManege.editFail': 'Fail to Update this Course! {{err}}',
	'courseManage.editSuc': 'Course {{courseName}} Updated Sucessfully!',
	'courseManage.newSuc': 'New Course Created Successfully',
	'courseManage.creating.course': 'Creating course...',
	'courseManage.duplicating.course': 'Duplicating course...',
	'courseManage.deleting.course': 'Deleting course...',
	'courseManage.lesson.time.setting': 'Schedule',
	'courseManage.no.weekday': 'Please select one day of the week',
	'courseManage.lesson.length.not.less.than': 'N should ≥ current course sessions',
	'courseManage.release.lesson.by.schedule.notice':
		'No lesson time specified. If you select "Release lesson by schedule", students will not be able to enter the lesson.',
};

const system = {
	'previousChpt': 'Last Chapter',
	'nextChpt': 'Next Chapter',

	'multipleLogin': 'Oops! It seems like that you are already logged in somewhere else.',
	'multipleLogin.hint1':
		'To ensure the security and integrity of your data, CodingBar only allows one session at a time, if you are logged in with a second browser or other device, the current session will be logged out.',
	'multipleLogin.hint2':
		'Please confirm that your login location and account password are secure. Please contact us if you need any assistance.',
	'apiError': 'system error! Please wait for a while.',
};

const loginPage = {
	'login.welcomeback': 'Welcome Back!',
	'login.hint': 'Please enter your account and password, or click the social sign on .',
	'login.remember': 'Remember my login info.',
	'login.forget': 'Forgot Password?',
	'login.fail': "Can't log in",

	'login.failToContact': 'Cannot log-in? Click here for help',
	'login': 'Log In',
	'login.teacherTrial': 'Instructor Trial Sign Up',
	'login.emailPlaceholder': 'Please Enter your Email',
	'login.passwordPlaceholder': 'Please Enter Your	Password',
	'login.failed': 'Failed to Log In',
	'login.wrongEmail': 'Invalid Email format. Please check again.', //信箱格式錯誤, 請再次檢查您的帳號
	'login.wrongPassword': 'Invalid password. Please check again.', //密碼錯誤, 請確認您輸入的密碼
	'login.wrongLogin': 'Invalid email or password', //帳號或密碼錯誤，請重新輸入
	'login.useGoogle': 'Please login via "Login with Google" below.', //請由下方「使用Google登入」進行登入
	'login.tooManyRequests': 'Too many failed login attempts. Try again later.', //您已多次登入失敗，目前帳號已鎖定，請稍後再試
	'login.unRegisteredEmail': 'Email account not found.', //此信箱帳號尚未註冊過
	'login.networkRequestFailed': 'Internet connection is unstable. Please try again.', //網路不穩定，登入連線過久，請再試一次
	'login.contactDev': 'Please mail to "contact@airabbi.com" for help.', //請聯絡 contact@airabbi.com 取得協助
	'login.signInWithgoogle': 'SignIn with Google',
	'login.selfParent': 'I am parent',
	'login.selfNotParent': 'I am student/instructor',
	'login.student.carouseltext1': 'Cultivate future competitiveness, the most professional choice',
	'login.student.carouseltext2': 'The courses are diverse and rich, and the content is simple and easy to understand',
	'login.student.carouseltext3':
		'Online question answer for	the quiz, you are not alone to learn , no stucks in the problem-solving',
	'signup.title': 'High School Instructor Registration',
	'signup.hint1': 'Sign up with your Email Instantly',
	'signup.hint2':
		'CodingBar now welcomes instructors from high schools across the country to try out our system. Just fill in the relevant school information and you can try it for free!',
	'email.notnull': 'Email Cannot be Empty',
	'signup.setpw': 'Set Password',
	'password.notnull': 'Password Cannot be Empty',
	'signup.confirmPw': 'Confirm Password',
	'signup.enterPWtwice': 'Please Enter your Password Again',
	'signup.pwrule': 'Enter password. At least 6 characters (alphanumeric)',
	'signup.pwrule2': 'Password must contain both alphabet and numeric characters',
	'signup.len': 'Password length must be longer than 6 characters',
	'signup.twicediff': "Passord Doesn't Match. Please Check your Input Again",
	'signup.agree': 'By clicking Continue, you agree to the ',
	'signup.agree2': 'Terms and Services',
	'signup.policy': 'policy',
	'signup.term': 'terms',
	'signup.privatePolicy': 'privacy policy',

	'signup.enterNewPW': 'Please Enter	the New Password',
	'signup.enterNewPWtwice': 'Please Enter your	New Password Again',

	'signup.setPWsets': 'Please set a Login Password',
	'signup.pwrule2': 'The password must be at least 8 character long and contain both alphabet and numeric characters.',

	'login.resetPassword.title': 'Reset Your Password', //重設密碼
	'login.resetPassword.content':
		'Please enter your CodingBar Email account, we’ll email you instructions to reset your password.', //請輸入您的 CodingBar Email 帳號，我們將發送密碼重設信給您。
	'login.resetPassword.placeholder': 'name@example.com', //請輸入您的電子信箱
	'login.resetPassword.sendEmail': 'Send me instructions', //發送密碼重設信件
	'login.resetPassword.alert.unRegisteredEmail': 'Email not found. Please try again.', //此帳號尚未註冊過，請輸入正確的email信箱
	'login.resetPassword.alert.wrongEmail': 'Please enter a valid email address format.', //格式錯誤，請輸入正確的email信箱
	'login.resetPassword.resetTitle': 'Instructions Sent', //重設信件已寄送
	'login.resetPassword.resetContent':
		'We’ve sent an email with a link to reset your password to the email address you supplied. Check your email for password reset instructions.', //重設密碼已寄送至您的信箱，請至信箱查看，並依照指引重新設定密碼。
	'login.resetPassword.gotit': 'Got it', //確認
	'login.apply.trial': 'Apply for Trial', // 申請試用
	'login.apply.trial.title': 'Apply for Educational Trial Version', // 申請教育試用版
	'login.apply.trial.note':
		'Use an .edu domain email and fill in basic information to activate a 14-day free trial experience!', // 使用 .edu 網域信箱，並填寫基本資料，即可開通 14 天免費試用體驗！
	'login.apply.trial.unit': 'Your School/Institution', // 您的學校單位
	'login.apply.trial.unit.note': 'Please enter the full name, e.g.,「台北市立第一女子高級中學」', // 請填入完整名稱，如「台北市立第一女子高級中學」
	'login.apply.trial.name': 'Your Name', // 您的姓名
	'login.apply.trial.phone': 'Your Mobile Number (numbers only)', // 您的手機號碼(純數字)
	'login.apply.trial.email': 'Your Email', // 您的信箱
	'login.apply.trial.email.note':
		'Please use an .edu email, which will serve as the teacher account for the system platform', // 請用 .edu 信箱，作為系統平台的教師帳號
	'login.apply.trial.alert.phone': 'Your phone number format is incorrect', // 您的電話格式錯誤
	'login.apply.trial.alert.email': 'Your email address is not in the .edu domain, please enter it again', // 您的信箱不是 .edu 網域，請重新填寫
	'login.apply.trial.alert.different.unit': 'Account already exists in another unit!', // 帳號已存在於其他單位!
	'login.apply.trial.alert.nothing.to.add': 'You have already applied for all the trial courses', // 您已申請過所有的試用課程
};

const loginEvent = {
	'loginExam1': 'Free APCS Practice Test', //APCS免費模擬考
	'loginExam2': 'Sign up now！', //立即註冊！

	'signup.globalExam': `We will continuously release new features in our free testing platform for those who would like to challenge APCS or ZeroJudge to have an easy-to-use and friendly compiler environment. \n
	We sincerely invite you to share your suggestions and experiences with us during the use process, so that this platform can be more perfect! \n
	*The beta version only provides Google third-party login. After successful registration, you can log in to the platform directly from the CodingBar official website\n\n`,
	'signup.incognito': '*Please do not login under the incognito mode', //*請勿使用無痕模式登入

	'loginFreeTrial1': 'Coding Tips and Tricks', //茶水間的程式妙招
	'loginFreeTrial2': 'Sign up，Free Trial now！', //立即註冊，免費體驗！

	'signup.freeTrial': `Code little, Relax more ! Sign up via Google account to try the tricks for free.

	Once signing up, you can login the platform again via CodingBar official website.\n\n`,
};

const freeTrialPage = {
	'freeTrialPage.NPBN.loadingProgress': 'Please wait...',

	'freeTrialPage.NPBN.createAccountFail.title': 'Create Account Fail!', // 開通帳號失敗！此帳號已被建立
	'freeTrialPage.NPBN.createAccountFail.content':
		'this email has been {{userInDBRole}}, Please back to official website and use another email', // 此帳號偵測已建立為 {{userInDBRole}} 角色，\n請回到網頁輸入其他信箱，始得開通帳號。
	'freeTrialPage.NPBN.createAccountFail.btn': 'return official website', // 回到官網

	'freeTrialPage.NPBN.errorCrash': 'Something went wrong, Please contact us via Line@', // 哪裡出錯了！請洽客服人員聯繫處理
	'freeTrialPage.NPBN.contactUs.btn': 'Contact Us', // 聯繫客服

	'freeTrialPage.NPBN.sendEmailTitle': 'CodingBar FreeTrial', // CodingBar 免費體驗課程開通

	'freeTrialPage.NPBN.alreadyDone.title': 'Already Used', // 此帳號已申請過試用課程
	'freeTrialPage.NPBN.alreadyDone.content':
		'This account has been applied, Please login for trial.\nIf trial time is over, Please purchase from official website.', // 此帳號偵測已申請過免費體驗課程，\n請直接點選下方連結登入平台後體驗，\n如體驗時間已結束，請於官網購買課程。

	'freeTrialPage.NPBN.done.title': 'Create Account Done!', // 開通帳號完成！
	'freeTrialPage.NPBN.done.content':
		"Your trial account has been sign-up, you can try for free in 14 day's! If you like course, Please purchase from official website.", // 您的體驗課程已開通，請點選下方連結後進行課程體驗！\n系統將於 14 天後結束體驗，如需繼續使用請於官網購買課程
	'freeTrialPage.NPBN.redirect2App': 'Go CodingBar!', // 前往 CodingBar 平台
};

const parentloginPage = {
	'login.parent': 'Parent login',
	'login.bannertext': 'professional programming education brand for teenagers',
	'login.parent.carouseltext1': 'Analyze all aspects, grasp all of the learning situation ',
	'login.parent.carouseltext2': 'Exclusive to members, get the first discount for the course',
	'login.parent.carouseltext3': 'Diversified courses, plan the learning roadmap with your children',
	'login.parent.failed':
		'The information here does not match the information provided in the registration form, please try again',

	'login.parent.welcomeText':
		"please enter register phone number of parent, login to view your child learning results, let's greet their growth", //請輸入您為孩子報名課程時所留下的聯絡電話，登入平台查看孩子學習的詳細數據，讓我們一起見證孩子的成長吧！
};

const parentHomePage = {
	'parent.leftside.title': 'Learning summary for kid',
	'parent.lessontext': 'Current course',
	'parent.courseprocess': 'Course Progress',
	'parent.learntime': 'Study Time',
	'parent.exercise': 'Exercises',
	'parent.homework': 'Homework completed',
	'parent.exerciseamount': 'Exercises',
	'parent.view': 'View',
	'parent.fullreport': "'s report",
	'parent.advertisement.title1': 'Programming education',
	'parent.advertisement.title2': 'Leading expert',
	'parent.advertisement.slogan': 'Let’s learn to	</code>',
};

const parentFullReport = {
	'parent.tablab.performance': 'Course performance',

	'parent.tablab.courseprogress': 'Course Progress',
	'parent.title.fullReport': 'Learning Status',
	'parent.title.detailData': 'Detail Analysis',

	'parent.lessonContent.innerText': 'Lesson',
	'parent.lessonContent.homework': 'Homework',
	'parent.lessonContent.homeworkComplete': 'HomeworkComplete',
	'parent.lessonContent.teacherComment': 'Notices',

	'parent.lessonContent.completeRate': ', complete', //，共完成

	'parent.lessonContent.viewAllContent': 'View Detail',
};

const csfcLoginPage = {
	'login.csfc': 'Entry detection',
	'login.csfc.bannertext': 'Computer Science Fundamentals Certification',
	'login.mail': 'Email',
	// 'login.mail.placeholder': 'Enter your email address...',
	'login.csfc.failed': 'There is no matching email in the registration info, please try again.',
	'login.csfc.welcomeText':
		'Enter the email address provided during registration and click Enter for identity verification.',
	'enter': 'Enter',
};

const staff = {
	'teacherTrialManage': 'Instructor Trial Account Management',
	'courseMaintenance': 'Course Maintenance',
	'staffMaintenance': 'Staff Management',
	'formManagement': 'Form Management',
	'couponManage': 'Coupon Management', // 折扣碼管理
	'allSignupManage': 'All Signup Information Manage', // 所有報名表管理
	'signupInfoManageV2_1': 'Signup Information Manage', // v2.1报名表管理
	'signupInfoDownloadV2_1': 'Download Signup Information', // v2.1报名表下载
	'signupInfoDownloadOld': 'Download Signup Information Old', // 旧报名表下载
	'signupInfoDownloadAncient': 'Download Signup Information Faraway', // 很久以前报名表
	'permissionManage': 'Permission Manage', // 權限管理
	'systemTutorial': 'System Tutorial',

	'staff.user': 'User',
	'staff.cumulativeusage': 'Cumulative Users',
	'staff.distribute': 'User Distribution',
	'staff.schoolStat': 'School Stats',
	'staff.dailyUser': 'Daily Users',

	'staff.system': 'System',
	'staff.nasSystem': 'NAS System Usage',
	'staff.kernelStatus': 'Kernel Status',
	'staff.systemFlow': 'System Usage',
	'staff.coursetime': 'Course Time',
	'staff.courseId': 'Course Id', //課程 ID
	'staff.systemUsage': 'System Usage',

	'staff.history': 'History',
	'staff.signupStat': 'Course Signup Stat',
	'staff.codesubmit': 'Code Submission per Hour',
	'staff.codesubmitStat': 'Code Submission Stats',
	'staff.vidwatch': 'Video Stats',

	'previousYear': 'Last Year',
	'nextYear': 'Next Year',

	'staff.totalUser': 'Total User',
	'staff.openclass': 'Opened Courses',
	'staff.exerciseNum': 'Exercise Number',

	'staff.sharedCourse': 'Shared Courses',
	'staff.settingPermissions': 'Setting Permissions', //設定權限

	'staff.num': 'No.',
	'staff.course': 'Courses Offered',
	'staff.applytime': 'Application Time',
	'staff.accountStatus': 'Account Status',

	'staff.teachernum': 'No. of Instructors',
	'staff.studentnum': 'No. of Students',
	'staff.coursenum': 'No. of Courses',

	'staff.search': 'Search Keywords',
	'staff.manipulate': 'Manipulate',
	'staff.chooseSchool': 'Please Choose your School',
	'staff.loadtime': 'Loading time',

	'staff.confirmDelete': 'Delete?',
	'staff.confirmDelete2': 'Please confrim again, you are about to delete this',

	'staff.previousweek': 'Last Week',
	'staff.nextweek': 'Next Week',

	'staff.day': 'Day',
	'staff.week': 'Week',

	'staff.previousDay': 'Yesterday',
	'staff.nextDay': 'Next Day',

	'staff.signupinfos': 'Signup Statistics',
	'staff.timeWrong': 'The time is wrong',

	'staff.assign': 'Assign',
	'staff.month': 'Month',

	'staff.previousMonth': 'Last Month',
	'staff.nextMonth': 'Next Month',

	'staff.sincePlaceholder': 'Enter Start Date',
	'staff.toPlaceholder': 'Enter End Date',
	'staff.since': 'Since',
	'staff.to': 'To',

	'staff.deleteForm': 'Are you sure that you want to delete this signup form',
	'staff.deleteForm2': 'Confirm again! This signup form will be permanently deleted',
	'staff.delFormSuc': 'Form Deleted Successfully!!',
	'staff.formManage': 'Signup Form Management',
	'staff.newForm': 'New Signup Form',
	'staff.signuplist': 'Course Signup List',
	'staff.signupForm': 'Signup Form',
	'staff.infoNum': 'data',
	'staff.download': 'Download CSV',
	'staff.update': 'Update',
	'staff.nodata': 'No Data',
	'staff.formAlert': 'Please choose the signup form!',

	'staff.newDoc': 'New Document',
	'staff.doc.proceed': 'The changes are not saved yet, \nare you sure to proceed?',
	'staff.docedit': 'Edit Document',
	'staff.incorrectDoc': 'Incorrect Document',
	'staff.createChpt': 'Create New Chapter',
	'staff.delChapter': 'Are you sure to delete this chapter',
	'staff.enterTitle': 'Please Enter the Title',
	'staff.insertImg': 'Insert Image',
	'staff.sourceCode': 'Open Source Code',
	'staff.clodeCode': 'Close Source Code',
	'staff.courseSetting': 'Course Settings',
	'staff.courseChallenge': 'After-class Challenge Questions',
	'staff.challengeA': 'Challenge A',
	'staff.challengeB': 'Challenge B',
	'staff.challengeC': 'Challenge C',
	'staff.copyFormSuc': 'Signup Form Copied Successfully!',
	'staff.copyForm': 'Copy Form',
	'staff.deleteForm': 'Delete Form',
	'staff.form.basic': 'Basic Info',
	'staff.form.course': 'Course Info',
	'staff.form.studentSetting': 'Student Info Settings',
	'staff.form.payment': 'Payment Settings',
	'staff.form.mail': 'E-mail Settings',
	'staff.form.insertGA': 'Insert GA-Code',
	'staff.form.insertFA': 'Insert FA-Code',
	'staff.form.workShop': 'Workshop',
	'staff.form.camp': 'Camps / Programs',
	'staff.form.adult': 'Adult',
	'staff.form.nomal': 'Normal',
	'staff.form.teacher': 'Instructor Selection',
	'staff.form.teacher2': 'Instructor Selection Phase 2',
	'staff.form.high': 'Senior High',
	'staff.form.eleJu': 'Elementary and Junior High',
	'staff.form.adult': 'Adult',
	'staff.activity': 'Activity Type',
	'staff.attendGroup': 'Attendant Segment',
	'staff.formId': 'Signup Form ID',
	'staff.placeholder': 'Please Enter Signup Form ID',
	'staff.link': 'Form Link',
	'staff.form.title': 'Form Title',
	'staff.form.titlePlaceholder': 'Please Enter the Form Title',
	'staff.formSlogan': 'Form Slogan',
	'staff.formSloganPlaceholder': 'Please Enter the Form Slogan',
	'staff.foreword': 'Description',
	'staff.policyTitle': 'Policy Title',
	'staff.policyTitlePlaceholder': 'Please Enter the Policy Title',
	'staff.policyLink': 'Policy Link',
	'staff.policyLinkPlaceholder': 'Please Enter the Policy Link',
	'staff.signupFin': 'Post-registration page content',
	'staff.notInList': 'The course is not in the selection, please check your formatting.',
	'staff.courseTime': 'Please enter the course session number ',
	'staff.getCourseFail': 'Failed to get the course',
	'staff.earlybirdDue': 'Earlybird Deadline',
	'staff.earlybirdDuePlaceholder': 'Please Enter the Deadline for the Earlybird Discount',
	'staff.courseSelection': 'Course Title Selection',
	'staff.courseSelectionPlaceholder': 'Please Enter the Custom Course Selection Title',
	'staff.courseOptions': 'Course Options',
	'staff.newCourseOption': 'Enter New Course Options',
	'staff.notInList2':
		'The selected time slot is not in this camp / program category!! Please check your formatting (full/half-width character / spacing)',
	'staff.courseOptionsOpen': 'Activate Course Selection',
	'staff.campCategory': 'Camp / Program Category',
	'staff.campCategoryPlaceholder': 'Enter New Camp / Program Category',
	'staff.batch': 'Time Slot / Session Number',
	'staff.batchPlaceholder': 'Insert Course & Manage the Course Time Slot / Session',
	'staff.groupornot': 'It is a Group Signup?',
	'staff.studentCareer': 'Career Selection',
	'staff.studentCareerPlaceholder': 'Enter New Career Selection Options',
	'staff.ageSelection': 'Age Selection',
	'staff.ageSelectionPlaceholder': 'Enter New Age Selection Option',
	'staff.gradeSelection': 'Grade Selection',
	'staff.gradeSelectionPlaceholder': 'Enter New Grade Selection Options',
	'staff.infoSource': 'Insert New Information Source Options',
	'staff.infoSourcePlaceholder': 'Enter New Information Source Options',
	'staff.alumnusDiscount': 'Enable Alumini Discount',
	'staff.alumnusPrice': 'Alumni Pice',
	'staff.alumnusPricePlaceholder': 'Enter Alumni Discount Price',
	'staff.combinePeriod': 'Combine Period (only when there is more than one)',
	'staff.combinePeriodPlaceholder': 'Enter the Combine Periods',
	'staff.combinePrice': 'Bundle Price',
	'staff.combinePricePlaceholder': 'Enter the Bundle Price',
	'staff.alumnusCombinePrice': 'Alumni Bundle Price',
	'staff.alumnusCombinePricePlaceholder': 'Enter the Alumni Bundle Price',
	'staff.pricePlan1': 'Price Plan 1 (Original Price)',
	'staff.pricePlan2': 'Price Plan 2 (10% off)',
	'staff.pricePlan3': 'Price Plan 3 (30% off)',
	'staff.earlybirdPrice': 'Earlybird Discount Price',
	'staff.earlybirdPricePlaceholder': 'Enter the Earlybird Discount Price',
	'staff.normalPrice': 'Normal Price',
	'staff.normalPricePlaceholder': 'Enter the Normal Price',
	'staff.pricePlanTitle': 'Please Enter Price Plan Name and Corresponding Price',
	'staff.pricePlanTitlePlaceholder': 'Enter the Price Plan Title',
	'staff.pricePlanPricePlaceholder': 'Price',
	'staff.activatePricePlan': 'Custom Price Plan',
	'staff.pricePlanHint': '*Once enabled, you don’t need to enter the above two payment settings again*',
	'staff.signupConfirmMail': 'Signup Confirmation Email',
	'staff.paymentConfirmMail': 'Payment Confirmation Email',
	'staff.addtoList': 'Add to the List',
	'staff.columnNum': 'Columns',
	'staff.removeAll': 'Remove All',
	'staff.addAll': 'Add All',
	'staff.lastUpdate': 'Last Update',
	'staff.order': 'Order',
	'staff.school': 'School',
	'staff.studentId': "Student's ID",
	'staff.studentName': 'Student Name',
	'staff.studentBD': "Student's Birthday",
	'staff.addr': 'Address',
	'staff.age': 'Age',
	'staff.parentTel': "Parents' Phone",
	'staff.studentPhone': "Student's Phone",
	'staff.note': 'Note',
	'staff.infoSource': 'Where did you hear about us?',
	'staff.groupSign': 'Group Signup',
	'staff.isEarly': 'Earlybird',
	'staff.payNum': 'Payment Number',
	'staff.paid': 'Paid',
	'staff.signupDate': 'Signup Date',
	'staff.paymentAmount': 'Payment Amount',
	'staff.transaction': 'Transaction ID',
	'staff.transactionAmount': 'Transaction Amount',
	'staff.transactionTime': 'Transaction Time',
	'staff.paymentTimes': 'Payment Counts',
	'staff.paymentTime': 'Payment Time',
	'staff.paymentMethod': 'Payment Method',
	'staff.combination': 'Bundled Payment',
	'staff.currentStudent': 'Current Student',
	'staff.createuser': "Create {{username}}'s Account",
	'staff.createAccount': 'Create Account',

	//
	'staff.liveCourseDashboard': 'Live Courses & Online Users',
	'staff.isLive': 'Online Users',
	'staff.shouldBeLive': 'Expected Users',
	'staff.liveStudentCount': 'Online Students',
	'staff.liveTeacherCount': 'Online Teachers',
	'staff.liveUnitCount': 'Online Users By Unit',
	'staff.liveCourseCount': 'Live Courses Count',
	'staff.liveUnitsCount': 'Online Units Count',
	'staff.liveCourse': 'Live Courses',

	'staff.permissionsEdit.isRegistrationAllowed': 'Registration Manage', //報名表 管理
	'staff.permissionsEdit.isUnitDomainAllowed': 'UnitDomain Manage', //單位網域 管理
	'staff.permissionsEdit.isBetaUnitAllowed': 'BetaUnit Manage', //Beta單位 管理
	'staff.permissionsEdit.isCRMAllowed': 'Crm Manage', //CRM 管理
	'staff.permissionsEdit.isDashboardAllowed': 'DashBoard', //系統儀表板
	'staff.permissionsEdit.isCouponAllowed': 'Coupon Manage', //折扣碼 管理
	'staff.permissionsEdit.isAnnounceAllowed': 'Announce Manage', //公告 管理
	'staff.permissionsEdit.isPackageCourseAllowed': 'PackageCourse Manage', //套裝課程 管理
	'staff.permissionsEdit.isTranslationAllowed': 'Translation Manage', //翻譯課程 管理
	'staff.permissionsEdit.isSyncSheetAllowed': 'SyncSheet Manage', //資料同步 管理
	'staff.permissionsEdit.isSystemDocumentAllowed': 'SystemDocument Manage', //系統文件 管理
	'staff.permissionsEdit.isStaffAsDevAllowed': 'TeacherAsDev', //Airabbi 教師權限
	'staff.permissionsEdit.isTeacherScheduleAllowed': 'TeacherSchedule', //教師行事曆
	'staff.permissionsEdit.isUnitPermissionsAllowed': 'Unit Function Permission', // 單位功能權限
	'staff.permissionsEdit.canAdjustWorkspaces': 'User Workspaces', // 使用者工作區

	//新版 Dashboard
	'staff.codeSubmitTimeRange': 'Code Sumbits In Time Range',
	'staff.timeRange': 'Time Range',
	'staff.byFiveMin': 'Five Minutes',
};

const signUpPage = {
	'signup.welcome': 'Create Account',
	'signup.email': 'Please Enter your E-mail',
	'signup.password': 'Please Enter your Password',
	'signup.confirmPassword': 'Please Confirm your Password',
	'signup.submit': 'Register',
	'signup.failed': 'Registration Failed',
	'signup.passwordNotSame': "The Passwords Don't Match",
	'signup.useThirdParty': 'Login with Google or Facebook',
	'signup.useGoogle': 'Login with Google',
	'signup.useFacebook': 'Login with Facebook',
	'signup.userAlreadyUsed': 'This E-mail has Already been Registered',

	'signup.loggingIn': 'Logging in...',
	'signup.cannotResetPW': 'Unable to reset your password',
	'signup.enterPWAgain': 'Please enter the new password again',
	'signup.PWMismatch': 'The password does not match',
	'signup.resetPWSuccess': 'Password Reset Successfully',
	'signup.PWTooWeak': 'You password is too weak :(',
	'signup.resendPWEmail': 'Unable to send email. Please resend password reset email.',
	'signup.resetPWFail': 'Password reset failed',
	'signup.findPW': 'Find your lost password',
	'signup.findParentPW': 'Find your lost password',
	'signup.retrievePW': 'Retrieve my password',
	'signup.enterEmailToResetPW':
		'Please enter your email. You will email your password to you. You can also contact us via Line@',
	'signup.enterEmail': 'Please enter your email',
	'signup.contactLine': 'Line@ ',
	'signup.checkInbox': 'Please check your inbox for your password',
	'signup.passwordEmailSent': 'We have sent your password via email. Please check your inbox.',
	'signUpPage.signup': 'Sign Up',
	'signUpPage.signup.get.verification.code': 'Get Verification Code',
	'signUpPage.signup.reget.verification.code': 'Reget Verification Code',
	'signUpPage.signup.verification.code': 'Verification Code',
	'signUpPage.signup.verification.code.placeholder': 'Please enter the verification code',
	'signUpPage.signup.verify.code': 'Verify',
	'signUpPage.signup.verify.code.error.incorrectCode': 'Incorrect verification code, please re-check',
	'signUpPage.signup.verify.code.error.noCodeReceived': "Haven't received the verification code",
	'signUpPage.signup.name.placeholder': 'Please enter name',
	'signUpPage.signup.user.name.alert': 'Username can only include a-z, 0-9, underscore(_), and period(.)',
	'signUpPage.signup.industry': 'Your Industry',
	'signUpPage.signup.industry.placeholder': 'Please select the option that best describes your industry',
	'signUpPage.signup.position': 'Your Position',
	'signUpPage.signup.position.placeholder': 'Please select the option that best describes your position',
	'signUpPage.signup.purpose': 'What problem do you want to solve with AImate?',
	'signUpPage.signup.purpose.placeholder': 'Please select the option that best describes your application scenario',
	'signUpPage.signup.Finance/Insurance': 'Finance/Insurance',
	'signUpPage.signup.Real Estate': 'Real Estate',
	'signUpPage.signup.Education/Research': 'Education/Research',
	'signUpPage.signup.Trade': 'Trade',
	'signUpPage.signup.Construction/Manufacturing': 'Construction/Manufacturing',
	'signUpPage.signup.Information Technology': 'Information Technology',
	'signUpPage.signup.Service': 'Service',
	'signUpPage.signup.Healthcare': 'Healthcare',
	'signUpPage.signup.Law Related': 'Law Related',
	'signUpPage.signup.Retail': 'Retail',
	'signUpPage.signup.Transportation/Travel': 'Transportation/Travel',
	'signUpPage.signup.Entertainment/Publishing': 'Entertainment/Publishing',
	'signUpPage.signup.Communication/PR/Advertising/Marketing': 'Communication/PR/Advertising/Marketing',
	'signUpPage.signup.Arts/Design': 'Arts/Design',
	'signUpPage.signup.Agriculture/Fishing': 'Agriculture/Fishing',
	'signUpPage.signup.Housekeeping': 'Housekeeping',
	'signUpPage.signup.Student': 'Student',
	'signUpPage.signup.Others': 'Others',
	'signUpPage.position.CEO/Company Leader': 'CEO/Company Leader',
	'signUpPage.position.Manager/Leader': 'Manager/Leader',
	'signUpPage.position.General Staff': 'General Staff',
	'signUpPage.position.Others': 'Others',
	'signUpPage.purpose.Work': 'Work',
	'signUpPage.purpose.Personal': 'Personal',
	'signUpPage.purpose.Education': 'Education',
	'signUpPage.activate.Aimate.title': 'Activate Product Service',
	'signUpPage.activate.Aimate.content':
		'This account has joined the CodingBar Workshop. Click the button below to activate the AImate product service.',
	'signUpPage.activate.Aimate.button': 'Activate AImate',
	'signUpPage.signup.success': 'Registration Successful',
	'signUpPage.downloadAimate.content':
		'You have successfully registered for the CodingBar Workshop and activated the AImate product service. Download and install the AImate app to access local computer files and save offline.',
	'signUpPage.downloadAimate.ios': 'Download for iOS Users',
	'signUpPage.downloadAimate.windows': 'Download for Windows Users',
	'signUpPage.downloadAimate.continue.web': 'Continue using the web version',
};

const notes = {
	'notes.title': 'Notes List',
	'notes.subtitle':
		'Welcome to our new note-taking feature. You can add new notes by clicking the "My Notes" button on the top and find your past notes there.',
	'notes.newNotes': 'New Notes',

	'notes.addNewNotes': 'Add Notes',
	'notes.saveNotes': 'Save Notes',
	'notes.confirmDeleteNote': 'Are you sure you want to delete this note?',
	'notes.deleteNote': 'Delete Notes',

	'notes.placeholder.addNotes': 'Enter your Notes here',
	'notes.placeholder.addNotesByButton': 'Click the button on the bottom-right to add a new note.',
	'notes.not.note.yet': 'No notes yet',
	'notes.no.note': 'Create a note by "New"',
};

const announcement = {
	'announcement.sidebar.name': 'Announcements',
	'announcement.tab.studentAncm': 'Student Announcements',
	'announcement.tab.teacherAncm': 'Instructor Announcements',
	'announcement.tab.publicAncm': 'Public Announcements',

	'announcement.addNewAncm': 'Add New Announcements',
	'announcement.viewAncm': 'View Announcements',
	'announcement.deleteThisAncm': 'Delete this Announcement',
	'announcement.deleteAncm': 'Delete Announcement',
	'announcement.writeAncm': 'Fill in the Announcement Content',
	'announcement.publishAncm': 'Publish',
	'announcement.viewAncm': 'View Announcement',
	'announcement.closeAncm': 'Close',
	'announcement.settingMsg1': 'When adding new announcements, please consider the following: ',
	'announcement.settingMsg2': '(1) The announcement recipient can be students, instructors or all',
	'announcement.settingMsg3': '(2) Announcement types can be set to Agreement, Announcement or Alert',
	'announcement.writeAncmMsg': 'Please fill in the Title and the Content.',
	'announcement.submitAncmConfirm': 'Confirm Announcement',
	'announcement.submitAncmMsg':
		'All users under the selected group will be notified. Are you sure you want to publish this announcement?',
	'announcement.deleteAncmConfirm': 'Confirm Announcement Deletion',
	'announcement.deleteAncmMsg':
		'This will delete the announcement for all users under the selected group. Are you sure you want to delete this announcement?',
	'announcement.selectAll': 'Select All',
	'announcement.dontShowAgain': "Don't Show it Again",
	'announcement.agree': 'Agree',
	'announcement.disagree': 'Disagree',
	'announcement.understood': 'Understood',
	'announcement.confirmClose': 'Confirm and Close',

	'announcement.dropdown.title.type': 'Choose the Announcement Type',
	'announcement.dropdown.title.unit': 'Choose the Recipient Group for the Announcement',
	'announcement.dropdown.title.receiver': 'Choose the Announcement Recipients',
	'announcement.dropdown.title.startDate': 'Publish Date',
	'announcement.dropdown.title.startTime': 'Start Time',
	'announcement.dropdown.title.endDate': 'End Date',
	'announcement.dropdown.title.endTime': 'End Time',

	'announcement.dropdown.label.agreement': 'Agreement',
	'announcement.dropdown.label.announcement': 'Announcement',
	'announcement.dropdown.label.alert': 'Alert',

	'announcement.dropdown.label.student': 'Students',
	'announcement.dropdown.label.teacher': 'Instructors',
	'announcement.dropdown.label.all': 'All',

	'announcement.content.title': 'Announcement Title',
	'announcement.content.content': 'Fill in the Announcement Content',
	'announcement.content.btnText': 'Button Text',
	'announcement.content.placeholder': 'This field is required',
	'announcement.content.placeholder.content': 'This field is required, if one line is too long, please wrap line.',
	'announcement.content.placeholder.btn': 'Maximal 15 words, defaults is "Confirm and Close" if blank',

	'announcement.view.type': 'Announcement Type',
	'announcement.view.unit': 'Announcement Recipient Group',
	'announcement.view.receiver': 'Announcement Recipeint',
	'announcement.view.content': 'Announcement Content',

	'announcement.view.allUnits': 'All Groups',
	'announcement.announcementList.noAnnounce': 'No Announcement', //暫無公告

	'announcement.addNewAncm.tabTitle.stepOne': 'Announcement Info', //設定公告範圍
	'announcement.addNewAncm.tabTitle.stepTwo': 'Announcement Content', //設定公告內文
};

const lintErrorHints = {
	'lint.msgLine1': 'On line',
	'lint.msgLine2': ', there is the following error:',
	'lint.btn.checkHint': 'Open Tips',
	'lint.btn.askQuestion': 'I have a question',
	'lint.btn.understood': 'Got it',
	'lint.title.stuck': 'Are you stuck?',
	'lint.title.fixed': 'I have solved it!',
	'lint.msg.errorsFixed': 'Perfect! All errors have been corrrected',
	'lint.msg.noHint': 'No hints',

	'lint.msg.error.hint': 'There is an error on line {{line}}, check it quickly',

	'lint.msg.chat.lose.miss.me': 'Hey, you lost a lot and then think of me?',
	'lint.msg.chat.no.show.up.order': "Dong Zai hasn't appeared for a long time, what would you like to have today? ",
	'lint.msg.chat.holiday.ask.dictionary':
		'Mr.Du is on holiday today, and if you have any questions, please look up the dictionary yourself',
	'lint.msg.chat.no.more.service': "Mr Du's IQ are developing and we cannot provide more services for the time being",
	'lint.msg.chat.click.on.and.off': "Click it and turn it off, turn it off and turn it on, What's up?",
	'lint.msg.chat.speechless.stay.tuned':
		'No new lines, and	no punch line until next month, so please(?) look forward to it',
	'lint.msg.chat.course.easter.egg': 'Remember to sign up for the CodingBar course to see more easter eggs',
	'lint.msg.chat.SSR.hang.in.there': "I have drawn SSR	and there is no time now, let's hold on first! ",
	'lint.msg.chat.ask.question.by.yourself':
		"Don't touch me! click left panel if you want to ask question. (／‵口′)／~╧╧",

	'lint.msg.chat.problem.solved.nice': 'Looks solved! Not bad! ',
	'lint.msg.chat.problem.solved.awesome': "I didn't expect you to be pretty good!",
	'lint.msg.chat.problem.solved.succeed': 'To make you successfully solve it, it seems that I underestimated you!',
	'lint.msg.chat.problem.solved.challenge': "It's so awesome. I'll find the mistakes later and challenge you, hahaha",
};

const studyResult = {
	'studyResult.completeRate': 'Compl.',
	'studyResult.courseScore': 'Score',
	'studyResult.completeTime': 'Time Spent',

	'studyResult.courseScore': 'Perf.',
	'studyResult.course.status': 'Learning Status',

	'studyResult.course.status.details': 'Detailed statistics',
	'studyResult.course.download.certificate': 'Download certificate',
	'studyResult.course.best.performance': 'Best performance',
	'studyResult.certificate.completed.course': 'Completed',
	'studyResult.certificate.completed.course.proof': 'This Certificate of Completion is hereby issued to certify that',
	'studyResult.certificate.course.duration': 'Course period',
	'studyResult.certificate.course.total.hours': 'Course hours',
	'studyResult.certificate.taiwan': 'Republic of China',
	'studyResult.certificate.year': '',
};

const unitDomainManage = {
	'unitDomain.sidebar.name': 'Group Domain Setting',
	'unitDomain.tab.allUnits': 'All Groups',
	'unitDomain.tab.appUnits': 'App Domain Groups',
	'unitDomain.tab.schoolUnits': 'School Domain Groups',
	'unitDomain.iconMenu.switchDomain': 'Change Group Domain',
	'unitDomain.listView.total': 'Total',
	'unitDomain.confirmModal.title': 'Change Group Domain',
	'unitDomain.confirmModal.content':
		"{{unitName}} is currently using '{{currentDomain}}'.\n Please confirm if you would like to change this group's domain to '{{switchToDomain}}'",
	'unitDomain.alert.success': 'Domain Changed Successfully',
	'unitDomain.alert.fail': 'Fail to Change Domain',
};

const AnnouncementListModal = {
	'announcementList.title': 'Announce List',
};

const betaUnitManage = {
	'betaUnit.sidebar.name': 'Beta Unit Setting',

	'betaUnit.tab.allUnits': 'All Units',
	'betaUnit.tab.betaUnits': 'Beta Units',
	'betaUnit.tab.notBetaUnits': 'Origin Units',

	'betaUnit.listView.total': 'Total',

	'betaUnit.confirmModal.title': 'Change Unit',
	'betaUnit.confirmModal.content':
		"{{unitName}} is currently using {{current}}；\n Please confirm if you would like to change this unit's to {{switchTo}}",

	'betaUnit.alert.success': 'Unit Changed Successfully',
	'betaUnit.alert.fail': 'Fail to Change Unit',

	'betaUnit.version.beta': 'Beta Version',
	'betaUnit.version.origin': 'Origin Version',
};

const zoom = {
	'zoom.list': 'Zoom List',
	'zoom.zoomCourse': 'CodingBar Online Courses',
	'zoom.selectCourse': 'Please select your course',
	'zoom.selectCodeAndPW': 'Please enter the correct course code, meeting ID and passcode',
	'zoom.retrievingInfo': 'Retrieving course information ...',
	'zoom.courseCode': 'Course Code',
	'zoom.meetingId': 'Meeting ID',
	'zoom.startCourse': 'Start Course',
	'zoom.endCourse': 'Stop Course',
};

const desktopDownload = {
	'desktopDownload.title': 'Download CodingBar software',
	'desktopDownload.description':
		'The desktop version of CodingBar can support Micro:bit, Meowbit, data science and other courses that need to read out information from hardwares. Please pay attention to your OS version and the type of class. Install the version that suits your course!',
	'desktopDownload.download': 'download now',
	'desktopDownload.instructions': 'The below is installation guidelines',
	'desktopDownload.step': 'steps',

	'desktopDownload.mac.title': 'Mac OS',
	'desktopDownload.mac.desc1':
		'Mac system only supports desktop/laptop devices, not applicable to tablet products such as iPad; please pay attention to whether you have',
	'desktopDownload.mac.desc2':
		'a port to plug in, or bring your own conversion port. \n\nIf there are installation problems, please refer to',
	'desktopDownload.mac.version11up': 'Mac OS 11 and above',
	'desktopDownload.mac.version11down': 'Mac OS 10.13 or above, version 11 is not supported',

	'desktopDownload.mac.stepTitle': 'Mac installation guide',
	'desktopDownload.mac.step1': 'First, click the left mouse button twice to open the downloaded file.',
	'desktopDownload.mac.step2': 'Please wait a while, you should see a window pop up from the prgram.',
	'desktopDownload.mac.step3':
		'According to the instructions from the pop-up window , click the left button of mouse on the CodingBar icon on the left, hold and drag it to the folder on the right, and then release it and wait for the installation.',
	'desktopDownload.mac.step4':
		'After the installation is done, open the "Applications" on the left in the Finder folder, and you should be able to see the CodingBar APP. Double-click to open the CodingBar APP.',
	'desktopDownload.mac.step5': 'Security settings are required for the first installation, please click "OK"',
	'desktopDownload.mac.step6':
		'Use the left mouse button to click on the Apple icon in the upper left corner of the desktop to open the computer menu.',
	'desktopDownload.mac.step7': 'Use the left mouse button to click on "System Preferences".',
	'desktopDownload.mac.step8':
		'In the "System Preferences" window, click "Security and Privacy" with the left mouse button.',
	'desktopDownload.mac.step9': 'In the "Security and Privacy" window, click "Force Open" with the left mouse button.',
	'desktopDownload.mac.step10':
		'In the confirmation window popping up from Force Open, please click "Open" on the left side.',
	'desktopDownload.mac.step11':
		'A connection permission confirmation will appear every time you connect to CodingBar, please click "Allow"',
	'desktopDownload.mac.step12': ',then you can successfully open the desktop version of CodingBar!',

	'desktopDownload.windows.title': 'Windows system',
	'desktopDownload.windows.desc1':
		'Windows version requires 64-bit system or above, please note that if you need to use hardware such as micro:bit, you need to have',
	'desktopDownload.windows.desc2':
		'a port to plug in, or bring your own conversion port. \n\nIf there are installation problems, please refer to',
	'desktopDownload.windows.version': 'Windows system 64-bit or above',

	'desktopDownload.windows.stepTitle': 'Windows installation guide',
	'desktopDownload.windows.step1':
		'First, click the left mouse button twice to open the downloaded file. \n\n*Windows version only supports 64-bit system',
	'desktopDownload.windows.step2':
		'Window-2 Windows system itself will open the security warning, click the left mouse button to execute.',
	'desktopDownload.windows.step3':
		'Then you can see the installation screen of CodingBar, please follow the instructions to install, click the next step with the left mouse button.',
	'desktopDownload.windows.step4':
		'Please continue to click the next step with the left mouse button. \n\n*Please do not change the default installation path, so as not to cause reading problems in program execution',
	'desktopDownload.windows.step5': 'Please continue to click "Install" with the left mouse button to start.',
	'desktopDownload.windows.step6':
		'Please wait for the installation process, do not press "Cancel" during the process, so as to avoid incomplete program files due to installation failure.',
	'desktopDownload.windows.step7':
		'When the installation is complete, click "Finish" to exit the installation program.',
	'desktopDownload.windows.step8':
		'A shortcut to the CodingBar program should appear on the desktop, or you can find the program path from the file path C://Program File (x86)/Airabbi/CodingBar/. Double-click CodingBar.exe with the left mouse button to open the program. \n\n*Do not move or delete any file in the program path without authorization, which will result in incomplete program files.',
	'desktopDownload.windows.step9': 'You can successfully open the desktop version of CodingBar!',
};

const studentDetailPage = {
	'page.tab.basicview': 'Basic ability assessment',
	'page.tab.overview': 'Performance summary',
	'page.tab.personalInfo': 'personal information',
	'page.tab.studentScore': 'Scores View',

	'page.blockHeader.assessment': 'Capability Assessment',
	'page.blockHeader.keyPerformance': 'Key performance',

	'page.btn.checkDetails': 'View details',
};

const teacherCourseLessonPage = {
	'lessonPage.card.title': 'Basic Course Information',
	'lessonPage.course.content': 'Course Information',
	'lessonPage.course.view.content': 'View content',
	'lessonPage.course.viewScoreOnly': 'View score', //檢視成績
	'lessonPage.confirm.edit.course.title': 'Changing course content and setting ?',
	'lessonPage.confirm.edit.course.content':
		'Please check before confirming to save changes. Once confirmed, the changes is unrecoverable.',
	'lessonPage.card.button.editCourseInfo': 'Edit',

	'lessonPage.confirm.editCancel.course.title': 'Cancel Edited', //取消變更課程內容
	'lessonPage.confirm.editCancel.course.content':
		'make sure you want to cancel edited, the previous change will disappear\nPlease confirm to cancel ', //請確認是否要取消編輯課程內容，方才的編輯更動將會全部遺失\n請再三確認是否要取消編輯。

	'lessonPage.card.fieldTitle.codeType': 'Main language',
	'lessonPage.card.fieldTitle.lessonLength': 'Number of courses',
	'lessonPage.card.fieldTitle.lessonLength.number': 'Course Sessions',

	'lessonPage.card.fieldTitle.tags': 'Custom label',
	'lessonPage.card.fieldTitle.content': 'Course Description',
	'lessonPage.card.fieldTitle.teacher': 'Lecturer',
	'lessonPage.card.fieldTitle.ta': 'Teaching Assistant',

	'lessonPage.card.fieldTitle.lessonTitle': 'Lesson Title',
	'lessonPage.card.fieldTitle.lessonContent': 'Lesson Description',
	'lessonPage.card.lessonContent.placeholder': 'Please provide more context for this lesson',
	'lessonPage.title': 'Lesson {{i}}',
	'lessonPage.entryoflesson': 'Enter teaching mode',
	'course.short.questionGroup': 'Exercise Set',

	'lessonPage.exercise.batch.setting': 'Batch Setting',
	'lessonPage.exercise.test.cases': 'TC',
	'lessonPage.exercise.video': 'Video',
	'lessonPage.abbrev.test.cases': 'TC',
	'lessonPage.abbrev.exercise': 'CONT',
	'lessonPage.abbrev.answer': 'ANS',

	'course.short.handout': 'Handout',
	'course.short.exercise': 'Exercise',
	'course.short.homework': 'Homework',
	'course.short.tests': 'Test',
	'course.short.exam': 'Test paper',
	'course.short.video': 'Video',
	'course.short.coursePackage': 'Package course',
	'course.short.AddLessonFromResource': 'Import from Library',
	'course.time.notSet': 'Unspecified time',
	'course.page.title.stuManage': 'Classroom student management',
	'course.page.removePackageStudentAlert': 'Not allowed to remove student of CoursePackage', // 套裝課程不允許移除學生
	'lessonPage.no.content.yet': 'No content yet',

	'course.time.finishedLessons': 'Completed {{currentLevel}} lessons (all {{totalLevel}} lessons)',
};

const coursePackageManage = {
	'CPManage.sidebar.name': 'Course management',

	'CPManage.info.beginTime': 'Take effect',
	'CPManage.info.endTime': 'Deadline',
	'CPManage.info.packageType': 'Category',
	'CPManage.info.useNum': 'Student number in the course',
	'CPManage.info.maintainer': 'Responsible for business',
	'CPManage.info.unitContact': 'Contact person in the unit',
	'CPManage.info.unitContactEmail': 'Contact person Email',
	'CPManage.info.contactPhone': 'Contact phone number',
	'CPManage.info.contactAddress': 'Classroom address',

	'CPManage.info.noContactInfo': 'No contact information in the unit temporarily',
	'CPManage.info.noUseLimit': 'Unlimited',

	'CPManage.iconMenu.editSetting': 'Edit settings',
	'CPManage.iconMenu.deletePackage': 'Delete course',

	'CPManage.modal.addPackage': 'Add external courses',
	'CPManage.modal.editPackage': 'Edit external course',
	'CPManage.modal.steps.basicSettings': 'Basic Info',
	'CPManage.modal.steps.importCourse': 'Import course',
	'CPManage.modal.steps.importCourse.confirm': 'Confirm',
	'CPManage.modal.chose.course.resource': 'Select Packaged Course',
	'CPManage.modal.steps.timeSettings': 'time setting',

	'CPManage.modal.stepOne.title.courseType': 'Category',
	'CPManage.modal.stepOne.title.maintainer': 'CodingBar contact person',
	'CPManage.modal.stepOne.title.useLimit': 'Maximum number of students in the course',
	'CPManage.modal.stepOne.title.assignedUnit': 'Designated unit',
	'CPManage.modal.stepOne.title.assignedContact': 'Contact person in the unit',
	'CPManage.modal.stepOne.title.beginTime': 'Course Effective Date',
	'CPManage.modal.stepOne.title.endTime': 'Course deadline',
	'CPManage.modal.stepOne.title.enableActiveUserNotification': 'Output active number to CodingBar contact person',
	'CPManage.modal.stepOne.title.courseTitle': 'Please enter course title',
	'CPManage.modal.stepOne.title.courseTitlePreview': 'Preview of Course Title',
	'CPManage.modal.stepOne.title.desc': 'Enter course description or notes',
	'CPManage.modal.stepOne.title.codeType': 'Main language',
	'CPManage.modal.stepOne.title.assignedTeacher': 'Teacher',
	'CPManage.modal.stepOne.title.assignedTA': 'Teaching assistant',
	'CPManage.modal.stepOne.title.unit': 'Institute',
	'CPManage.modal.stepOne.title.chatGPT': 'Activate ChatGPT Feature',
	'CPManage.modal.stepOne.title.toggleAllowedEdit': 'Enabled Edit Themself',

	'CPManage.modal.stepThree.title.courseBeginTime': 'Start date',
	'CPManage.modal.stepThree.title.courseLessonLength': 'Estimated number of courses',
	'CPManage.modal.stepThree.title.packageCourseEndDate': 'Expire Date',
	'CPManage.modal.stepThree.title.autoReleaseCourse': 'Automatically open courses',
	'CPManage.release.lesson.by.schedule': 'Release lesson by schedule',

	'CPManage.modal.stepOne.placeholder.maintainer': 'Enter Business maintainer', //輸入業務名稱
	'CPManage.modal.stepOne.placeholder.useLimit': 'Enter 0 for unlimited',
	'CPManage.modal.stepOne.placeholder.assignedContact':
		'If there is no automatic filling in data, you can choose to fill in manually',

	'CPManage.modal.errorText.endBeforeStart': 'End time not valid',
	'CPManage.modal.errorText.notStartBetweenPackageTime':
		'The date is not within the time limit for the expatriate course',

	'CPManage.modal.alert.lessonDatesError':
		'The course date is: {{dates}}, some dates are not within the course time limit',
	'CPManage.modal.alert.lessonDates': 'The course dates are: {{dates}}',

	'CPManage.modal.confirm.titleTeacher': 'Create a course by using the packaged course ?',
	'CPManage.modal.confirm.bodyTeacher':
		'Please note that once the packaged course is imported and used, you won’t be able to delete it.\nAnd students in the course will be counted.\n\nConfirm to countinue.',
	'CPManage.modal.confirm.titleDev': 'Establish external package course?',
	'CPManage.modal.confirm.bodyDev':
		'Please confirm whether the package course is dispatched to the relevant unit or teacher.',
	'CPManage.modal.confirmDelete.titleDev': 'Delete the external package course?',
	'CPManage.modal.confirmDelete.bodyDev':
		'Please confirm whether to delete the external course, related units and teachers will be affected.',

	'CPManage.modal.AssignCourseStepTwo.note':
		"Heads up! Date range and shcedule setting could be optional. However, system won't be able to remind you the lesson time since it's an empty value.",
	'CPManage.modal.alert.import.lessonDatesError':
		'Current course schedule:{{dates}} \nThis packaged course expires on {{dueDate}}, please adjust your schedule setting.',
	'CPManage.modal.alert.import.lessonDatesError.title': 'Please adjust course schedule',
};

const comments = {
	'comments.btn.addComment': 'Add comment',
	'comments.btn.addAnotherComment': '+Add another group of comments',
	'comments.btn.removeThisComment': '-Delete this comment',

	'comments.addComment.tab.basicAbility': 'Basic ability assessment',
	'comments.addComment.tab.addComment': 'Comment writing',

	'comments.addComment.info1': 'The level of programming concepts and implementation',
	'comments.addComment.info2': 'Sum to 100',
	'comments.addComment.info3':
		', You can directly enter one of the fields, and the system will automatically fill in the other fields for you',

	'comments.field.conceptScore': 'The level of programming concepts',
	'comments.field.implementScore': 'The level of program implementation',
	'comments.field.difficultyScore': 'The level of acceptance',
	'comments.field.starScore': 'Star rating',
	'comments.field.commentShort': 'Built-in comments selection',
	'comments.field.comment': 'Comment content',

	'comments.placeholder.noComment': 'The teacher has not yet written any other comments',
};

const studentInfo = {
	'studentInfo.field.unit': 'Unit',
	'studentInfo.field.name': 'Student name',
	'studentInfo.field.gender': 'Gender',
	'studentInfo.field.phone': 'Contact phone number',
	'studentInfo.field.identityCardNumber': 'Student ID number',
	'studentInfo.field.birthday': 'Date of birth',
	'studentInfo.field.age': 'Age',
	'studentInfo.field.email': 'e-mail',
	'studentInfo.field.password': 'Password',
	'studentInfo.field.educationStage': 'Grade',
	'studentInfo.field.contactName': "Contact person's name",
	'studentInfo.field.contactPhone': "Contact person's phone number",
	'studentInfo.field.useOldMember': 'Member identity',
	'studentInfo.field.contactEmail': 'e-mail',
	'studentInfo.field.contactAddress': 'Contact Address',

	'studentInfo.placeholder.inputPhone': 'Please enter the phone number',
	'studentInfo.placeholder.inputIdentityCardNumber': 'Please enter student ID number',
	'studentInfo.placeholder.resetPassword': 'Send a password reset letter',
	'studentInfo.placeholder.inputSchool': 'Please enter school name',
	'studentInfo.placeholder.inputStudentClass': 'Please enter the class',
	'studentInfo.placeholder.inputSeatNo': 'Please enter the seat number (Arabic numerals)',
	'studentInfo.placeholder.inputContactName': 'Please enter the name of the contact person',
	'studentInfo.placeholder.inputContactPhone': 'Please enter the name of the contact person',
	'studentInfo.placeholder.inputContactAddress': 'Please enter a contact address',

	'studentInfo.grade.first': 'First grade',
	'studentInfo.grade.second': 'Second grade',
	'studentInfo.grade.third': 'Third grade',
	'studentInfo.grade.fourth': 'Fourth grade',
	'studentInfo.grade.fifth': 'Fifth grade',
	'studentInfo.grade.sixth': 'Sixth grade',
	'studentInfo.grade.seventh': 'Seventh grade',
	'studentInfo.grade.eighth': 'Eighth grade',
	'studentInfo.grade.ninth': 'Ninth grade',
	'studentInfo.grade.tenth': 'First grade of high school',
	'studentInfo.grade.eleventh': 'Second grade of high school',
	'studentInfo.grade.twelfth': 'Third grade of high school',

	'studentInfo.grade.elementary': 'Elementary school',
	'studentInfo.grade.junior': 'High School',
	'studentInfo.grade.senior': 'Senior high school',
	'studentInfo.grade.uni': 'University',

	'studentInfo.field.member': 'Member',

	'studentInfo.confirm.updateStudentInfo.title': 'Change student information?',
	'studentInfo.confirm.updateStudentInfo.body':
		'You are about to change the student information, please confirm that the information is correct.',
};

const studentPerformance = {
	'studentPer.rowTitle.lessonVideos': 'Unit video',
	'studentPer.rowTitle.examResults': 'Test scores',
	'studentPer.rowTitle.videoViewed': 'Video watching',
	'studentPer.rowTitle.homeworkFinished': 'Homework completed',
	'studentPer.rowTitle.questionsAsked': 'Proactively ask questions',
	'studentPer.rowTitle.checkedTips': 'View tips',
	'studentPer.rowTitle.submitTimes': 'Submission with errors',
	'studentPer.rowTitle.finishedTime': 'Complete time',
	'studentPer.rowTitle.runtimeEfficiency': 'Execution efficiency',
	'studentPer.rowTitle.classRanking': 'Ranking',
	'studentPer.rowTitle.taggedPerf': 'Mark as excellent',
	'studentPer.rowTitle.exerciseTime': 'Practice time',
	'studentPer.rowTitle.exercisePerf': 'Exam performance',

	'studentPer.rowTitle.lessonInfo': 'Course news',

	'studentPer.blockSubtitle.perfomance': 'Performance evaluation',
	'studentPer.blockSubtitle.classAverage': 'Class average',
	'studentPer.blockSubtitle.finishedLength': 'Amount completed',
	'studentPer.blockSubtitle.askedLength': 'Number of submissions',
	'studentPer.blockSubtitle.checkedTimes': 'View frequency',
	'studentPer.blockSubtitle.totalLength': 'Total quantity',
	'studentPer.blockSubtitle.personalTime': 'Personal tim',
	'studentPer.blockSubtitle.personalPerf': 'Personal performance',
	'studentPer.blockSubtitle.bestRecord': 'Best record',
	'studentPer.blockSubtitle.worstRecord': 'Lowest record',
	'studentPer.blockSubtitle.overallPerformance': 'Comprehensive performance',
	'studentPer.blockSubtitle.difficultySelect': 'Acceptance',
	'studentPer.blockSubtitle.testAverage': 'Test average',
	'studentPer.blockSubtitle.exerciseCompletion': 'Exercise completion rate',

	'studentPer.barSubtitle.classAverage': 'Others Mean Time',
	'studentPer.barSubtitle.videos': 'Videos you have watched',
	'studentPer.barSubtitle.exercises': 'Questions you have practiced',
	'studentPer.barSubtitle.exerciseTime': 'The time you have practiced',
	'studentPer.barSubtitle.homework': 'Homework you have completed',
	'studentPer.barSubtitle.questions': 'The number of times you have asked',
	'studentPer.barSubtitle.tips': 'The number of times you have viewed',
	'studentPer.barSubtitle.submit': 'The number of times you have submitted',
	'studentPer.barSubtitle.time': 'The time you have spend',
	'studentPer.barSubtitle.rank': 'Your ranking performance',
	'studentPer.barSubtitle.perf': 'Your outstanding times',

	'studenPer.headerText.results': 'Score statistics',
	'studenPer.headerText.selflearn': 'Self-learning',
	'studenPer.headerText.careful': 'Carefulness',
	'studenPer.headerText.preformance': 'Efficiency performance',
	'studenPer.headerText.other': 'Other performance',

	'studentPer.work': 'Work',
	'studentPer.overall.evaluation': 'Overall Evaluation',
	'studentPer.works': 'Catalog of Featured Works',
	'studentPer.feedback': 'Student Feedback',
	'studentPer.attachments': 'Work Attachments / Activity Album',
	'studentPer.learningAttitude': 'Attitude',
	'studentPer.programmingAbility': 'Ability',
	'studentPer.attendance.rate': 'Attendance Rate',
	'studentPer.homework.completion.rate': 'Homework Completion Rate',
	'studentPer.participation': 'Participation',
	'studentPer.programming.syntax.familiarity': 'Programming Syntax Familiarity',
	'studentPer.programming.logic.understanding': 'Programming Logic Understanding',
	'studentPer.problem.solving.skills': 'Problem Solving Skills',
	'studentPer.organizational.skills': 'Organizational Skills',
	'studentPer.creativity': 'Creativity',
	'studentPer.first.work': 'Work 1',
	'studentPer.look.forward.to.amazing.work': 'Featured works coming soon',
	'studentPer.attachments.link': 'Work links',
	'studentPer.albums.link': 'Course Activity Albums',
};

const parentPage = {
	'parentPage.expandAll': 'Expand all status',
	'parentPage.closeAll': 'Hide details',

	'parentPage.selectCourse.placeholder': 'Select a course',
};

const exerciseEditor = {
	'exerciseEditor.sidebar.exerciseList': 'Exercises list',
	'exerciseEditor.sidebar.questions': 'Question discussion',
	'exerciseEditor.sidebar.exerciseContent': 'CONT',
	'exerciseEditor.sidebar.exerciseQuestions': 'Exercise',
	'exerciseEditor.sidebar.exerciseAnswers': 'ANS',
	'exerciseEditor.sidebar.exerciseResources': 'Resource',
	'exerciseEditor.sidebar.noInfoTitle': 'Oops !!',
	'exerciseEditor.sidebar.noInfo': 'There is no content to show \n it shoud be that the teacher does not open it ! ',

	'exerciseEditor.sidebar.answer.record': 'Answer Record',
	'exerciseEditor.answer.record.choice': 'Select Item',
	'exerciseEditor.answer.record.correct.answer': 'Correct Answer',
	'exerciseEditor.answer.record.rearrangement.result': 'Sorted result',
	'exerciseEditor.answer.record.your.answer': 'Your answer',
	'exerciseEditor.answer.record.your.didnt.answer': "You didn't answer",

	'exerciseEditor.handIn.globalTest.announce.title': 'Calculate Score', //計算成績
	'exerciseEditor.handIn.globalTest.announce.content': 'Please waiting for your report.', //請稍候，我們正在為您計算成績
};

const questionGroupModal = {
	'questionGroupModal.addQuestionGroup': 'Create exercise set',
	'questionGroupModal.editQuestionGroup': 'Edit exercise set',
	'questionGroupModal.stepOne.basicContent': 'Basic Information',
	'questionGroupModal.stepTwo.importExercise': 'Import exercises',
	'questionGroupModal.deleteQuestionGroup': 'Delete exercise set',

	'questionGroupModal.stepOne.info1':
		'1. The new version of the exercise set function, you can group several exercises into a set according to the purpose or exercise type.',
	'questionGroupModal.stepOne.info2':
		'2. Please import exercises from Library, you can directly drag and drop exercises to adjust the order.',

	'questionGroupModal.stepOne.title': 'Exercise set name',
	'questionGroupModal.stepOne.titleholder': 'Please enter the exercise set name',
	'questionGroupModal.stepOne.hashtag': 'Custom Label',

	'questionGroupModal.stepTwo.selectFrom': 'Import From Library',
	'questionGroupModal.stepTwo.amountcount': '{{amountcount}} items selected',
	'questionGroupModal.resources.exercise': 'Exercises',
	'questionGroupModal.resources.video': 'Video',
};

const disconnetPage = {
	'disconnetPage.title': 'Sorry... the system is temporarily unable to respond',
	'disconnetPage.text': 'There may be a problem with the server or other connections',
};
const teacherCourseTestPage = {
	'testPage.header.title': 'Test Events',
	'testPage.header.text':
		'Test events are our newest feature that allows you to schedule tests beforehand.\nSelect any test from your Library to try it out!',

	'testPage.endMockAlert': 'Mock End!',
	'testPage.endTestAlert': 'Test End!',

	'testPage.tabs.isScheduled': 'Scheduled',
	'testPage.tabs.isEnded': 'Tested',
	'testPage.tabs.isMockTest': 'Mock Tests',

	'testPage.list.isTestOngoing': 'Open',
	'testPage.list.testDate': 'Open Date',
	'testPage.list.testDuration': 'Duration',
	'testPage.list.editTest': 'Edit Test Event',
	'testPage.list.deleteTest': 'Delete This Test Event',
	'testPage.list.viewTest.setting': 'View Test Settings',
	'testPage.list.endTest': 'End Test',
	'testPage.list.viewTestScore': 'View Test Scores',
	'testPage.list.openTestResource': 'Edit Test Resources',
	'testPage.list.deleteTestData': 'Delete This Test',
	'testPage.list.deleteMockTest': 'Delete This Mock Test',
	'testPage.list.noTests': 'No tests have been created',

	'testPage.modal.deleteTest.title': 'Delete test?',
	'testPage.modal.deleteTest.content':
		'Once this test event is deleted, it will no longer be accessible in your course. \n\nPlease confirm this action, as it is not revertable.',
	'testPage.modal.deleteTest.btn': 'Delete Test Event',

	'testPage.modal.endTest.title': 'End test?',
	'testPage.modal.endTest.content':
		'Test in progress!\n\n Students will not be able to continue answering if this test is ended. However, test scores will be calculated automatically. \n\nPlease confirm this action, as it is not revertable.',
	'testPage.modal.endTest.btn': 'End Test',

	'testPage.fab.addTest': 'Create Test Event',

	'testPage.alert.leaveTest':
		'Your answers will not be saved if you leave this page. Are you sure you want to continue?',
	'testPage.alert.csfc.leaveTest':
		'Leaving is regarded as handing in the test, please hand in the test before leaving.', // 離開試卷視同交卷，離開前請先提交試卷。
};

const testInfo = {
	'testInfo.starttest': 'Start answering',
	'testInfo.description': 'Test description',
	'testInfo.duration': 'Test time',

	'testInfo.viewtest': 'View the content of the answer',
	'testInfo.view5recent': 'View the last 5 scores',

	'testInfo.recent5score': 'The last five scores of the challenges',

	'testInfo.mockDone': 'Challenged mock tests',
	'testInfo.allchallengetime': 'Total challenge time',
};

const studentTestPage = {
	'studentTestPage.timeUp': 'Times up! Please stop answering.',
	'studentTestPage.autoHandIn': 'Times up! Auto handing in your exam.',
	'studentTestPage.countdowninfo': 'Time remaining ',
	'studentTestPage.markquestion': 'Mark',
};

const loadingProcessBar = {
	'loadingProcessBar.createExam.title': 'The test  is being set up...',
	'loadingProcessBar.createExamDone.title': 'Your designated test has started!',
	'loadingProcessBar.resetExam.title': 'Resetting the test...',
	'loadingProcessBar.endExam.title': 'Terminating the test...',
	'loadingProcessBar.endExamDone.title': 'The test you designated has been terminated!',

	'loadingProcessBar.endExamDone.ok': 'View grades',
	'loadingProcessBar.endExamDone.cancel': 'View grades later',
};

const globalExamPage = {
	'globalExamAd.line1': 'Bonus plus for the practice test , send you the complete video of the solutions',
	'globalExamAd.line2': 'Hurry up and click on the menu to prepare for the APCS test',
	'globalExamAd.cancelBtn': 'I have taken the test',

	'globalExamPage.EntranceAnnounce.title': 'Free APCS practice test, welcome to experience the challenge!',
	'globalExamPage.EntranceAnnounce.content': `Every pratice test is divided into ""twenty multiple choice questions"" and ""two program implementation questions"", We separate the concepts that are frequently tested, so that you can check whether the various concepts are fully prepared. Do not close the browser or disconnect the Internet or leave the test interface during the test, otherwise your answers will not be saved. The  program implementation question environment provides answers in Python, C, and C++ languages, please switch the programming language through the setting left. When answering the questions, please make sure to press the ""submit"" button below and confirm whether to submit the answer through the question menu on the left. When all the questions are completed, you can click on the submit button to end the exam, or the system will automatically submit the answers you have done when the time is over; You will receive a test result and simple analysis within 5 minutes as a reference and attachment of the problem-solving reference video. We are developing a new version of the software function. Any suggestions on the content and platform functions are welcome to leave a message on our fan club and IG. Help us make this platform better, let's Coding together !
	\n CodingBar Development Team`,
};

const translationPage = {
	'translation.selectCourse': 'Select a course from the lefthand panel to start translating.',
	'translation.toBeTranslated': 'Courses To Be Translated',
	'translation.submitTranslation': 'Submit Translation',

	'translation.formTitle.courseInfo': 'Course Info',
	'translation.formTitle.lessonInfo': 'Lesson Info',
	'translation.formTitle.materialInfo': 'Material Info',
	'translation.formTitle.exerciseInfo': 'Exercise Info',

	'translation.lesson.title': 'Lesson Title',

	'translation.exercise.option': 'Exercise Option',
	'translation.exercise.answer': 'Exercise Answer',
	'translation.exercise.text': 'Exercise Text',

	'translation.exercise.cloze.row': 'Cloze Exercise Row',
	'translation.exercise.dragdrop.row': 'Drag Drop Exercise Row',
	'translation.exercise.cloze.text': 'Cloze Exercise Text',
	'translation.exercise.cloze.slot': 'Cloze Exercise Slot Answer',
	'translation.exercise.cloze.option': 'Cloze Exercise Option',

	'translation.exercise.slot.content': 'Slot Content',

	'translation.test.noTest': 'No Tests Yet',

	'translation.course.assignCourse': 'Assign to translator',
	'translation.course.assignCourse.success': 'Assign course success',
	'translation.course.assignCourse.fail': 'Assign course fail',

	'translation.translationManage.sidebar': 'Translation Course Manage',
	'translation.translationManage.noCourse': 'No Courses Yet',
	'translation.translationManage.assignedTeacher': 'Assigned Teacher',
	'translation.translationManage.assignedTimestamp': 'Assigned Date',
	'translation.translationManage.submittedTimestamp': 'Submitted Date',

	'translation.translationManage.tab.ongoing': 'Translation In Progress',
	'translation.translationManage.tab.ended': 'Translation Finished',

	'translation.translationManage.edit.title': 'Edit Translation Course Assignment',

	'translation.translationManage.delete.title': 'Delete Translation Course Assignment',
	'translation.translationManage.delete.content':
		'Please confirm whether to delete this course assignment. The translating course in progress will be deleted and assigned translator(s) will no longer be able to access this course.',
};

const shareCourseModal = {
	'shareCourse.modal.confirm.titleDev': 'Share Course?', //分享課程
	'shareCourse.modal.confirm.bodyDev': 'Please check to share course.', //請確認是否將課程分享至相關單位或老師。
};

const dataSyncPage = {
	'dataSync.page.sidebar': 'Data Sync Manage',
	'dataSync.page.headerInfo':
		'To sync data from the following Google sheets for registration forms / CRM / parent page, please click the buttons below.',

	'dataSync.btn.syncData': 'Sync Data',
};

const crm = {
	'crm.header': 'CRM Student Information Management',

	'crm.searchResult': 'Search Results: A total of {{resultLength}} "{{searchTerm}}" matching customer information',

	'crm.action.sendEmail': 'Send letters',
	'crm.action.on.sendEmail': 'Sending letters',
	'crm.action.sendEmail.success': 'Successfully sent letters!',
	'crm.action.sendEmail.fail': 'Failed to send letters！',
	'crm.action.sendSms': 'Send SMS',

	'crm.email.title.addEmail': 'Add letter',
	'crm.email.tab.template': 'Template selection',

	'crm.email.modal.chooseTemplate': 'Select template',
	'crm.email.modal.chooseRegisForm': 'Course name and registration code',
	'crm.email.modal.chooseRegisClass': 'Class name',
	'crm.email.modal.bring': 'Carry Items',
	'crm.email.modal.bring.device': 'Prepare Equipment',
	'crm.email.modal.preparation': 'Pre-class Preparation',
	'crm.email.modal.image': 'Login Image Example',
	'crm.email.modal.note': 'Notes',
	'crm.email.modal.videoNote': 'Video Software Usage Teaching',
	'crm.email.modal.videoDoc': 'Upload video software teaching files',
	'crm.email.modal.teachBasic': 'Basic Teaching',
	'crm.email.modal.stepsDoc': 'Step-by-step Instructional File',
	'crm.email.modal.emailHeading': 'Headline',
	'crm.email.placeholder.emailHeading': 'Please enter headline',
	'crm.email.modal.emailContent': 'Letter Content',
	'crm.email.placeholder.emailContent': 'Please enter letter content',
	'crm.email.modal.isSendCampCertificate': 'Camp Certificate Download',
	'crm.email.modal.download.CampCertificate': 'Download Certificate of Participation',
	'crm.email.advanced.settings': 'Advanced Settings',
	'crm.email.modal.isSendCampAlbums': 'Event Album',
	'crm.email.modal.campAlbumLink': 'Camp Activity Album Link',
	'crm.email.modal.checkout.campAlbumLink': 'View event photos',
	'crm.email.placeholder.campAlbumLink': 'Enter album link',
	'crm.email.modal.courseRecommend': 'Select recommended courses (up to two)',
	'crm.email.modal.signature': 'Signature settings',
	'crm.email.modal.continuation': 'Select Continuation Courses (up to one)',
	'crm.email.modal.testName': 'Exam name',
	'crm.email.modal.certificateLink': 'Exam certificate link',
	'crm.email.placeholder.certificateLink': 'Please paste URL',
	'crm.email.modal.emailTitle': 'Letter Subject',
	'crm.email.placeholder.emailTitle': 'Enter letter subject',
	'crm.email.modal.testEmail': 'Test mail',
	'crm.email.modal.course.description': 'Course Introduction',
	'crm.email.modal.course.features': 'Course Features',
	'crm.email.modal.picture.description': 'Image caption',
	'crm.email.modal.registration.link': 'Registration form link',
	'crm.email.placeholder.testEmail': 'Enter test mail',

	'crm.email.type.onSiteCourseNotice': 'On-site Class Notice',
	'crm.email.type.offSiteCourseNotice': 'Remote Class Notice',
	'crm.email.type.accountNotice': 'Account Opening Notice',
	'crm.email.type.campAchievement': 'Camp Achievement',
	'crm.email.type.courseAchievement': 'Study Report Evaluation',
	'crm.email.type.courseContinuation': 'Course Continuation Notice',
	'crm.email.type.certificate': 'Test Certificate',
	'crm.email.type.notice': 'Information announcement',

	'crm.email.type.onSiteCourseNotice.subLabel':
		'If you need to upload classroom location pictures, the recommended width of each picture is 480px.',
	'crm.email.type.offSiteCourseNotice.subLabel':
		'Please confirm whether the link to the instructions of the video software is correct, or the content needs to be updated.',
	'crm.email.type.accountNotice.subLabel':
		'Please confirm whether the content of the step instructional file is correct',
	'crm.email.type.campAchievement.subLabel':
		'Please confirm the link to the camp album and the relevant certificates are available before sending the letter.',
	'crm.email.type.courseAchievement.subLabel':
		"1. Before sending the study report, please confirm the course and teacher comment review process have been completed.\n2. If the student is not in the course, or there is no study report, the student's letter will be automatically skipped and not sent",
	'crm.email.type.courseContinuation.subLabel':
		'After you select a continuation course, you can view the course content.',
	'crm.email.type.certificate.subLabel': 'After you select a continuation course, you can view the course content.',
	'crm.email.type.notice.subLabel': 'This template is a blank announcement, please use it with caution.',

	'crm.email.regis.lowestPrice': 'Lowest price for a single person',
	'crm.email.otherSetting.subLabel':
		'After the setting is completed, please click the "Send Test Letter" button below to confirm that the letter is correct before sending it to the customer.',
	'crm.email.send.testMail': 'Send Test Letter',

	'crm.email.type.onSiteCourseNotice.subject': '【CodingBar】 On-site Class Notification Letter',
	'crm.email.type.offSiteCourseNotice.subject': '【CodingBar】 Remote Class Notification Letter',
	'crm.email.type.accountNotice.subject': '【CodingBar】 Account opening notice',
	'crm.email.type.campAchievement.subject': '【CodingBar】 Camp Achievement',
	'crm.email.type.courseAchievement.subject': '【CodingBar】 Study Reports/Evaluations',
	'crm.email.type.courseContinuation.subject': '【CodingBar】 Course Continuation Notice',
	'crm.email.type.certificate.subject': '【CodingBar】 Test Pass Certificate',
	'crm.email.type.notice.subject': '【CodingBar】 Information announcement Notice',

	'crm.email.title.coming.course': 'Classes are coming soon, please note.',
	'crm.email.title.active.account': 'Account has been opened, please note.',
	'crm.email.title.study.hard': 'Do your best and study hard.',
	'crm.email.title.keep.learning': 'continuous learning',
	'crm.email.title.fruitful.result': 'Hard work, fruitful results!',

	'crm.email.alert.course.not.created': 'This course has not been created yet!',
};

const manualEmailTemplate = {
	'manualEmailTemplate.linkButton.login': 'Login to CodingBar',
	'manualEmailTemplate.linkButton.step': 'View step-by-step instruction',
	'manualEmailTemplate.linkButton.register.now': 'Register Nnw',

	'manualEmailTemplate.sectionRecommendedCourse.note.1': 'Recommended courses',
	'manualEmailTemplate.sectionRecommendedCourse.note.2': 'This course has been fruitful for',
	'manualEmailTemplate.sectionRecommendedCourse.note.3': '!',
	'manualEmailTemplate.sectionRecommendedCourse.note.4':
		'We will recommend suitable follow-up courses for you, please refer to the link below, or get more information through Line@!',

	'manualEmailTemplate.recommendedCourseBlock.linkButton.text': 'View more',

	'manualEmailTemplate.mailAddress.class': 'Register class',
	'manualEmailTemplate.mailAddress.classRoom': 'Classroom Location',

	'manualEmailTemplate.studentInfo.studentEmail': 'Student account',

	'manualEmailTemplate.thankRegisterContent.note.1': 'Thank you for registering.',
	'manualEmailTemplate.thankRegisterContent.note.2':
		'You can directly log in to the CodingBar learning platform with the registered email, start the program learning journey! Before getting started, please read the following:',

	'manualEmailTemplate.lineContact.note.1':
		'Thank you for  your participation and support. If you have any questions, please call (02) 7717-9386 during business hours, or contact us via the official Line@!',
	'manualEmailTemplate.lineContact.note.2': 'Join Line@ Now',
	'manualEmailTemplate.lineContact.note.3': 'This is a system mail, please do not reply to this email.',
	'manualEmailTemplate.lineContact.default.join':
		'You can click the button below to download the teaching files of the CodingBar platform system. If you have other usage questions, you can also join Line@ for more information!',

	'manualEmailTemplate.toBring.default.computer.1':
		'If you bring your own laptop, please note that the Windows system requires 64 bits, and the Mac system requires os 10.13 or above.',
	'manualEmailTemplate.toBring.default.computer.2':
		'Please note that Windows requires a 64-bit system, and Mac requires os 10.13 or above.',
	'manualEmailTemplate.toBring.default.pad':
		'If you use a tablet, prepare a matching keyboard for easy code entry during course.',
	'manualEmailTemplate.toBring.default.other': 'Pens, cups, personal cutlery and personal medicine.',

	'manualEmailTemplate.toPrepare.default.chrome': 'Access the CodingBar platform using the Chrome browser.',
	'manualEmailTemplate.toPrepare.default.system.account': 'The account will be opened on',
	'manualEmailTemplate.toPrepare.default.system.test.login.1':
		'. Please try to log in to the platform before starting the course. If you have any questions, please join Line@ and ask customer service.',
	'manualEmailTemplate.toPrepare.default.system.test.login.2':
		'. Please confirm that you can successfully log in to our platform before the course. If you have any questions, please ask at line@',
	'manualEmailTemplate.toPrepare.default.zoom.1': 'Zoom is required for class, please enter Zoom ',
	'manualEmailTemplate.toPrepare.default.zoom.2': '5 minutes in advance ',
	'manualEmailTemplate.toPrepare.default.zoom.3': 'and test the system to avoid affecting class rights.',
	'manualEmailTemplate.toPrepare.default.zoom.4': 'Please test the microphone and video camera in advance.',
	'manualEmailTemplate.toPrepare.default.zoom.5': 'In order to identify the students in the class, ',
	'manualEmailTemplate.toPrepare.default.zoom.6': 'please use your real name to enter the meeting room.',

	'manualEmailTemplate.toPrepare.default.video.1': 'There are two ways you can join the meeting',
	'manualEmailTemplate.toPrepare.default.video.2':
		'Your computer has installed the Zoom software, you can directly open the software and join the meeting.',
	'manualEmailTemplate.toPrepare.default.video.3':
		'Whether you have Zoom software installed or not, you can access it through a browser connection.',

	'manualEmailTemplate.toNote.default.mask': 'Students are required to wear masks throughout the camp.',
	'manualEmailTemplate.toNote.default.fever':
		'Please cooperate with the staff to measure body temperature and disinfect. If you have contact  history or symptoms of over 37.5 degrees and fever, you will be assisted in refunding.',

	'manualEmailTemplate.emailContent.default.dear': 'Dear',
	'manualEmailTemplate.emailContent.default.course.end.1':
		'This course camp has ended. Thank you for your support to CodingBar. We will provide you with the',
	'manualEmailTemplate.emailContent.default.course.end.2':
		'certificate in the link below, and also provide photos of this course camp for your reference.',
	'manualEmailTemplate.emailContent.default.course.end.3':
		'If there is anything you want to know, please let us know at any time!',

	'manualEmailTemplate.emailContent.default.course.about.to.end.1':
		"At the end of the course, the instructor's suggestions for ",
	'manualEmailTemplate.emailContent.default.course.about.to.end.2':
		'course performance and learning are as follows, for your reference; At the same time, we also provide a link to the study report below. If you have any further questions, please contact us!',

	'manualEmailTemplate.emailContent.default.congratulation.1': 'Congratulations on completing the ',
	'manualEmailTemplate.emailContent.default.congratulation.2':
		'course!Along the learning journey, we offer a six-stage progressive course to help students achieve certifications such as Microsoft MTA and aAPCS in stages. The next course ',
	'manualEmailTemplate.emailContent.default.congratulation..3':
		'will lead the children to continue their studies. Welcome to register.',

	'manualEmailTemplate.emailContent.default.signature': 'Student Care Department',

	'manualEmailTemplate.emailContent.default.notice.1':
		'Thank you for your hard work along the way and taking the exam at the end of the course to review your learning!',
	'manualEmailTemplate.emailContent.default.notice.2':
		'The test results below are for your reference. At the same time, we provide test certificates for encouragement. Welcome to download through the link below.',
};

//TODO
const parentEmailTemplate = {
	'parentEmailTemplate.signUp': 'sign up', //報名
	'parentEmailTemplate.waiting': 'alternate', //候補

	'parentEmailTemplate.x1.title': 'Welcome', //報名成功，歡迎加入
	'parentEmailTemplate.x2.title': '', //繳費完成，敬請期待
	'parentEmailTemplate.x3.title': '', //請認證您的信箱
	'parentEmailTemplate.x4.title': 'Reset Password', //重設密碼
	'parentEmailTemplate.x5.title': '', //名額有限，請勿錯過
	'parentEmailTemplate.x6.title': '', //候補完成，靜候佳音

	'parentEmailTemplate.x1.innerTextBottom1': '', //我們提供多種付款方式，課程名額將視繳費順序進行保留，請點選下方繳費連結，並於
	'parentEmailTemplate.x1.innerTextBottom2': '', //三天之內
	'parentEmailTemplate.x1.innerTextBottom3': '', //繳費完畢，以確保您的上課權益。
	'parentEmailTemplate.x1.innerTextBottom4': '', //完成繳費後，您將收到繳費確認信，請將 service@codingbar.ai 設為常用聯絡人，才不會漏掉重要通知唷！
	'parentEmailTemplate.x1.innerTextBottom5': '', // 請將 service@codingbar.ai 設為常用聯絡人，才不會漏掉重要通知唷！

	'parentEmailTemplate.x1_simple.innerTextBottom1':
		"Besides, Please click link below to fill in children's information, including name, account email, phone and other fields, to go through the formalities quickly.", //此外，別忘記點擊下方連結，填寫地址與學生資料，包含姓名、郵件信箱、學生聯絡電話等相關資訊，以利行政作業程序！

	'parentEmailTemplate.x1.linkButton': '', //立即前往繳費
	'parentEmailTemplate.x1_simple.linkButton': 'Fill In Info', //填寫學生資訊
	'parentEmailTemplate.x3.linkButton': '', //認證信箱
	'parentEmailTemplate.x4.linkButton': '', //重設密碼
	'parentEmailTemplate.x5.linkButton': '', //立即前往繳費

	'parentEmailTemplate.x2.innerTextTop1': '', //您已完成
	'parentEmailTemplate.x2.innerTextTop2': '', //的報名繳費程序，我們已收到課程款項，將於
	'parentEmailTemplate.x2.innerTextTop3': '', //開課前一週
	'parentEmailTemplate.x2.innerTextTop4': '', //寄發開課通知；請務必將 service@codingbar.ai 設為常用聯絡人，避免遺漏後續重要通知。

	'parentEmailTemplate.x3.innerTextBottom1': '', //歡迎您註冊 CodingBar 與我們一起開始程式學習的道路！
	'parentEmailTemplate.x3.innerTextBottom2': '', //請點擊下方連結認證並開通帳號，就能開始享受 CodingBar 程式學習平台的強大功能唷。

	'parentEmailTemplate.x4.innerTextTop1': '', //請點擊下方連結重新設定您的 CodingBar 帳號密碼，若您沒有申請過重新設定密碼，請忽略此封信。

	'parentEmailTemplate.x5.innerTextTop1': '', //感謝您報名
	'parentEmailTemplate.x5.innerTextTop2': '', //，由於名額有限，請參考下列繳費資訊，並敬請於
	'parentEmailTemplate.x5.innerTextTop3': '', //前完成繳費，屆時如未繳費CodingBar將會釋出您的名額給其他候補學員，不另行通知，敬請見諒。

	'parentEmailTemplate.x6.innerTextTop1': '', //您已完成
	'parentEmailTemplate.x6.innerTextTop2': '', //的候補程序，並排入候補名單中，若候補成功，將有專人與您聯絡；請務必將 service@codingbar.ai 設為常用聯絡人，避免遺漏後續重要通知。
};

const registrationDownloadPage = {
	'registration.download.tablabel.open': 'Course(Open)', //課程(開放)
	'registration.download.tablabel.closed': 'Course(Closed)', //課程(關閉)
	'registration.download.tablabel.tutorOpen': 'Tutor(Open)', //家教(開放)
	'registration.download.tablabel.tutorClosed': 'Tutor(Closed)', //家教(關閉)

	'registration.download.sortlabel.name': 'Name', //名稱
	'registration.download.sortlabel.formId': 'FormId', //報名表代碼
	'registration.download.sortlabel.formAttributeSelected': 'FormAttribute', //報名表種類

	'registration.download.headerlabel.name': 'Name', //班級名稱
	'registration.download.headerlabel.courseCid': 'CourseCid', //班級代碼
	'registration.download.headerlabel.placeSelected': 'Place', //班級地區
	'registration.download.headerlabel.classSelected': 'Class', //班級序號
	'registration.download.headerlabel.courseStartAndEndDate': 'Date', //班級日期
	'registration.download.headerlabel.classTime': 'Time', //班級時間

	'registration.download.iconMenu.view': 'view data', //檢視資料
	'registration.download.searchBox.placeholder': 'Search Title/FormId', //輸入關鍵字或報名表代碼

	'registration.download.dumpBtn.text': 'Download', //下載資料
	'registration.download.settingModal.confirmBtn.text': 'Download', //確認下載

	'registration.download.settingModal.title': 'Download Settings', //下載設定
	'registration.download.settingModal.note1': 'Please import file to google sheet of .csv file.', //下載檔案為 CSV 格式，請匯入 google sheet 使用
	'registration.download.settingModal.note2': 'combine download will seperate to multiple files.', //多份報名表合併下載時，會以不同檔案形式呈現
	'registration.download.settingModal.note3': 'you can set data dur已繳費ation time of paid/registered.', //可設定報名/繳費時間區間
	'registration.download.settingModal.basicSettings': 'Basic Settings', //基本設定
	'registration.download.settingModal.basicSettings.paid': 'Paid', //已繳費
	'registration.download.settingModal.basicSettings.oldmember': 'Old Member', //是否為舊生
	'registration.download.settingModal.payMethodSelected': 'PayMethod', //繳費種類
	'registration.download.settingModal.payMethodSelected.credit': 'Credit Card', //信用卡
	'registration.download.settingModal.payMethodSelected.atm': 'ATM',
	'registration.download.settingModal.payMethodSelected.cvs': 'Convenience Store', // 超商代碼
	'registration.download.settingModal.timeSelected.title': 'TimeAttribute', //時間區間屬性
	'registration.download.settingModal.timeSelected.paid': 'Paid', //繳費
	'registration.download.settingModal.timeSelected.registed': 'Registed', //報名

	'registration.download.settingModal.begin.date': 'Please select Begin Date', //請選擇起始日期
	'registration.download.settingModal.end.date': 'Please select Cutoff Date', //請選擇截止日期
	'registration.download.settingModal.begin.time': 'Please select Begin Time', //請選擇起始時間
	'registration.download.settingModal.end.time': 'Please select Cutoff Time', //請選擇截止時間
	'registration.download.settingModal.alert.end.date': 'Should be later then Begin Date', //截止日期不能早於起始日期
	'registration.download.settingModal.alert.end.time': 'Should be later then Begin Time', //截止時間不能早於起始時間

	'registration.download.settingModal.clearBeginTime': 'Clear BeginTime', //清空起始欄位
	'registration.download.settingModal.clearCutoffTime': 'Clear CutoffTime', //清空截止欄位

	'registration.download.switch.courseCid': 'CourseCid', //依班級代碼
	'registration.download.switch.formId': 'FormId', //依報名表代碼
};

const crmManage = {
	'crmManage.main.sidebar.crm-student': 'CRM Student',
	'crmManage.main.sidebar.crm': 'CRM',

	'crmAccountManage.sidebar.name': 'CRM Account Manage', //CRM 帳號管理
	'crmAccountManage.iconMenu.switch': 'change to CRM', // 切換 CRM 權限
	'crmAccountManage.confirm.title': 'Change to CRM？', // 切換成 CRM 權限？
	'crmAccountManage.confirm.body': 'Please confirm to change\n{{uid}}\n into CRM manage account.', // 請確認是否將此帳號\n{{uid}}\n切換為具有 CRM 權限(此操作無法還原)
};

const internalMailManage = {
	'internalMailManage.sidebar.label': 'InternalMail Manage', //員工寄信管理

	'internalMailManage.page.headerInfo': 'Please add mail to define internal receivers', //內部人員相關寄信需求，請自行填寫加入
	'internalMailManage.editmail.btn': 'Edit Receivers', //編輯收信人
	'internalMailManage.createmail.btn': 'Create Receivers', //新增收信名單

	'internalMailManage.key.title': 'Key', //請輸入 key
	'internalMailManage.title.title': 'Title', //請輸入標題
	'internalMailManage.emailContent.title': 'Email List', //請輸入 email 列表
};

const toolsPage = {
	'toolsPage.tools': 'Tools',
	'toolsPage.tools.CSFC.download.title': 'CSFC certificate generation and download',
	'toolsPage.tools.CSFC.download.subtitle':
		'Please refer to the CSV format to create a list, import the list into the system and generate certificates.',
	'toolsPage.tools.CSFC': 'Generate Certificate',
	'toolsPage.CertificateGenerator.title': 'Certificate setting and download',
	'toolsPage.CertificateGenerator.note.1': 'For the student list, please refer to the',
	'toolsPage.CertificateGenerator.note.2': 'template file, ',
	'toolsPage.CertificateGenerator.note.3':
		'format the list as a csv file and import. If the csv template is garbled, please change the encoding to unicode, or use google spreadsheet to open it.',
	'toolsPage.CertificateGenerator.form.exam': 'Exam',
	'toolsPage.CertificateGenerator.form.exam.date': 'Exam Date',
	'toolsPage.CertificateGenerator.form.exam.level': 'Level',
	'toolsPage.CertificateGenerator.form.exam.certificate.date': 'Issue date',
	'toolsPage.CertificateGenerator.form.exam.names': 'Certificate Issuance List',
	'toolsPage.CertificateGenerator.form.exam.names.placeholder': 'Please enter student names, one line per student',
	'toolsPage.CertificateGenerator.import.CSV': 'import scv file',
	'toolsPage.CertificateGenerator.download.Certificate': 'Download certificate',
	'toolsPage.tools.certificate.download.title': 'Generate and Download Graduate Certificates', // 結業證書製作及下載

	'toolsPage.CertificateGenerator.download.completed': 'Download completed',
	'toolsPage.CertificateGenerator.download.completed.note':
		'The certificate has been downloaded, please go to the download folder.',
	'toolsPage.CertificateGenerator.generating': 'Generating...',

	'toolsPage.tools.createAccount.school.title': 'School Authorize Service', //校園授權方案開通
	'toolsPage.tools.createAccount.school.subtitle': 'Please fill in data from table and create account/course', //請根據申請試用表單，填寫對應資料並生成課程。
	'toolsPage.tools.createAccount': 'Create Account', //開通帳號
	'toolsPage.tools.createAccount.school.completed': 'Create Account', //已開通帳號
	'toolsPage.tools.createAccount.school.completed.body':
		'Accounts and Courses are already created, NoticeMail has been sent.', //帳號與課程已建立，系統已寄送通知信件。

	'toolsPage.tools.createAccount.info.email': 'Regist Email', //申請者 Email
	'toolsPage.tools.createAccount.info.unit': 'Regist Unit', //申請者單位
	'toolsPage.tools.createAccount.info.displayName': 'Regist Name', //申請者姓名
	'toolsPage.tools.createAccount.info.phone': 'Regist Phone', //申請者聯絡電話
	'toolsPage.tools.createAccount.info.trialPlan': 'Regist Plan', //申請方案
	'toolsPage.schoolAuthorizeTrial.info.cloneCourse': 'Source Course Id', //複製課程來源

	'toolsPage.schoolAuthorizeTrial.note.1':
		'Please fill in the information of register, diff plan will provide diff service.', //請填寫申請者相關資訊並選取方案，將根據不同方案提供對應課程與服務內容。
	'toolsPage.schoolAuthorizeTrial.info.planFree': 'Free', //免費
	'toolsPage.schoolAuthorizeTrial.info.plan1': 'Trial-1', //方案1
	'toolsPage.schoolAuthorizeTrial.info.plan2': 'Trial-2', //方案2

	'toolsPage.schoolAuthorizeTrial.generating': 'Generating... Please wait.', //正在創建帳號課程中，請耐心等候
	'toolsPage.schoolAuthorizeTrial.account.exists': 'Account Exists', //此帳號已存在
	'toolsPage.schoolAuthorizeTrial.inValidCourseId': 'Invalid Course Id', //請輸入正確的課程 Id

	'toolsPage.schoolAuthorizeTrial.sendEmailTitle': 'CodingBar has already active your School Authorization Trial', //校園授權方案免費體驗開通
	'toolsPage.schoolAuthorizeTrial.apply.success': 'CodingBar School Authorization Trial Application Successful', // CodingBar 校園授權方案試用申請成功
	'toolsPage.schoolAuthorizeTrial.apply.success.content': 'Please check your mailbox and activate your account.', // 請至信箱收信，並且啟用帳號。
	'toolsPage.schoolAuthorizeTrial.generating.fail': 'CodingBar School Authorization Trial Activation Failed', // CodingBar 校園授權方案試用開通失敗
	'toolsPage.schoolAuthorizeTrial.note.2':
		'This button will update the "Copy Course Source" field data to the database, and apply it to the "School Plan Trial" automatic and manual process.', // 此按鈕將更新"複製課程來源"欄位資料至資料庫，套用在"校園方案試用"自動與手動流程。
	'toolsPage.schoolAuthorizeTrial.note.3':
		'Only courseId "006f40ef-abe0-4c4a-b07d-c51e6d14f597-1662540906827" will create dummy student answer records', // 只有 courseId 為"006f40ef-abe0-4c4a-b07d-c51e6d14f597-1662540906827" 會創建 dummy 學生作答記錄
	'toolsPage.schoolAuthorizeTrial.update.autoJoinCourses.db': 'Update Copy Course Source DB', // 更新複製課程來源 DB
	'toolsPage.schoolAuthorizeTrial.update.autoJoinCourses.db.note':
		'Are you sure you want to update the current "Copy Course Source" field data to the database? Please double-check!!!', // 是否要將目前"複製課程來源"欄位資料更新至資料庫，請再三確認!!!
	'toolsPage.schoolAuthorizeTrial.unit.alert': 'Unit cannot be empty or airabbi!', // 單位不可為空或 airabbi!
	'schoolAuthorizeTrial.alreadyApplied.content':
		'This account has already applied for a free trial course. Please click the link below to log in to the platform and experience the course. If the trial period has ended, please contact customer service.', // 此帳號偵測已申請過免費體驗課程，請直接點選下方連結登入平台後體驗，如體驗時間已結束，請聯絡客服。
	'schoolAuthorizeTrial.done.content':
		'Your trial course has been activated. Please click the link below to experience the course! The system will end the trial in 14 days. If you need the full version, please contact customer service.', // 您的體驗課程已開通，請點選下方連結後進行課程體驗！系統將於 14 天後結束體驗，如需正式版請聯絡客服。
	'schoolAuthorizeTrial.userExistInOtherUnit.content':
		'Account already exists in another unit! Please contact customer service.', // 帳號已存在於其他單位! 請洽客服人員。

	'toolsPage.schoolCAGenerator.title': 'SchoolCA generation and download', //校園 Usb 憑證製作
	'toolsPage.schoolCAGenerator.subtitle': 'Please fill in data from table and create CA', //請根據欄位填寫對應資料並生成憑證資料。
	'toolsPage.schoolCAGenerator.btn': 'Generate CA', //製作憑證
	'toolsPage.schoolCAGenerator.completed': 'Download completed', //憑證完成
	'toolsPage.schoolCAGenerator.completed.note':
		'The certificate has been downloaded, please go to the download folder and put in Usb.', //憑證已下載完成，請由下載資料夾將檔案放至 Usb 內。

	'toolsPage.schoolCAGenerator.note.1': 'Please fill in data from table.', //請根據欄位填寫對應資料並生成憑證資料。

	'toolsPage.schoolCAGenerator.info.email': 'Regist Email', //憑證教師 Email
	'toolsPage.schoolCAGenerator.info.unit': 'Regist Unit', //憑證教師單位
	'toolsPage.schoolCAGenerator.info.displayName': 'Regist DisplayName', //憑證教師姓名
	'toolsPage.schoolCAGenerator.info.phone': 'Regist Phone', //憑證教師聯絡電話

	'toolsPage.schoolCAGenerator.generating': 'Generating...', //正在創建憑證中，請耐心等候

	'toolsPage.financialReportTransfer.title': 'GreenWorld Monthly Financial Statistics Report', //綠界每月財務入帳金額統計
	'toolsPage.financialReportTransfer.subtitle':
		'Please upload report from greenworld and make sure dateRange of courseManage googleSheet', //請將綠界報表上傳並確認開課班級管理表資料後進行轉換。
	'toolsPage.financialReportTransfer.btn': 'Upload Report', //上傳報表
	'toolsPage.financialReportTransfer.completed': 'Transfer complete!', //轉換完成
	'toolsPage.financialReportTransfer.completed.note': 'The report transfer complete, please view from download folder.', //報表已轉換完成，請開啟下載資料夾觀看檔案。

	'toolsPage.financialReportTransfer.generating': 'Generating...', //正在轉換報表中，請耐心等候

	'toolsPage.financialReportTransfer.note.1': 'Please upload greenworld report(.xlsx) and make sure', //請上傳綠界報表(.xlsx) 並確認
	'toolsPage.financialReportTransfer.note.2': 'CourseManage googleSheet',
	'toolsPage.financialReportTransfer.note.3': 'has value of BE, BF', // 資料中，使用到的 課程row 於欄位 BE, BF 中有內容。

	'toolsPage.financialReportTransfer.dataIsLoading': 'Loading...', //載入外部資料【開課班級管理表】中...
	'toolsPage.financialReportTransfer.dataLoadingDone': 'Data Done!', //資料載入完成！

	'toolsPage.dataError': 'wrong information',
	'toolsPage.LaborReportGenerator.title': 'Labor Report Generator',
	'toolsPage.LaborReportGenerator.generating': 'Pdf is being generated and sent, please be patient',
	'toolsPage.LaborReportGenerator.subtitle':
		'Please use the CSV file exported by A1-006 every month. After importing, the corresponding labor report will be automatically generated and sent to the corresponding mail.',
	'toolsPage.LaborReportGenerator.subtitle2': '1. The image file format is jpg, please compress after conversion ',
	'toolsPage.LaborReportGenerator.subtitle3': '2. Please follow the format YYYY-MM at the end of the upload file name',
	'toolsPage.LaborReportGenerator.done': 'Operation completed',

	'toolsPage.teacherSchedule': 'TeacherSchedule', //教師行事曆
	'toolsPage.autoJoinCourses.title': 'Automatic Course Activation List Settings', // 自動開通課程名單設定
	'toolsPage.autoJoinCourses.subtitle':
		"For online self-study courses and CBA courses, once the payment is complete, courses are automatically activated in the student's account based on the corresponding course ID to the 10-code .", // 線上自學課程、CBA 課程，繳費完後，根據 10 碼對應到的課程 ID ，自動開通課程到學生的帳號。
	'toolsPage.autoJoinCourses.note':
		'Please paste the class management table column B 10-code and corresponding Course ID into the following textfield, separated by a comma', // 請將開課班級管理表 B欄位 10碼與對應的Course ID 貼入下方 textfield ，並以半形逗號分開
	'toolsPage.autoJoinCourses.title.classId': 'Class Code (Column B)', // 班級代碼 (B欄位)
	'toolsPage.AutoJoinCoursesManage.delete.title': 'Delete Course List', // 刪除課程名單
	'toolsPage.AutoJoinCoursesManage.delete.content': 'Confirm whether to delete the course list: {{key}}', // 確認是否刪除課程名單: {{key}}
	'toolsPage.AutoJoinCoursesManage.delete.content2': 'Warning! The course validity period will also be deleted!', // 注意！課程時限設定也將一併刪除!
	'toolsPage.CourseValidityPeriod.title': 'Course Validity Period Settings', // 課程時限設定
	'toolsPage.CourseValidityPeriod.subtitle':
		'Validity period settings for the 10-digit column B in the course management table', // 開課班級管理表 B 欄位 10 碼的時限方案設定
	'toolsPage.CourseValidityPeriod.note': 'Set the validity period after selecting the 10 digits in column B, in day', // 選擇 B 欄位 10 碼後設定時限，以天為單位
	'toolsPage.CourseValidityPeriod.delete.title': 'Delete Course Validity Period', // 刪除課程時限
	'toolsPage.CourseValidityPeriod.delete.content': 'Confirm deletion of course validity period: {{key}}', // 確認是否刪除課程時限: {{key}}
	'toolsPage.CourseValidityPeriod.confirmation.title': 'Course Plan Expiration Notice', // 課程方案到期通知
	'toolsPage.CourseValidityPeriod.confirmation.content':
		'The course {{title}} you purchased expired on {{expiryTime}}, \nif you wish to extend the course duration, please contact customer service. Thank you!', // 您購買的課程 {{title}} 已於 {{expiryTime}} 到期，\n如欲延長課程時間，請聯絡客服，謝謝！
};

const batchTeacherAccountModal = {
	'batchTeacherAccountModal.content':
		'Please import .xlsx file, and make sure the sheet name is "sheet1", first column of name and second column of email', //請匯入 .xlsx 檔案，第一欄為姓名，第二欄為 email，並注意工作表名稱應設定為 sheet1
	'batchTeacherAccountModal.generating': 'Generating...', // 正在批次創建帳號中，請耐心等候
};

const globalTestModal = {
	'globalTest.score.sendEmailComplete':
		'Complete! Now you can receive your report from your email.\nIf not, make sure checked spam.', //完成！您現在可以點擊確認按鍵離開了，\n提醒您，成績結果請稍候於您的信箱內檢視，如未收到信件，請先確認您的垃圾信箱。
	'globalTest.score.progressBar.label1': 'Calculating Score...', //正在計算考試成績...
	'globalTest.score.progressBar.label2': 'Generating Report...', //正在產生成績報表...
	'globalTest.score.progressBar.label3': 'Sending Email...', //正在寄送結果信件...
	'globalTest.score.progressBar.complete': 'Done!', //完成！
};

const csfcStudentAccountModal = {
	'csfcStudentAccountModal.title': 'import CSFC Student', //匯入 CSFC 報名資訊
	'csfcStudentAccountModal.warning.repeatEmail': 'already registed, no use to import.', //已有報名紀錄資料，將不再重複報名。
	'csfcStudentAccountModal.warning.testId.not.exist':
		'The csv file you imported is incorrect, please double check the file content.',
	'csfcStudentAccountModal.warning.wrong.testId': 'Test does not exist, please confirm the file content again.',
	'csfcStudentAccountModal.warning.wrong.unit':
		'The unit is incorrect or not filled in, please confirm the file content again.',
	'csfcStudentAccountModal.import.downloadNotes-2': '(1) Please download the corresponding registration CSV file',
	'csfcStudentAccountModal.import.downloadNotes-3':
		", After import, student's registration will be generated automatically, If the file cannot be displayed correctly, please", //，依格式將名單製作為 csv 檔並匯入，系統產生學生註冊資料；若開啟 csv 範本為亂碼，請將
	'csfcStudentAccountModal.import.downloadNotes-8': '; if student has already registed, no allowed to import.', // ；若該學生已報名同一場次時，將無法勾選匯入。

	'csfcStudentAccountModal.sendEmailTitle': '【CSFC Tests】', // CSFC 檢測系統報名

	'csfcVerifyPage.success.title': 'Success!', // CSFC 檢測報名成功！
	'csfcVerifyPage.success.content':
		'You have already registed CSFC success, Please entries system(https://exam.csfcasia.org) when test time. If any question, you can contact us via line below.', // 您好，您已確認報名 CSFC 檢測成功，請記得於檢測時間進入檢測系統(https://exam.csfcasia.org)，如有問題請點選下方 line 與我們聯繫，謝謝。

	'csfcVerifyPage.fail.title': 'Error!', // CSFC 檢測報名資料錯誤
	'csfcVerifyPage.fail.content':
		'Your link has some trouble, Please check this is correct link. If any question, you can contact us via line below.', // 您好，您的 CSFC 檢測連結似乎有誤，請再次確認您是否點選正確連結，或是透過下方 line 與我們聯繫，謝謝。

	'csfcStudentAccountModal.toast.importDone': 'Create account all done!', // 帳號全數創建完成！
	'csfcStudentAccountModal.test.evet.time': 'Test Time', // 場次時間
	'csfcStudentAccountModal.test.evet.title': 'Test Item', // 場次項目
	'csfcStudentAccountModal.test.evet.csv.file': 'CSV Registration File', // CSV 報名檔
	'csfcStudentAccountModal.tab1.label': 'Download the Registration CSV File', // 下載報名場次 CSV 檔案
	'csfcStudentAccountModal.tab2.label': 'Import CSV', // 匯入 CSV
	'csfcStudentAccountModal.manual.handIn': 'hand in manually', // 手動交卷
	'csfcStudentAccountModal.manual.handIn.success': 'handed in successfully', // 手動交卷成功
	'csfcStudentAccountModal.manual.handIn.confirmation.title': 'Manually handing in CSFC test results', // 手動交卷 CSFC 檢測結果
	'csfcStudentAccountModal.manual.handIn.confirmation.content':
		'The student has not yet handed in the test, manual handing in will record the test result and send it to the student.', // 該學生尚未交卷，手動交卷將紀錄檢測結果並寄送給學生。
	'CSFCExamPage.info': 'Candidate Information', // 應考人資訊
	'CSFCExamPage.exam.note': 'Computer Science Fundamentals Certification Exam Notes：', // CSFC 資訊科學能力檢定考試注意事項：
	'CSFCExamPage.exam.note.guidebook': 'CSFC Candidate Guidebook', // CSFC 考生指引手冊
	'CSFCExamPage.exam.note.content0': 'Please read carefully before the test', // 請在測驗開始前詳細閱讀
	'CSFCExamPage.exam.note.content1':
		'When answering the questions, please make sure to press the ""submit"" button below and confirm whether to submit the answer through the question menu on the left.', // 作答時，請務必按下方的「提交」按鈕，並透過左側題目選單確認是否提交答案
	'CSFCExamPage.exam.note.content2':
		'When all the questions are completed, you can click on the submit button to end the exam, or the system will automatically submit the answers you have done when the time is over; You will receive a test result and simple analysis within 5 minutes.', // 完成測驗，按下「交卷」按鈕結束測驗，或測驗時間到將由系統自動交卷；你將於五分鐘內收到一份測驗結果。
	'CSFCExamPage.exam.note.content3':
		'If there is a network interruption or other situations, you can return to the platform to continue answering before the end of the test.', // 測驗進行中，若有網路中斷或其他狀況發生，可於測驗結束前回到平台繼續作答。
	'CSFCExamPage.exam.events': 'Test', // 考試場次
	'CSFCExamPage.exam.result.concept': 'concept test', // 觀念題考試
	'CSFCExamPage.exam.result.implement': 'implementation test', // 實作題考試
	'CSFCExamPage.exam.test.time': 'Test Time', // 考試時間
	'CSFCExamPage.exam.button.status.doing': 'In Progress', // 進行中
	'CSFCExamPage.exam.button.status.start': 'Start Test', // 開始測驗
	'CSFCExamPage.exam.button.status.done': 'Handed in', // 已交卷
	'CSFCExamPage.exam.button.status.invalid': 'Not verified yet', // 尚未驗證
	'CSFCExamPage.exam.button.status.countdown1': 'Start in', // 後開始
	'CSFCExamPage.exam.button.status.countdown2': '', // 後開始
	'CSFCExamPage.exam.failed': 'failed', // 未通過
	'CsfcStudentManage.modal.title': 'View CSFC Student Information', // 檢視 CSFC 學生資料
	'CsfcStudentManage.modal.title.validation': 'Registration Validation', // 報名驗證
	'CsfcStudentManage.modal.title.row.score': 'Score (Concept, Implementation)', // 分數(觀念,實作)
	'CsfcStudentManage.student.valid': 'Validated', // 已驗證
	'CsfcStudentManage.no.students': 'No CSFC Students Imported Yet', // 尚未匯入 CSFC 學生
};

const chatGPT = {
	'UnitPermissionsManagePage.title': 'Unit Function Permission Management', // 單位功能權限管理
	'chatGPTManage.modal.preview.selectedCourse': 'View Selected Course', // 檢視已選課程
	'chatGPTManage.modal.preview.selectedCourse.undo': 'Undo View Selected Course', // 取消檢視已選課程
	'chatGPTManage.modal.preview.selectedCourse.no.courseIds': 'No Courses Selected Yet', // 尚未選擇任何課程
	'chatGPTManage.modal.title': 'chatGPT Authorization Management', // chatGPT 權限管理
	'chatGPTManage.confirmation.modal.title': 'Edit chatGPT Authorization', // 編輯 chatGPT 權限
	'chatGPTManage.confirmation.modal.content':
		'Please confirm if you want to edit the chatGPT authorizations for this unit', // 請確認是否編輯該單位的 chatGPT 權限
	'chatGPTManage.authorizationType.title': 'Authorization Settings', // 權限設定
	'chatGPTManage.authorizationType.unit': 'Unit', // 單位
	'chatGPTManage.authorizationType.course': 'Course', // 課程
	'chatGPTManage.authorizationType.none': 'Not Open', // 不開放
	'chatGPTManage.authorizationType': 'chatGPT type', // chatGPT 類型
	'chatGPTManage.authorizationType.toast.title': 'chatGPT Authorization, {{status}}', // chatGPT 權限, {{status}}
	'chatGPTManage.noPromptLimit': 'Unlimited Prompt', // Prompt 無限制
	'exerciseAssistant.header.label': 'Mr. Du AI', // 獨眼怪 AI
	'exerciseAssistant.chatinput.placeholder': 'Ask Mr. Du Assistant!', // 來問獨眼怪小助手吧！
	'exerciseAssistant.chatinput.loading': '...', // 我正準備回覆你，請稍等一下下喔
	'exerciseAssistant.messages.placeholder': 'Hello, I can answer any programming-related questions.', // 你好，跟程式有關的問題，我都能盡可能回答你喔！
	'exerciseAssistant.messages.preset': 'Default', // 預設
	'exerciseAssistant.messages.tokens.alert': 'Your input exceeds the token usage limit.', // 您的輸入超過 tokens 使用上限
	'multiExerciseTypeManage.title': 'Edit Multi-Exercise Type Permissions', // 編輯多元題型權限
	'multiExerciseTypeManage.type': 'Multi-Exercise Type', // 多元題型
	'multiExerciseTypeManage.confirmation.content':
		'Please confirm whether to edit the permissions for the multi-exercise type of this unit', // 請確認是否編輯該單位的多元題型權限
	'multiExerciseTypeManage.type.title': 'Open Settings', // 開放設定
	'multiExerciseTypeManage.type.all': 'All', // 全選
	'multiExerciseTypeManage.type.basic': 'Basic', // 基本
	'multiExerciseTypeManage.type.paid': 'Paid', // 付費
	'multiExerciseTypeManage.toast.title': 'Edit Multi-Exercise Type Permissions, {{status}}', // 編輯多元題型權限, {{status}}
	'multiExerciseTypeManage.exercise.type.basic': 'Basic Exercise Type', // 基本題型選擇
	'multiExerciseTypeManage.exercise.type.advanced': 'Advanced Exercise Type', // 進階題型選擇
	'chatGPT.nothing.to.delete': 'The conversation box is empty!',
};

const general = {
	'AF': 'Afghanistan',
	'AX': 'Åland Islands',
	'AL': 'Albania',
	'DZ': 'Algeria',
	'AS': 'American Samoa',
	'AD': 'Andorra',
	'AO': 'Angola',
	'AI': 'Anguilla',
	'AQ': 'Antarctica',
	'AG': 'Antigua and Barbuda',
	'AR': 'Argentina',
	'AM': 'Armenia',
	'AW': 'Aruba',
	'AU': 'Australia',
	'AT': 'Austria',
	'AZ': 'Azerbaijan',
	'BS': 'Bahamas',
	'BH': 'Bahrain',
	'BD': 'Bangladesh',
	'BB': 'Barbados',
	'BY': 'Belarus',
	'BE': 'Belgium',
	'BZ': 'Belize',
	'BJ': 'Benin',
	'BM': 'Bermuda',
	'BT': 'Bhutan',
	'BO': 'Bolivia (Plurinational State of)',
	'BQ': 'Bonaire, Sint Eustatius and Saba',
	'BA': 'Bosnia and Herzegovina',
	'BW': 'Botswana',
	'BV': 'Bouvet Island',
	'BR': 'Brazil',
	'IO': 'British Indian Ocean Territory',
	'BN': 'Brunei Darussalam',
	'BG': 'Bulgaria',
	'BF': 'Burkina Faso',
	'BI': 'Burundi',
	'KH': 'Cambodia',
	'CM': 'Cameroon',
	'CA': 'Canada',
	'CV': 'Cabo Verde',
	'KY': 'Cayman Islands',
	'CF': 'Central African Republic',
	'TD': 'Chad',
	'CL': 'Chile',
	'CN': 'China',
	'CX': 'Christmas Island',
	'CC': 'Cocos (Keeling) Islands',
	'CO': 'Colombia',
	'KM': 'Comoros',
	'CG': 'Congo',
	'CD': 'Congo, Democratic Republic of the',
	'CK': 'Cook Islands',
	'CR': 'Costa Rica',
	'CI': "Côte d'Ivoire",
	'HR': 'Croatia',
	'CU': 'Cuba',
	'CW': 'Curaçao',
	'CY': 'Cyprus',
	'CZ': 'Czechia',
	'DK': 'Denmark',
	'DJ': 'Djibouti',
	'DM': 'Dominica',
	'DO': 'Dominican Republic',
	'EC': 'Ecuador',
	'EG': 'Egypt',
	'SV': 'El Salvador',
	'GQ': 'Equatorial Guinea',
	'ER': 'Eritrea',
	'EE': 'Estonia',
	'ET': 'Ethiopia',
	'FK': 'Falkland Islands (Malvinas)',
	'FO': 'Faroe Islands',
	'FJ': 'Fiji',
	'FI': 'Finland',
	'FR': 'France',
	'GF': 'French Guiana',
	'PF': 'French Polynesia',
	'TF': 'French Southern Territories',
	'GA': 'Gabon',
	'GM': 'Gambia',
	'GE': 'Georgia',
	'DE': 'Germany',
	'GH': 'Ghana',
	'GI': 'Gibraltar',
	'GR': 'Greece',
	'GL': 'Greenland',
	'GD': 'Grenada',
	'GP': 'Guadeloupe',
	'GU': 'Guam',
	'GT': 'Guatemala',
	'GG': 'Guernsey',
	'GN': 'Guinea',
	'GW': 'Guinea-Bissau',
	'GY': 'Guyana',
	'HT': 'Haiti',
	'HM': 'Heard Island and McDonald Islands',
	'VA': 'Holy See',
	'HN': 'Honduras',
	'HK': 'Hong Kong',
	'HU': 'Hungary',
	'IS': 'Iceland',
	'IN': 'India',
	'ID': 'Indonesia',
	'IR': 'Iran (Islamic Republic of)',
	'IQ': 'Iraq',
	'IE': 'Ireland',
	'IM': 'Isle of Man',
	'IL': 'Israel',
	'IT': 'Italy',
	'JM': 'Jamaica',
	'JP': 'Japan',
	'JE': 'Jersey',
	'JO': 'Jordan',
	'KZ': 'Kazakhstan',
	'KE': 'Kenya',
	'KI': 'Kiribati',
	'KP': "Korea (Democratic People's Republic of)",
	'KR': 'Korea, Republic of',
	'KW': 'Kuwait',
	'KG': 'Kyrgyzstan',
	'LA': "Lao People's Democratic Republic",
	'LV': 'Latvia',
	'LB': 'Lebanon',
	'LS': 'Lesotho',
	'LR': 'Liberia',
	'LY': 'Libya',
	'LI': 'Liechtenstein',
	'LT': 'Lithuania',
	'LU': 'Luxembourg',
	'MO': 'Macao',
	'MK': 'North Macedonia',
	'MG': 'Madagascar',
	'MW': 'Malawi',
	'MY': 'Malaysia',
	'MV': 'Maldives',
	'ML': 'Mali',
	'MT': 'Malta',
	'MH': 'Marshall Islands',
	'MQ': 'Martinique',
	'MR': 'Mauritania',
	'MU': 'Mauritius',
	'YT': 'Mayotte',
	'MX': 'Mexico',
	'FM': 'Micronesia (Federated States of)',
	'MD': 'Moldova, Republic of',
	'MC': 'Monaco',
	'MN': 'Mongolia',
	'ME': 'Montenegro',
	'MS': 'Montserrat',
	'MA': 'Morocco',
	'MZ': 'Mozambique',
	'MM': 'Myanmar',
	'NA': 'Namibia',
	'NR': 'Nauru',
	'NP': 'Nepal',
	'NL': 'Netherlands',
	'NC': 'New Caledonia',
	'NZ': 'New Zealand',
	'NI': 'Nicaragua',
	'NE': 'Niger',
	'NG': 'Nigeria',
	'NU': 'Niue',
	'NF': 'Norfolk Island',
	'MP': 'Northern Mariana Islands',
	'NO': 'Norway',
	'OM': 'Oman',
	'PK': 'Pakistan',
	'PW': 'Palau',
	'PS': 'Palestine, State of',
	'PA': 'Panama',
	'PG': 'Papua New Guinea',
	'PY': 'Paraguay',
	'PE': 'Peru',
	'PH': 'Philippines',
	'PN': 'Pitcairn',
	'PL': 'Poland',
	'PT': 'Portugal',
	'PR': 'Puerto Rico',
	'QA': 'Qatar',
	'RE': 'Réunion',
	'RO': 'Romania',
	'RU': 'Russian Federation',
	'RW': 'Rwanda',
	'BL': 'Saint Barthélemy',
	'SH': 'Saint Helena, Ascension and Tristan da Cunha',
	'KN': 'Saint Kitts and Nevis',
	'LC': 'Saint Lucia',
	'MF': 'Saint Martin (French part)',
	'PM': 'Saint Pierre and Miquelon',
	'VC': 'Saint Vincent and the Grenadines',
	'WS': 'Samoa',
	'SM': 'San Marino',
	'ST': 'Sao Tome and Principe',
	'SA': 'Saudi Arabia',
	'SN': 'Senegal',
	'RS': 'Serbia',
	'SC': 'Seychelles',
	'SL': 'Sierra Leone',
	'SG': 'Singapore',
	'SX': 'Sint Maarten (Dutch part)',
	'SK': 'Slovakia',
	'SI': 'Slovenia',
	'SB': 'Solomon Islands',
	'SO': 'Somalia',
	'ZA': 'South Africa',
	'GS': 'South Georgia and the South Sandwich Islands',
	'SS': 'South Sudan',
	'ES': 'Spain',
	'LK': 'Sri Lanka',
	'SD': 'Sudan',
	'SR': 'Suriname',
	'SJ': 'Svalbard and Jan Mayen',
	'SZ': 'Eswatini',
	'SE': 'Sweden',
	'CH': 'Switzerland',
	'SY': 'Syrian Arab Republic',
	'TW': 'Taiwan',
	'TJ': 'Tajikistan',
	'TZ': 'Tanzania, United Republic of',
	'TH': 'Thailand',
	'TL': 'Timor-Leste',
	'TG': 'Togo',
	'TK': 'Tokelau',
	'TO': 'Tonga',
	'TT': 'Trinidad and Tobago',
	'TN': 'Tunisia',
	'TR': 'Türkiye',
	'TM': 'Turkmenistan',
	'TC': 'Turks and Caicos Islands',
	'TV': 'Tuvalu',
	'UG': 'Uganda',
	'UA': 'Ukraine',
	'AE': 'United Arab Emirates',
	'GB': 'United Kingdom of Great Britain and Northern Ireland',
	'US': 'United States of America',
	'UM': 'United States Minor Outlying Islands',
	'UY': 'Uruguay',
	'UZ': 'Uzbekistan',
	'VU': 'Vanuatu',
	'VE': 'Venezuela (Bolivarian Republic of)',
	'VN': 'Viet Nam',
	'VG': 'Virgin Islands (British)',
	'VI': 'Virgin Islands (U.S.)',
	'WF': 'Wallis and Futuna',
	'EH': 'Western Sahara',
	'YE': 'Yemen',
	'ZM': 'Zambia',
	'ZW': 'Zimbabwe',
	//
	'taiwanCities.TaipeiCity': 'Taipei City', //臺北市
	'taiwanCities.NewTaipeiCity': 'New Taipei City', //新北市
	'taiwanCities.TaoyuanCity': 'Taoyuan City', //桃園市
	'taiwanCities.TaichungCity': 'Taichung City', //臺中市
	'taiwanCities.TainanCity': 'Tainan City', //臺南市
	'taiwanCities.KaohsiungCity': 'Kaohsiung City', //高雄市
	'taiwanCities.KeelungCity': 'Keelung City', //基隆市
	'taiwanCities.HsinchuCity': 'Hsinchu City', //新竹市
	'taiwanCities.ChiayiCity': 'Chiayi City', //嘉義市
	'taiwanCities.HsinchuCounty': 'Hsinchu County', //新竹縣
	'taiwanCities.MiaoliCounty': 'Miaoli County', //苗栗縣
	'taiwanCities.ChanghuaCounty': 'Changhua County', //彰化縣
	'taiwanCities.NantouCounty': 'Nantou County', //南投縣
	'taiwanCities.YunlinCounty': 'Yunlin County', //雲林縣
	'taiwanCities.ChiayiCounty': 'Chiayi County', //嘉義縣
	'taiwanCities.PingtungCounty': 'Pingtung County', //屏東縣
	'taiwanCities.YilanCounty': 'Yilan County', //宜蘭縣
	'taiwanCities.HualienCounty': 'Hualien County', //花蓮縣
	'taiwanCities.TaitungCounty': 'Taitung County', //臺東縣
	'taiwanCities.PenghuCounty': 'Penghu County', //澎湖縣
	'taiwanCities.KinmenCounty': 'Kinmen County', //金門縣
	'taiwanCities.LienchiangCounty': 'Lianjiang County', //連江縣
};

const finalResult = Object.assign(
	{},
	common,
	header,
	teacherExercise,
	sidebar,
	teacherCourseDetail,
	InformationPage,
	notificationPages,
	teacherResourcePage,
	exerciseManageRectangle,
	testManageRectangle,
	materialManage,
	materialUploadFile,
	exerciseManage,
	exerciseAndTest,
	exerciseTipInfo,
	exerciseAnswerInfo,
	exerciseTestInfo,
	testManage,
	lessonAppendExercise,
	testRatioInfo,
	testPreview,
	addMaterial,
	course,
	studentlist,
	test,
	score,
	student,
	exercise,
	question,
	feedback,
	addTA,
	courseManage,
	system,
	loginPage,
	loginEvent,
	freeTrialPage,
	parentloginPage,
	parentHomePage,
	parentFullReport,
	csfcLoginPage,
	staff,
	signUpPage,
	notes,
	announcement,
	lintErrorHints,
	studyResult,
	unitDomainManage,
	betaUnitManage,
	AnnouncementListModal,
	zoom,
	desktopDownload,
	studentDetailPage,
	teacherCourseLessonPage,
	coursePackageManage,
	comments,
	studentInfo,
	studentPerformance,
	parentPage,
	exerciseEditor,
	questionGroupModal,
	disconnetPage,
	teacherCourseTestPage,
	questionGroupManageRectangle,
	testEvent,
	testInfo,
	studentTestPage,
	loadingProcessBar,
	globalExamPage,
	translationPage,
	shareCourseModal,
	dataSyncPage,
	crm,
	manualEmailTemplate,
	parentEmailTemplate,
	registrationDownloadPage,
	crmManage,
	internalMailManage,
	toolsPage,
	batchTeacherAccountModal,
	globalTestModal,
	csfcStudentAccountModal,
	chatGPT,
	general
);

export default finalResult;
